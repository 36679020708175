export const config = {
    API_BASE_URL: process.env.REACT_APP_API_URL || '',
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL || '',
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN || '',
    // Add other environment variables here
};

// Use throughout your app
export const getMediaUrl = (url) => {
    if (!url) return '';
    return url.startsWith('http') ? url : `${config.API_BASE_URL}${url}`;
};