/**
 * Link and URL utilities for handling URL detection and formatting
 */

/**
 * Extract URLs from a text message
 * @param {string} text - The message text to extract URLs from
 * @returns {string[]} Array of URL strings found in the message
 */
export const extractUrls = (text) => {
    if (!text) return [];
    
    // This regex detects URLs that start with http://, https://, or www.
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
    const matches = text.match(urlRegex);
    
    if (!matches) return [];
    
    // Normalize URLs to ensure they have https:// prefix
    return matches.map(url => {
        if (url.startsWith('www.')) {
            return `https://${url}`;
        }
        return url;
    });
};

/**
 * Check if a URL is a YouTube link
 * @param {string} url - The URL to check
 * @returns {boolean} True if the URL is a YouTube link
 */
export const isYouTubeUrl = (url) => {
    if (!url) return false;
    
    // Match common YouTube URL patterns
    return /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/v\/|youtube\.com\/user\/.+\/|youtube\.com\/user\/.+#.+\/|youtube\.com\/shorts\/).+/.test(url);
};

/**
 * Check if a URL is an Instagram link
 * @param {string} url - The URL to check
 * @returns {boolean} True if the URL is an Instagram link
 */
export const isInstagramUrl = (url) => {
    if (!url) return false;
    
    // Match common Instagram URL patterns (profiles, posts, reels, stories)
    return /^(https?:\/\/)?(www\.)?instagram\.com\/(p\/|reel\/|stories\/|[^\/]+\/?$)/.test(url);
};

/**
 * Determine the type of Instagram content
 * @param {string} url - The Instagram URL
 * @returns {string} The type of Instagram content ('profile', 'post', 'reel', or 'story')
 */
export const getInstagramContentType = (url) => {
    if (!url) return null;
    
    if (/instagram\.com\/p\//.test(url)) return 'post';
    if (/instagram\.com\/reel\//.test(url)) return 'reel';
    if (/instagram\.com\/stories\//.test(url)) return 'story';
    if (/instagram\.com\/[^\/]+\/?$/.test(url)) return 'profile';
    
    return null;
};

/**
 * Extract the content ID from an Instagram URL
 * @param {string} url - The Instagram URL
 * @returns {string|null} The Instagram content ID or null if not found
 */
export const getInstagramContentId = (url) => {
    if (!url) return null;
    
    // Extract post/reel ID
    const postMatch = url.match(/instagram\.com\/(?:p|reel)\/([^\/\?&]+)/i);
    if (postMatch) return postMatch[1];
    
    // Extract profile username
    const profileMatch = url.match(/instagram\.com\/([^\/\?&]+)\/?$/i);
    if (profileMatch) return profileMatch[1];
    
    // Extract story username
    const storyMatch = url.match(/instagram\.com\/stories\/([^\/\?&]+)/i);
    if (storyMatch) return storyMatch[1];
    
    return null;
};

/**
 * Get the embed URL for Instagram content
 * @param {string} url - The Instagram URL
 * @returns {string|null} The embed URL or null if not supported
 */
export const getInstagramEmbedUrl = (url) => {
    const contentType = getInstagramContentType(url);
    const contentId = getInstagramContentId(url);
    
    if (!contentType || !contentId) return null;
    
    // Only posts and reels can be embedded with iframe
    if (contentType === 'post' || contentType === 'reel') {
        return `https://www.instagram.com/${contentType === 'post' ? 'p' : 'reel'}/${contentId}/embed`;
    }
    
    return null;
};

/**
 * Extract the video ID from a YouTube URL
 * @param {string} url - The YouTube URL
 * @returns {string|null} The YouTube video ID or null if not found
 */
export const getYouTubeVideoId = (url) => {
    if (!url) return null;
    
    // YouTube URL patterns
    const regexes = [
        // youtu.be format
        /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^\/\?&]+)/i,
        // youtube.com/watch?v= format
        /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?:.*&)?v=([^&]+)/i,
        // youtube.com/embed/ format
        /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^\/\?&]+)/i,
        // youtube.com/v/ format
        /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/v\/([^\/\?&]+)/i,
        // youtube.com/shorts/ format - improved to handle query parameters
        /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([^\/\?&]+)/i
    ];
    
    for (const regex of regexes) {
        const match = url.match(regex);
        if (match) return match[1];
    }
    
    return null;
};

/**
 * Convert a YouTube URL to an embed URL
 * @param {string} url - The YouTube URL
 * @returns {string|null} The YouTube embed URL or null if invalid
 */
export const getYouTubeEmbedUrl = (url) => {
    const videoId = getYouTubeVideoId(url);
    
    if (!videoId) return null;
    
    // For Shorts, we need to use the standard embed URL but can set a different player style
    const isShort = url.includes('/shorts/');
    
    // Use standard embed URL for all YouTube videos (including Shorts)
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0${isShort ? '&modestbranding=1' : ''}`;
};

/**
 * Render text with clickable links
 * @param {string} text - The text containing URLs
 * @param {function} linkRenderer - Function that renders a URL as a link component
 * @returns {Array} Array of text and link components
 */
export const renderTextWithLinks = (text, linkRenderer) => {
    if (!text) return null;
    
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
    const parts = text.split(urlRegex);
    
    return parts.map((part, index) => {
        // Check if this part is a URL
        if (part && (part.startsWith('http') || part.startsWith('www.'))) {
            const normalizedUrl = part.startsWith('www.') ? `https://${part}` : part;
            return linkRenderer(normalizedUrl, `link-${index}`);
        }
        
        return part || null;
    });
}; 