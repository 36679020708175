// src/contexts/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from './AuthContext';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const { user } = useAuth();
    const [socket, setSocket] = useState(null);
    const [connected, setConnected] = useState(false);
    const [activities, setActivities] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const location = useLocation();
    const [messageSound] = useState(new Audio('/sounds/message.mp3'));

    // Fetch initial notifications on component mount
    useEffect(() => {
        if (user) {
            fetchInitialNotifications();
        }
    }, [user]);

    // Setup socket connection when user changes
    useEffect(() => {
        if (!user) return;

        // Connect to Socket.IO server
        const socketInstance = io(process.env.REACT_APP_API_URL || window.location.origin, {
            auth: {
                token: localStorage.getItem('token')
            }
        });

        // Set up event listeners
        socketInstance.on('connect', () => {
            console.log('Socket connected');
            setConnected(true);
        });

        socketInstance.on('disconnect', () => {
            console.log('Socket disconnected');
            setConnected(false);
        });

        // Debug listener - will catch all events for debugging
        socketInstance.onAny((event, ...args) => {
            console.log(`[Socket Debug] Event: ${event}`, args);
        });

        // Listen for WhatsApp message received events
        socketInstance.on('whatsapp_message_received', (data) => {
            console.log('WhatsApp message received:', data);
            
            // Only show toast and play sound if not on the chat page
            const isOnChatPage = location.pathname.includes('/chat');
            if (!isOnChatPage) {
                // Extract message data - handle both array and object formats
                let messageData;
                
                // Check if data is an array
                if (Array.isArray(data) && data.length > 0) {
                    messageData = data[0]?.message || {};
                } else {
                    // Assume data is an object with a message property
                    messageData = data?.message || {};
                }
                
                console.log('Processed message data:', messageData);
                
                // Get sender information
                const senderName = messageData.from?.name || 'Unknown';
                const senderPhone = messageData.from?.phone || '';
                const displayName = senderName !== 'Unknown' ? senderName : senderPhone;
                
                // Get message content based on type
                let messageContent = '';
                let messageIcon = null;
                
                // Log the message type for debugging
                console.log('Message type:', messageData.type);
                
                switch (messageData.type) {
                    case 'text_message':
                        messageContent = messageData.content?.text || 'New message';
                        break;
                    case 'media_message':
                        // Handle media messages based on mediaType
                        const mediaType = messageData.content?.mediaType || '';
                        switch(mediaType) {
                            case 'image':
                                messageContent = '📷 Image';
                                messageIcon = '📷';
                                break;
                            case 'video':
                                messageContent = '🎬 Video';
                                messageIcon = '🎬';
                                break;
                            case 'audio':
                                messageContent = '🎵 Audio';
                                messageIcon = '🎵';
                                break;
                            case 'document':
                                messageContent = '📄 Document';
                                messageIcon = '📄';
                                break;
                            case 'sticker':
                                messageContent = '🙂 Sticker';
                                messageIcon = '🙂';
                                break;
                            default:
                                messageContent = `📁 Media (${mediaType})`;
                                messageIcon = '📁';
                        }
                        break;
                    case 'image_message':
                        messageContent = '📷 Image';
                        messageIcon = '📷';
                        break;
                    case 'video_message':
                        messageContent = '🎬 Video';
                        messageIcon = '🎬';
                        break;
                    case 'audio_message':
                        messageContent = '🎵 Audio';
                        messageIcon = '🎵';
                        break;
                    case 'document_message':
                        messageContent = '📄 Document';
                        messageIcon = '📄';
                        break;
                    case 'location_message':
                        messageContent = '📍 Location';
                        messageIcon = '📍';
                        break;
                    case 'contact_message':
                        messageContent = '👤 Contact';
                        messageIcon = '👤';
                        break;
                    case 'sticker_message':
                        messageContent = '🙂 Sticker';
                        messageIcon = '🙂';
                        break;
                    default:
                        // Log the unknown message type for debugging
                        console.log('Unknown message type:', messageData.type, messageData);
                        messageContent = `New message (${messageData.type || 'unknown type'})`;
                }
                
                // Show toast notification
                toast.info(
                    <div className="flex flex-col">
                        <div className="font-bold text-sm">{displayName}</div>
                        <div className="flex items-center">
                            {messageIcon && <span className="mr-2">{messageIcon}</span>}
                            <span className="text-sm truncate">{messageContent}</span>
                        </div>
                    </div>, 
                    {
                        position: "top-right",
                        autoClose: 5000,
                        onClick: () => {
                            // Navigate to chat page on click
                            window.location.href = '/chat';
                        }
                    }
                );
                
                // Play notification sound
                try {
                    messageSound.volume = 0.5;
                    messageSound.play().catch(e => console.log('Error playing sound:', e));
                } catch (err) {
                    console.error('Error playing notification sound:', err);
                }
            }
        });

        // Listen for activity notifications
        socketInstance.on('new_activity', (activity) => {
            console.log('New activity received:', activity);
            
            // Add to activities list
            setActivities(prev => {
                // Check if activity already exists by _id to prevent duplicates
                const exists = prev.some(a => a._id === activity._id);
                if (exists) return prev;
                return [activity, ...prev].slice(0, 50); // Keep only 50 most recent
            });
            
            // Increment notification count if relevant
            if (shouldNotifyUser(activity, user)) {
                setNotificationCount(prev => prev + 1);
                // Show browser notification if permission granted
                showBrowserNotification(activity);
            }
        });

        // Listen for notification events
        socketInstance.on('new_notification', (notification) => {
            console.log('New notification received:', notification);
            
            // If the notification includes an activityId, find and update that activity
            if (notification.activityId) {
                // Fetch the latest activities to ensure we have the most up-to-date data
                fetchInitialNotifications();
            } else {
                // Increment notification count
                setNotificationCount(prev => prev + 1);
            }
        });

        // Listen for all_activities_read event
        socketInstance.on('all_activities_read', () => {
            console.log('All activities marked as read');
            markAllNotificationsAsRead();
        });

        // Listen for specific events
        socketInstance.on('template_status_update', (update) => {
            console.log('Template status update:', update);
            // Handle template status updates
        });

        socketInstance.on('message_status', (update) => {
            console.log('Message status update:', update);
            // Handle message status updates
        });

        setSocket(socketInstance);

        // Clean up on unmount
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, [user, location.pathname, messageSound]);

    // Fetch initial notifications 
    const fetchInitialNotifications = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/api/activities?limit=10`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (response.ok) {
                const data = await response.json();
                if (data.activities) {
                    setActivities(data.activities);
                    const unread = data.activities.filter(a => !a.read).length;
                    setNotificationCount(unread);
                }
            }
        } catch (error) {
            console.error('Error fetching initial notifications:', error);
        }
    };

    const shouldNotifyUser = (activity, user) => {
        // Don't show notifications for the current user's own actions if they are of type 'user'
        if (activity.userId === user?.id && activity.sourceType === 'user') {
            return false;
        }
        
        // Admin sees all notifications
        if (user?.role === 'admin') {
            return true;
        }
        
        // Regular users see their own activities or system-wide notifications
        return activity.userId === user?.id || activity.sourceType === 'system';
    };

    const showBrowserNotification = (activity) => {
        if ('Notification' in window && Notification.permission === 'granted') {
            // Format notification message based on new schema
            let title = 'New Activity';
            let body = '';
            
            const { type, data, sourceType } = activity;
            
            switch (sourceType) {
                case 'auth':
                    title = 'Authentication';
                    if (type === 'login_success') {
                        body = `${data?.username || 'User'} logged in successfully`;
                    } else if (type === 'login_failed') {
                        body = `Failed login attempt for ${data?.username || 'unknown user'}`;
                    } else {
                        body = type.replace(/_/g, ' ');
                    }
                    break;
                    
                case 'system':
                    title = 'System Event';
                    body = type.replace(/_/g, ' ');
                    break;
                    
                case 'whatsapp':
                    title = 'WhatsApp Event';
                    if (type === 'messages_status_updates') {
                        body = `Message ${data?.status || 'status updated'} for ${data?.recipient || 'a recipient'}`;
                    } else if (type === 'message_template_status_update') {
                        body = `Template ${data?.name || ''} is now ${data?.status || 'updated'}`;
                    } else {
                        body = type.replace(/_/g, ' ');
                    }
                    break;
                    
                case 'user':
                    title = 'User Activity';
                    if (data?.action) {
                        body = `${data.action.replace(/_/g, ' ')} ${data?.details || ''}`;
                    } else {
                        body = type.replace(/_/g, ' ');
                    }
                    break;
                    
                default:
                    body = type.replace(/_/g, ' ');
            }
            
            new Notification(title, {
                body,
                icon: '/logo192.png'
            });
        }
    };

    const markAllNotificationsAsRead = () => {
        setNotificationCount(0);
        // Update activities to mark them as read
        setActivities(prev => prev.map(activity => ({ ...activity, read: true })));
    };
    
    const emitEvent = (eventName, data) => {
        if (socket && connected) {
            socket.emit(eventName, data);
        } else {
            console.error('Socket not connected, cannot emit event');
        }
    };

    const value = {
        socket,
        connected,
        activities,
        notificationCount,
        markAllNotificationsAsRead,
        emitEvent
    };

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketContext; 