//src/components/auth/LoginForm.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { User, Lock, AlertCircle, X, LogIn } from 'lucide-react';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [localError, setLocalError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { login, error, clearError: contextClearError } = useAuth();
    const navigate = useNavigate();

    // Use effect to handle error from auth context and store it locally
    useEffect(() => {
        if (error) {
            setLocalError(error);
        }
    }, [error]);

    const clearError = () => {
        setLocalError('');
        contextClearError(); // Also clear error in the auth context
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Clear previous errors
        clearError();
        
        // Form validation
        if (!username.trim()) {
            setLocalError('Username is required');
            return;
        }
        
        if (!password.trim()) {
            setLocalError('Password is required');
            return;
        }
        
        // Prevent multiple submissions
        if (isSubmitting) return;
        
        setIsSubmitting(true);
        
        try {
            await login(username, password);
            // Login successful - navigation happens automatically
        } catch (err) {
            // Error is already handled by auth context and stored locally
            console.error('Login failed:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="auth-container">
            <div className="max-w-md w-full space-y-8">
                {/* Logo and Header */}
                <div className="text-center">
                    <div className="auth-logo">
                        <LogIn className="h-8 w-8 text-white" />
                    </div>
                    <h2 className="mt-6 text-3xl font-bold text-gray-900">
                        WhatsApp Agent
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                        Sign in to your account to access the dashboard
                    </p>
                </div>

                {/* Login Form */}
                <div className="auth-card">
                    {/* Error Alert */}
                    {localError && (
                        <div className="auth-error">
                            <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
                            <div className="ml-3 flex-1">
                                <p className="text-sm text-red-700">{localError}</p>
                            </div>
                            <button 
                                onClick={clearError}
                                className="ml-auto flex-shrink-0 text-red-500 hover:text-red-700"
                                aria-label="Dismiss error"
                            >
                                <X className="h-5 w-5" />
                            </button>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="username" className="form-label">
                                Username
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="auth-input-icon">
                                    <User className="h-5 w-5" />
                                </div>
                                <input
                                    id="username"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="form-input pl-10"
                                    placeholder="Enter your username"
                                    autoComplete="username"
                                    autoFocus
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="form-label">
                                Password
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="auth-input-icon">
                                    <Lock className="h-5 w-5" />
                                </div>
                                <input
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-input pl-10"
                                    placeholder="Enter your password"
                                    autoComplete="current-password"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="form-checkbox"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="auth-button"
                        >
                            {isSubmitting ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Signing in...
                                </>
                            ) : (
                                'Sign in'
                            )}
                        </button>
                    </form>
                </div>
                
                {/* Footer */}
                <div className="text-center text-sm text-gray-500">
                    <p>
                        By signing in, you agree to our{' '}
                        <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                            Privacy Policy
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginForm; 