import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useSocket } from '../../contexts/SocketContext';
import { apiService } from '../../services/api';
import { Bell, Clock, CheckCircle } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

const NotificationBell = () => {
    const { user } = useAuth();
    const { activities, notificationCount, markAllNotificationsAsRead } = useSocket();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const dropdownRef = useRef(null);

    // Update notifications when activities change from socket
    useEffect(() => {
        console.log('Activities from socket updated:', activities);
        if (activities && activities.length > 0) {
            // Use the activities from socket context directly to ensure real-time updates
            setNotifications(activities.slice(0, 5));
            setUnreadCount(notificationCount);
        }
    }, [activities, notificationCount]);

    // Force a refresh of notifications when unreadCount changes
    useEffect(() => {
        if (unreadCount > 0 && isOpen) {
            fetchNotifications();
        }
    }, [unreadCount, isOpen]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    // Initial load of notifications
    useEffect(() => {
        fetchNotifications();
    }, []);
    
    const fetchNotifications = async () => {
        try {
            setLoading(true);
            
            // Build params object for fetchWithAuth
            const params = {
                limit: 5,
                method: 'GET'
            };
            
            // If not admin, only fetch user's relevant notifications
            if (user?.role !== 'admin') {
                params.userId = user.id;
            }
            
            const response = await apiService.fetchWithAuth('/api/activities', {
                params
            });
            
            if (response.activities) {
                setNotifications(response.activities);
                
                // Count unread notifications
                const unread = response.activities.filter(activity => !activity.read).length;
                setUnreadCount(unread);
            }
        } catch (err) {
            console.error('Error fetching notifications:', err);
        } finally {
            setLoading(false);
        }
    };
    
    const markAsRead = async (activityId) => {
        try {
            await apiService.fetchWithAuth('/api/activities/mark-read', {
                method: 'POST',
                body: JSON.stringify({
                    ids: [activityId],
                    markAllAsRead: false
                })
            });
            
            // Update local state
            setNotifications(prev => 
                prev.map(activity => 
                    activity._id === activityId || activity.id === activityId
                        ? { ...activity, read: true } 
                        : activity
                )
            );
            
            // Update unread count
            setUnreadCount(prev => Math.max(0, prev - 1));
        } catch (err) {
            console.error('Error marking activity as read:', err);
        }
    };
    
    const markAllAsRead = async () => {
        try {
            await apiService.fetchWithAuth('/api/activities/mark-read', {
                method: 'POST',
                body: JSON.stringify({
                    ids: [],
                    markAllAsRead: true
                })
            });
            
            // Update local state
            setNotifications(prev => 
                prev.map(notification => ({ ...notification, read: true }))
            );
            
            // Reset unread count
            setUnreadCount(0);
            
            // Mark all notifications as read in socket context
            markAllNotificationsAsRead();
        } catch (err) {
            console.error('Error marking all notifications as read:', err);
        }
    };
    
    // Toggle notification dropdown
    const toggleNotifications = () => {
        const newState = !isOpen;
        setIsOpen(newState);
        
        // Refresh notifications when opening the dropdown
        if (newState) {
            fetchNotifications();
        }
    };
    
    const handleNotificationClick = (notification) => {
        if (!notification.read) {
            markAsRead(notification._id || notification.id);
        }
        
        // Navigate based on notification type and sourceType
        switch (notification.sourceType) {
            case 'whatsapp':
                navigate('/chat');
                break;
            case 'template':
                navigate('/templates');
                break;
            default:
                // Navigate to activity log for other notifications
                navigate('/activities');
                break;
        }
        
        setIsOpen(false);
    };
    
    const viewAllActivities = () => {
        navigate('/activities');
        setIsOpen(false);
    };
    
    const getNotificationContent = (notification) => {
        const { type, data, sourceType } = notification;
        
        switch (sourceType) {
            case 'auth':
                if (type === 'login_success') {
                    return `${data?.username || 'User'} logged in successfully`;
                } else if (type === 'login_failed') {
                    return `Failed login attempt for ${data?.username || 'unknown user'}`;
                }
                return type.replace(/_/g, ' ');
                
            case 'system':
                return type.replace(/_/g, ' ');
                
            case 'whatsapp':
                if (type === 'messages_status_updates') {
                    return `Message ${data?.status || 'status updated'} for ${data?.recipient || 'a recipient'}`;
                } else if (type === 'message_template_status_update') {
                    return `Template ${data?.name || ''} is now ${data?.status || 'updated'}`;
                }
                return type.replace(/_/g, ' ');
                
            case 'user':
                if (data?.action) {
                    return `${data.action.replace(/_/g, ' ')} ${data?.details || ''}`;
                }
                return type.replace(/_/g, ' ');
                
            default:
                return type.replace(/_/g, ' ');
        }
    };
    
    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={toggleNotifications}
                className="p-1 rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-100 relative"
                aria-label="Notifications"
            >
                <Bell size={20} />
                {unreadCount > 0 && (
                    <span className="absolute top-0 right-0 h-4 w-4 rounded-full bg-red-500 text-white text-xs flex items-center justify-center transform translate-x-1 -translate-y-1">
                        {unreadCount > 9 ? '9+' : unreadCount}
                    </span>
                )}
            </button>
            
            {isOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg py-1 z-50">
                    <div className="px-4 py-2 border-b border-gray-100 flex justify-between items-center">
                        <h3 className="font-semibold text-gray-900">Notifications</h3>
                        {unreadCount > 0 && (
                            <button
                                onClick={markAllAsRead}
                                className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
                            >
                                <CheckCircle size={14} />
                                Mark all as read
                            </button>
                        )}
                    </div>
                    
                    {loading ? (
                        <div className="py-8 text-center">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500 mx-auto"></div>
                        </div>
                    ) : notifications.length === 0 ? (
                        <div className="py-8 text-center text-gray-500">
                            No notifications
                        </div>
                    ) : (
                        <>
                            <div className="max-h-72 overflow-y-auto">
                                {notifications.map((notification, index) => (
                                    <div
                                        key={notification._id || notification.id || index}
                                        onClick={() => handleNotificationClick(notification)}
                                        className={`
                                            px-4 py-3 hover:bg-gray-50 cursor-pointer border-l-2
                                            ${notification.read ? 'border-transparent' : 'border-blue-500'}
                                        `}
                                    >
                                        <div className="flex justify-between items-start">
                                            <p className={`text-sm ${notification.read ? 'text-gray-600' : 'text-gray-900 font-medium'}`}>
                                                {getNotificationContent(notification)}
                                            </p>
                                            <span className="text-xs text-gray-500 flex items-center gap-1 whitespace-nowrap">
                                                <Clock size={12} />
                                                {formatDistanceToNow(new Date(notification.timestamp || notification.createdAt), { addSuffix: true })}
                                            </span>
                                        </div>
                                        
                                        {notification.user && (
                                            <p className="text-xs text-gray-500 mt-1">
                                                By: {notification.user.username || notification.user}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                            
                            <div className="px-4 py-2 border-t border-gray-100 text-center">
                                <button
                                    onClick={viewAllActivities}
                                    className="text-sm text-blue-600 hover:text-blue-800"
                                >
                                    View all activities
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotificationBell; 