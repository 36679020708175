// src/components/dashboard/WhatsAppDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { formatDuration, calculateDeliveryRate } from '../../utils/dashboardUtils';
import { MessageVolumeChart, MessageTypeChart, SentimentChart } from './charts';
import { ActivityPatternChart } from './charts/ActivityPatternChart';
import UserActivityTable from './UserActivityTable';
import Header from '../shared/Header';
import Card from '../ui/Card';
import Button from '../ui/Button';

import {
    Calendar,
    RefreshCcw,
    MessageSquare,
    Users,
    Clock,
    CheckCircle,
    Menu,
    Download
} from 'lucide-react';
import { apiService } from '../../services/api';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import { format, subDays } from 'date-fns';
import DateRangePicker from './DateRangePicker';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const MetricCard = ({ title, value, icon, trend }) => (
    <Card className="flex flex-col h-full">
        <div className="flex items-start justify-between mb-4">
            <div className="bg-blue-50 p-3 rounded-lg">
                {icon}
            </div>
            {trend && (
                <div className={`text-xs font-medium ${trend.positive ? 'text-green-500' : 'text-red-500'}`}>
                    {trend.positive ? '↑' : '↓'} {trend.value}%
                </div>
            )}
        </div>
        <div>
            <h3 className="text-sm font-medium text-gray-500">{title}</h3>
            <p className="text-2xl font-bold mt-1">{value}</p>
        </div>
    </Card>
);

const ChartCard = ({ title, children, subtitle, actions }) => (
    <Card 
        title={title}
        subtitle={subtitle}
        headerAction={actions}
        className="h-full flex flex-col"
    >
        <div className="flex-1">
            {children}
        </div>
    </Card>
);

const WhatsAppDashboard = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dashboardData, setDashboardData] = useState({
        messageVolume: [],
        messageTypes: [],
        sentiment: {},
        activeUsers: { total: 0, topUsers: [] },
        responseTime: {},
        deliveryStatus: [],
        interactionPatterns: {}
    });

    // Only fetch data on initial load
    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        refreshDashboard();
    }, [user]); // Remove date dependencies

    const refreshDashboard = async () => {
        if (!startDate || !endDate) {
            setError('Please select both start and end dates');
            return;
        }

        if (startDate > endDate) {
            setError('Start date must be before end date');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const params = {
                startDate: format(startDate, 'yyyy-MM-dd'),
                endDate: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
            };

            console.log('Fetching data with params:', params);

            const [summary, sentiment, conversations] = await Promise.all([
                apiService.getReportsSummary(params),
                apiService.getAnalyticsSentiment(params),
                apiService.getAnalyticsConversations(params)
            ]);

            setDashboardData({
                ...summary.data,
                sentiment: sentiment.data,
                conversations: conversations.data,
                hourlyActivity: conversations.data?.interactionPatterns?.hourlyDistribution ||
                    conversations.data?.hourlyActivity ||
                    Array(24).fill(0)
            });
        } catch (err) {
            setError('Failed to load dashboard data');
            console.error('Dashboard error:', err);
        } finally {
            setLoading(false);
        }
    };

    const renderDateControls = () => (
        <div className="flex items-center space-x-4">
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartChange={(date) => {
                    setStartDate(date);
                    setError(null);
                }}
                onEndChange={(date) => {
                    setEndDate(date);
                    setError(null);
                }}
            />
            <Button
                onClick={refreshDashboard}
                disabled={loading}
                variant="primary"
                size="md"
                icon={<RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />}
            >
                Refresh
            </Button>
        </div>
    );

    const exportReport = () => {
        // Export functionality would go here
        console.log('Exporting report...');
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50 overflow-auto">
            {/* Date range controls */}
            <div className="mx-6 mt-4 flex justify-end">
                {renderDateControls()}
            </div>

            {/* Error Display */}
            {error && (
                <div className="mx-6 mt-4">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative">
                        <span className="block sm:inline">{error}</span>
                        <button 
                            className="absolute top-0 bottom-0 right-0 px-4 py-3"
                            onClick={() => setError(null)}
                        >
                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <main className="flex-1 py-6 px-6 space-y-6 overflow-auto">
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                    </div>
                ) : (
                    <>
                        {/* Key Metrics */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                            <MetricCard
                                title="Total Messages"
                                value={dashboardData.messageVolume.reduce((acc, day) => acc + day.total, 0)}
                                icon={<MessageSquare className="text-blue-600" size={24} />}
                                trend={{ positive: true, value: 12.5 }}
                            />
                            <MetricCard
                                title="Active Users"
                                value={dashboardData.activeUsers?.total || 0}
                                icon={<Users className="text-green-600" size={24} />}
                                trend={{ positive: true, value: 8.2 }}
                            />
                            <MetricCard
                                title="Avg Response Time"
                                value={formatDuration(dashboardData.responseTime.averageResponseTime)}
                                icon={<Clock className="text-purple-600" size={24} />}
                                trend={{ positive: false, value: 3.1 }}
                            />
                            <MetricCard
                                title="Delivery Rate"
                                value={`${calculateDeliveryRate(dashboardData.deliveryStatus).toFixed(1)}%`}
                                icon={<CheckCircle className="text-orange-600" size={24} />}
                                trend={{ positive: true, value: 1.8 }}
                            />
                        </div>

                        {/* Charts */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <ChartCard 
                                title="Message Volume Trend" 
                                subtitle="Daily message count over time"
                                actions={
                                    <Button 
                                        variant="secondary" 
                                        size="sm" 
                                        icon={<Download size={14} />}
                                        onClick={exportReport}
                                    >
                                        Export
                                    </Button>
                                }
                            >
                                <MessageVolumeChart data={dashboardData.messageVolume} />
                            </ChartCard>
                            <ChartCard 
                                title="Message Type Distribution"
                                subtitle="Breakdown by message type"
                            >
                                <MessageTypeChart data={dashboardData.messageTypes} />
                            </ChartCard>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <ChartCard 
                                title="Sentiment Analysis"
                                subtitle="User sentiment distribution"
                            >
                                <SentimentChart data={dashboardData.sentiment} />
                            </ChartCard>
                            <ChartCard 
                                title="Daily Activity Pattern"
                                subtitle="Message volume by hour of day"
                            >
                                <ActivityPatternChart data={dashboardData.conversations} />
                            </ChartCard>
                        </div>

                        {/* User Activity Table */}
                        <Card
                            title="Top Active Users"
                            subtitle="Users with highest message volume"
                            noPadding
                        >
                            <UserActivityTable users={dashboardData.activeUsers?.topUsers || []} />
                        </Card>
                    </>
                )}
            </main>
        </div>
    );
};

export default WhatsAppDashboard;