import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const StatusDistributionChart = ({ delivered = 0, failed = 0, pending = 0, read = 0, other = 0 }) => {
    // Define chart data
    const chartData = {
        labels: ['Delivered', 'Failed', 'Pending', 'Read', 'Other'],
        datasets: [
            {
                data: [delivered, failed, pending, read, other],
                backgroundColor: [
                    'rgba(16, 185, 129, 0.7)',  // green for delivered
                    'rgba(239, 68, 68, 0.7)',   // red for failed
                    'rgba(245, 158, 11, 0.7)',  // yellow for pending
                    'rgba(59, 130, 246, 0.7)',  // blue for read
                    'rgba(156, 163, 175, 0.7)'  // gray for other
                ],
                borderColor: [
                    'rgb(16, 185, 129)',
                    'rgb(239, 68, 68)',
                    'rgb(245, 158, 11)',
                    'rgb(59, 130, 246)',
                    'rgb(156, 163, 175)'
                ],
                borderWidth: 1
            }
        ]
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                }
            }
        },
        // Center text displaying total messages
        elements: {
            arc: {
                borderWidth: 1
            }
        },
        cutout: '60%'
    };

    const total = delivered + failed + pending + read + other;

    return (
        <div className="h-full w-full relative">
            {total === 0 ? (
                <div className="flex items-center justify-center h-full">
                    <p className="text-gray-500">No status data available</p>
                </div>
            ) : (
                <>
                    <Doughnut data={chartData} options={options} />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="text-center">
                            <div className="text-3xl font-bold text-gray-800">{total}</div>
                            <div className="text-sm text-gray-500">Total</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default StatusDistributionChart; 