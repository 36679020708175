import React, { useState, useEffect, useRef } from 'react';
import Modal from '../ui/Modal';
import { 
  Download, 
  ZoomIn, 
  ZoomOut, 
  RotateCw, 
  Play, 
  Pause, 
  Volume2, 
  VolumeX, 
  FileText,
  ChevronLeft, 
  ChevronRight,
  SkipBack,
  SkipForward,
  Clock,
  File,
  FileArchive,
  FilePlus,
  FileCode,
  Image as ImageIcon,
  Film
} from 'lucide-react';

const MediaViewer = ({ media, isOpen, onClose }) => {
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isShowingControls, setIsShowingControls] = useState(true);
  
  const videoRef = useRef(null);
  const audioControlsTimeoutRef = useRef(null);

  // Debug logs to track what's happening
  useEffect(() => {
    if (isOpen) {
      console.log("MediaViewer opened with:", media);
    }
  }, [isOpen, media]);

  // Reset state when opening new media
  useEffect(() => {
    if (isOpen) {
      setZoom(1);
      setRotation(0);
      setIsMuted(false);
      setIsPaused(true);
      setCurrentTime(0);
      setDuration(0);
      setVolume(1);
      setIsShowingControls(true);
    }
  }, [isOpen, media]);

  // Format time in mm:ss format
  const formatTime = (time) => {
    if (isNaN(time)) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Handle media events
  useEffect(() => {
    const mediaElement = videoRef.current;
    if (!mediaElement) return;

    const handleTimeUpdate = () => setCurrentTime(mediaElement.currentTime);
    const handleDurationChange = () => setDuration(mediaElement.duration);
    const handleVolumeChange = () => setVolume(mediaElement.volume);
    const handleEnded = () => setIsPaused(true);
    const handlePlay = () => setIsPaused(false);
    const handlePause = () => setIsPaused(true);

    mediaElement.addEventListener('timeupdate', handleTimeUpdate);
    mediaElement.addEventListener('durationchange', handleDurationChange);
    mediaElement.addEventListener('volumechange', handleVolumeChange);
    mediaElement.addEventListener('ended', handleEnded);
    mediaElement.addEventListener('play', handlePlay);
    mediaElement.addEventListener('pause', handlePause);

    return () => {
      mediaElement.removeEventListener('timeupdate', handleTimeUpdate);
      mediaElement.removeEventListener('durationchange', handleDurationChange);
      mediaElement.removeEventListener('volumechange', handleVolumeChange);
      mediaElement.removeEventListener('ended', handleEnded);
      mediaElement.removeEventListener('play', handlePlay);
      mediaElement.removeEventListener('pause', handlePause);
    };
  }, [isOpen, media]);

  // Basic controls
  const handleZoomIn = () => setZoom(prev => Math.min(prev + 0.25, 3));
  const handleZoomOut = () => setZoom(prev => Math.max(prev - 0.25, 0.5));
  const handleRotate = () => setRotation(prev => (prev + 90) % 360);

  // Media controls
  const handleTogglePlay = () => {
    if (!videoRef.current) return;
    
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleToggleMute = () => {
    if (!videoRef.current) return;
    
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(!isMuted);
  };

  const handleSeek = (e) => {
    if (!videoRef.current) return;
    
    const seekPos = e.nativeEvent.offsetX / e.target.clientWidth;
    videoRef.current.currentTime = seekPos * duration;
  };

  const handleVolumeChange = (e) => {
    if (!videoRef.current) return;
    
    const newVolume = parseFloat(e.target.value);
    videoRef.current.volume = newVolume;
    setVolume(newVolume);
    
    if (newVolume === 0) {
      videoRef.current.muted = true;
      setIsMuted(true);
    } else if (isMuted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    }
  };

  const handleSkipBackward = () => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 10);
  };

  const handleSkipForward = () => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = Math.min(duration, videoRef.current.currentTime + 10);
  };

  const showControlsTemporarily = () => {
    setIsShowingControls(true);
    
    clearTimeout(audioControlsTimeoutRef.current);
    audioControlsTimeoutRef.current = setTimeout(() => {
      setIsShowingControls(false);
    }, 3000);
  };

  const getFileExtension = (url) => {
    if (!url) return '';
    return url.split('.').pop().toLowerCase();
  };

  const getFileIcon = (url, size = 50) => {
    if (!url) return <FileText size={size} className="text-blue-500" />;
    
    const ext = getFileExtension(url);
    
    // Images
    if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(ext)) {
        return <ImageIcon size={size} className="text-green-500" />;
    }
    
    // Videos
    if (['mp4', 'mov', 'avi', 'webm', 'mkv'].includes(ext)) {
        return <Film size={size} className="text-purple-500" />;
    }
    
    // Audio
    if (['mp3', 'wav', 'ogg', 'aac', 'm4a'].includes(ext)) {
        return <Volume2 size={size} className="text-red-500" />;
    }
    
    // Archives
    if (['zip', 'rar', '7z', 'tar', 'gz'].includes(ext)) {
        return <FileArchive size={size} className="text-amber-500" />;
    }
    
    // Code
    if (['js', 'jsx', 'ts', 'tsx', 'html', 'css', 'py', 'java', 'php', 'c', 'cpp', 'json'].includes(ext)) {
        return <FileCode size={size} className="text-cyan-500" />;
    }
    
    // Documents
    if (['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'].includes(ext)) {
        return <FileText size={size} className="text-blue-500" />;
    }
    
    return <File size={size} className="text-gray-500" />;
  };

  const renderMediaContent = () => {
    if (!media) return null;

    switch (media.type) {
      case 'image':
        return (
          <div className="relative flex items-center justify-center h-full">
            <img
              src={media.url}
              alt={media.caption || 'Image'}
              className="max-h-[80vh] object-contain transition-transform"
              style={{ 
                transform: `scale(${zoom}) rotate(${rotation}deg)`
              }}
            />
            <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-4">
              <button
                onClick={handleZoomIn}
                className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
              >
                <ZoomIn size={20} />
              </button>
              <button
                onClick={handleZoomOut}
                className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
              >
                <ZoomOut size={20} />
              </button>
              <button
                onClick={handleRotate}
                className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
              >
                <RotateCw size={20} />
              </button>
              <a
                href={media.url}
                download
                className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
              >
                <Download size={20} />
              </a>
            </div>
          </div>
        );

      case 'video':
        return (
          <div 
            className="relative flex flex-col items-center justify-center h-full"
            onMouseMove={showControlsTemporarily}
            onClick={() => setIsShowingControls(true)}
          >
            <video
              ref={videoRef}
              src={media.url}
              className="max-h-[80vh] max-w-full rounded-lg"
              controls={false}
              muted={isMuted}
              onEnded={() => setIsPaused(true)}
            />
            
            {/* Video Controls */}
            <div className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent px-4 py-6 transition-opacity duration-300 ${isShowingControls ? 'opacity-100' : 'opacity-0'}`}>
              {/* Progress Bar */}
              <div 
                className="relative h-1 bg-gray-700 rounded-full mb-4 cursor-pointer"
                onClick={handleSeek}
              >
                <div 
                  className="absolute h-full bg-indigo-500 rounded-full"
                  style={{ width: `${(currentTime / duration) * 100}%` }}
                ></div>
                <div 
                  className="absolute h-3 w-3 bg-white rounded-full -mt-1"
                  style={{ left: `${(currentTime / duration) * 100}%` }}
                ></div>
              </div>
              
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <button
                    onClick={handleSkipBackward}
                    className="text-white hover:text-indigo-300 focus:outline-none"
                  >
                    <SkipBack size={18} />
                  </button>
                  
                  <button
                    onClick={handleTogglePlay}
                    className="text-white hover:text-indigo-300 focus:outline-none p-2 bg-indigo-600 rounded-full"
                  >
                    {isPaused ? <Play size={20} /> : <Pause size={20} />}
                  </button>
                  
                  <button
                    onClick={handleSkipForward}
                    className="text-white hover:text-indigo-300 focus:outline-none"
                  >
                    <SkipForward size={18} />
                  </button>
                  
                  <span className="text-white text-sm ml-2">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </span>
                </div>
                
                <div className="flex items-center space-x-3">
                  <button
                    onClick={handleToggleMute}
                    className="text-white hover:text-indigo-300 focus:outline-none"
                  >
                    {isMuted ? <VolumeX size={18} /> : <Volume2 size={18} />}
                  </button>
                  
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={isMuted ? 0 : volume}
                    onChange={handleVolumeChange}
                    className="w-24 accent-indigo-500"
                  />
                  
                  <a
                    href={media.url}
                    download
                    className="text-white hover:text-indigo-300 focus:outline-none"
                  >
                    <Download size={18} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        );

      case 'audio':
        return (
          <div className="p-8 flex flex-col items-center bg-gradient-to-b from-blue-100 to-indigo-100 rounded-lg w-full max-w-xl">
            <div className="flex justify-center items-center w-32 h-32 rounded-full bg-gradient-to-r from-blue-400 to-indigo-500 mb-6 shadow-lg">
              <Volume2 size={50} className="text-white" />
            </div>
            
            <h3 className="text-lg font-semibold mb-6 text-center text-gray-800">
              {media.caption || 'Audio File'}
            </h3>
            
            {/* Custom Audio Player */}
            <div className="w-full">
              {/* Progress Bar */}
              <div 
                className="relative h-2 bg-gray-200 rounded-full mb-4 cursor-pointer"
                onClick={handleSeek}
              >
                <div 
                  className="absolute h-full bg-indigo-500 rounded-full"
                  style={{ width: `${(currentTime / duration) * 100}%` }}
                ></div>
                <div 
                  className="absolute h-4 w-4 bg-white border-2 border-indigo-500 rounded-full -mt-1 shadow-md"
                  style={{ left: `${(currentTime / duration) * 100}%` }}
                ></div>
              </div>
              
              {/* Time Display */}
              <div className="flex justify-between text-sm text-gray-600 mb-4">
                <div className="flex items-center">
                  <Clock size={14} className="mr-1" />
                  <span>{formatTime(currentTime)}</span>
                </div>
                <span>-{formatTime(duration - currentTime)}</span>
              </div>
              
              {/* Controls */}
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={handleSkipBackward}
                    className="text-indigo-700 hover:text-indigo-900 focus:outline-none"
                  >
                    <SkipBack size={20} />
                  </button>
                  
                  <button
                    onClick={handleTogglePlay}
                    className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-full p-3 focus:outline-none"
                  >
                    {isPaused ? <Play size={24} /> : <Pause size={24} />}
                  </button>
                  
                  <button
                    onClick={handleSkipForward}
                    className="text-indigo-700 hover:text-indigo-900 focus:outline-none"
                  >
                    <SkipForward size={20} />
                  </button>
                </div>
                
                <div className="flex items-center space-x-3">
                  <button
                    onClick={handleToggleMute}
                    className="text-indigo-700 hover:text-indigo-900 focus:outline-none"
                  >
                    {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
                  </button>
                  
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={isMuted ? 0 : volume}
                    onChange={handleVolumeChange}
                    className="w-24 accent-indigo-500"
                  />
                </div>
              </div>
              
              {/* Hidden audio element to control with our custom UI */}
              <audio
                ref={videoRef}
                src={media.url}
                className="hidden"
                preload="metadata"
              />
            </div>
            
            {/* Download button */}
            <a
              href={media.url}
              download
              className="mt-6 flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              <Download size={16} />
              <span>Download Audio</span>
            </a>
          </div>
        );

      case 'document':
        const ext = getFileExtension(media.url);
        return (
          <div className="p-8 flex flex-col items-center bg-white rounded-lg shadow-lg max-w-2xl w-full">
            <div className="flex justify-center items-center w-28 h-28 rounded-lg bg-blue-50 border border-blue-100 mb-6">
              {getFileIcon(media.url)}
            </div>
            
            <h3 className="text-xl font-semibold mb-2 text-center text-gray-800">
              {media.fileName || 'Document'}
            </h3>
            
            <div className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600 uppercase font-medium mb-4">
              {ext || 'Document'}
            </div>
            
            {media.caption && (
              <p className="text-sm text-gray-500 mb-6 text-center max-w-md">
                {media.caption}
              </p>
            )}
            
            <div className="flex space-x-4">
              <a
                href={media.url}
                download
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 px-5 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Download size={18} />
                <span>Download</span>
              </a>
              
              <a
                href={media.url}
                target="_blank"
                rel="noopener noreferrer" 
                className="flex items-center space-x-2 px-5 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                  <polyline points="15 3 21 3 21 9" />
                  <line x1="10" y1="14" x2="21" y2="3" />
                </svg>
                <span>Open</span>
              </a>
            </div>
          </div>
        );

      default:
        return <div>Unsupported media type</div>;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <div className="w-full h-full flex items-center justify-center bg-black bg-opacity-90">
        {renderMediaContent()}
      </div>
    </Modal>
  );
};

export default MediaViewer; 