import React, { useState, useEffect, useCallback } from 'react';
import { format, subDays } from 'date-fns';
import { Loader, Download, ChevronDown, ChevronUp, Search, Filter, ChevronLeft, ChevronRight } from 'lucide-react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
// Import jspdf-autotable dynamically in the exportToPDF function

import { apiService } from '../../services/api';
import DateRangePicker from '../dashboard/DateRangePicker';
import TemplateDistributionChart from './charts/TemplateDistributionChart';
import StatusDistributionChart from './charts/StatusDistributionChart';

const TemplatePerformanceDashboard = () => {
    const [startDate, setStartDate] = useState(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [summaryData, setSummaryData] = useState({
        total: 0,
        delivered: 0,
        failed: 0,
        pending: 0,
        read: 0,
        other: 0,
        distribution: [],
        timeSeriesData: []
    });
    
    // Messages table state
    const [messages, setMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const [messagesError, setMessagesError] = useState(null);
    const [totalMessages, setTotalMessages] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        totalPages: 1
    });
    
    // Filter state
    const [filters, setFilters] = useState({
        templateName: '',
        status: '',
        recipient: '',
        sortBy: 'timestamp',
        sortOrder: 'desc'
    });
    
    const [expandedExport, setExpandedExport] = useState(false);
    const [interval, setInterval] = useState('daily');
    const [showFilters, setShowFilters] = useState(false);

    // Fetch summary data
    const fetchSummaryData = useCallback(async () => {
        setLoading(true);
        setError(null);
        
        try {
            // Format dates for API request
            const formattedStartDate = format(startDate, 'yyyy-MM-dd');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd');
            
            // API call to get template message metrics
            const response = await apiService.getTemplateMessageMetrics(
                formattedStartDate,
                formattedEndDate,
                interval
            );
            
            // Process and set the data
            if (response?.success === true && response?.data) {
                const data = response.data;
                setSummaryData({
                    total: data.summary.total || 0,
                    delivered: data.summary.delivered || 0,
                    failed: data.summary.failed || 0,
                    pending: data.summary.pending || 0,
                    read: data.summary.read || 0,
                    other: data.summary.other || 0,
                    distribution: data.distribution || [],
                    timeSeriesData: data.timeSeriesData || []
                });
            } else {
                throw new Error('Failed to fetch template message metrics');
            }
        } catch (err) {
            console.error('Error fetching summary data:', err);
            setError('Failed to load template message metrics. Please try again later.');
            
            // Use mock data for demonstration if API fails
            setSummaryData({
                total: 145,
                delivered: 98,
                failed: 6,
                pending: 4,
                read: 35,
                other: 2,
                distribution: [
                    { name: 'bitvoice_missed_call', count: 67, percentage: 46.21 },
                    { name: 'appointment_reminder', count: 42, percentage: 28.97 },
                    { name: 'order_update', count: 21, percentage: 14.48 },
                    { name: 'payment_confirmation', count: 15, percentage: 10.34 }
                ],
                timeSeriesData: Array(7).fill(0).map((_, i) => ({
                    timeKey: format(subDays(new Date(), 6 - i), 'yyyy-MM-dd'),
                    total: Math.floor(Math.random() * 20) + 10,
                    delivered: Math.floor(Math.random() * 15) + 5,
                    read: Math.floor(Math.random() * 10),
                    failed: Math.floor(Math.random() * 3),
                    pending: Math.floor(Math.random() * 2),
                    other: 0
                }))
            });
        } finally {
            setLoading(false);
        }
    }, [startDate, endDate, interval]);

    // Fetch messages data
    const fetchMessagesData = useCallback(async () => {
        setMessagesLoading(true);
        setMessagesError(null);
        
        try {
            // Format dates for API request
            const formattedStartDate = format(startDate, 'yyyy-MM-dd');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd');
            
            // API call to get template messages with pagination and filters
            const response = await apiService.getTemplateMessages(
                formattedStartDate,
                formattedEndDate,
                {
                    page: pagination.page,
                    limit: pagination.limit,
                    ...filters
                }
            );
            
            // Process and set the data
            if (response?.success === true && response?.data) {
                const data = response.data;
                setMessages(data.messages || []);
                setTotalMessages(data.total || 0);
                
                // Update pagination based on response
                if (response.metadata?.pagination) {
                    setPagination({
                        page: response.metadata.pagination.page || 1,
                        limit: response.metadata.pagination.limit || 10,
                        totalPages: response.metadata.pagination.totalPages || 1
                    });
                }
            } else {
                throw new Error('Failed to fetch template messages');
            }
        } catch (err) {
            console.error('Error fetching messages data:', err);
            setMessagesError('Failed to load template messages. Please try again later.');
            
            // Use mock data for demonstration if API fails
            setMessages(Array(10).fill(0).map((_, i) => ({
                id: `msg-${i+1}`,
                templateName: ['bitvoice_missed_call', 'appointment_reminder', 'order_update', 'payment_confirmation'][Math.floor(Math.random() * 4)],
                recipient: `+${Math.floor(Math.random() * 999999999999)}`,
                recipientName: "Test User",
                status: ['delivered', 'failed', 'read', 'pending'][Math.floor(Math.random() * 4)],
                timestamp: new Date(Date.now() - Math.floor(Math.random() * 7 * 24 * 60 * 60 * 1000)).toISOString()
            })));
            setTotalMessages(67);
            setPagination({
                page: 1,
                limit: 10,
                totalPages: 7
            });
        } finally {
            setMessagesLoading(false);
        }
    }, [startDate, endDate, pagination.page, pagination.limit, filters]);

    // Initial data loading
    useEffect(() => {
        fetchSummaryData();
    }, [fetchSummaryData]);

    useEffect(() => {
        fetchMessagesData();
    }, [fetchMessagesData]);

    // Handle refreshing all data
    const refreshData = () => {
        fetchSummaryData();
        fetchMessagesData();
    };

    // Handle filter changes
    const handleFilterChange = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value
        }));
        // Reset to first page when filters change
        setPagination(prev => ({
            ...prev,
            page: 1
        }));
    };

    // Handle sorting
    const handleSort = (field) => {
        if (filters.sortBy === field) {
            // Toggle sort order if the same field is clicked
            handleFilterChange('sortOrder', filters.sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // Set new sort field and default to descending order
            setFilters(prev => ({
                ...prev,
                sortBy: field,
                sortOrder: 'desc'
            }));
        }
    };

    // Handle pagination
    const goToPage = (newPage) => {
        if (newPage < 1 || newPage > pagination.totalPages) return;
        setPagination(prev => ({
            ...prev,
            page: newPage
        }));
    };

    // Filter reset
    const resetFilters = () => {
        setFilters({
            templateName: '',
            status: '',
            recipient: '',
            sortBy: 'timestamp',
            sortOrder: 'desc'
        });
        setPagination(prev => ({
            ...prev,
            page: 1
        }));
    };

    // Export data functions
    const exportToExcel = () => {
        // Create worksheet from messages data
        const worksheet = XLSX.utils.json_to_sheet(
            messages.map(message => ({
                'Template Name': message.templateName,
                'Recipient': message.recipient,
                'Recipient Name': message.recipientName || '',
                'Status': message.status,
                'Date': format(new Date(message.timestamp), 'MMM dd, yyyy HH:mm'),
                'Message ID': message.id
            }))
        );
        
        // Set column widths
        const columnWidths = [
            { wch: 20 }, // Template Name
            { wch: 15 }, // Recipient
            { wch: 20 }, // Recipient Name
            { wch: 10 }, // Status
            { wch: 20 }, // Date
            { wch: 30 }  // Message ID
        ];
        worksheet['!cols'] = columnWidths;
        
        // Create workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Template Messages');
        
        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        // Format filename with date range
        const startDateStr = format(startDate, 'yyyy-MM-dd');
        const endDateStr = format(endDate, 'yyyy-MM-dd');
        saveAs(data, `template-messages-${startDateStr}_to_${endDateStr}.xlsx`);
        
        // Close the dropdown menu
        setExpandedExport(false);
    };

    const exportToPDF = () => {
        // Create PDF document
        const doc = new jsPDF('landscape');
        
        // Add title and date range
        const startDateStr = format(startDate, 'MMM dd, yyyy');
        const endDateStr = format(endDate, 'MMM dd, yyyy');
        doc.setFontSize(16);
        doc.text('Template Messages Report', 14, 15);
        doc.setFontSize(10);
        doc.text(`Period: ${startDateStr} - ${endDateStr}`, 14, 22);
        
        // Add summary data
        doc.text('Summary:', 14, 30);
        doc.text(`Total: ${summaryData.total}`, 30, 38);
        doc.text(`Delivered: ${summaryData.delivered}`, 30, 45);
        doc.text(`Read: ${summaryData.read}`, 30, 52);
        doc.text(`Failed: ${summaryData.failed}`, 30, 59);
        doc.text(`Pending: ${summaryData.pending}`, 30, 66);
        doc.text(`Other: ${summaryData.other}`, 30, 73);
        
        // Create table data
        const tableColumn = ["Template Name", "Recipient", "Status", "Date"];
        const tableRows = messages.map(message => [
            message.templateName,
            message.recipient + (message.recipientName ? ` (${message.recipientName})` : ''),
            message.status.charAt(0).toUpperCase() + message.status.slice(1),
            format(new Date(message.timestamp), 'MMM dd, yyyy HH:mm')
        ]);
        
        // Close the dropdown menu
        setExpandedExport(false);
        
        // Use the correct way to create tables with jspdf-autotable
        import('jspdf-autotable').then((module) => {
            const { default: autoTable } = module;
            
            autoTable(doc, {
                startY: 80,
                head: [tableColumn],
                body: tableRows,
                headStyles: { fillColor: [59, 130, 246] },
                didDrawPage: (data) => {
                    // Footer with page numbers
                    doc.setFontSize(10);
                    const pageSize = doc.internal.pageSize;
                    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    doc.text(`Page ${data.pageNumber} of ${data.pageCount}`, data.settings.margin.left, pageHeight - 10);
                }
            });
            
            // Save the PDF
            doc.save(`template-messages-${format(startDate, 'yyyy-MM-dd')}_to_${format(endDate, 'yyyy-MM-dd')}.pdf`);
        });
    };

    // Metric Card Component
    const MetricCard = ({ title, value, color = 'blue' }) => (
        <div className="bg-white p-4 rounded-lg shadow">
            <p className="text-sm font-medium text-gray-500">{title}</p>
            <p className={`mt-2 text-3xl font-bold text-${color}-600`}>{value}</p>
        </div>
    );

    // Get color for status
    const getStatusColor = (status) => {
        switch (status) {
            case 'delivered': return 'text-green-600';
            case 'failed': return 'text-red-600';
            case 'pending': return 'text-yellow-600';
            case 'read': return 'text-blue-600';
            default: return 'text-gray-600';
        }
    };

    const handleIntervalChange = (newInterval) => {
        setInterval(newInterval);
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-2xl font-bold text-gray-900">Template Messages</h1>
                </div>
            </header>

            {/* Main Content */}
            <main className="flex-grow">
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    
                    {/* Date Range and Controls */}
                    <div className="mb-6 bg-white p-4 rounded-lg shadow">
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                            <div>
                                <h2 className="text-lg font-medium text-gray-900 mb-2">Date Range</h2>
                                <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    onStartChange={setStartDate}
                                    onEndChange={setEndDate}
                                />
                            </div>
                            <div className="flex flex-col space-y-3">
                                <div className="flex space-x-3">
                                    <button
                                        onClick={refreshData}
                                        disabled={loading || messagesLoading}
                                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 disabled:bg-blue-300 flex items-center space-x-2"
                                    >
                                        {(loading || messagesLoading) && <Loader size={16} className="animate-spin" />}
                                        <span>Refresh</span>
                                    </button>

                                    <div className="relative">
                                        <button
                                            onClick={() => setExpandedExport(!expandedExport)}
                                            className="bg-gray-100 text-gray-700 px-4 py-2 rounded hover:bg-gray-200 flex items-center space-x-2"
                                        >
                                            <Download size={16} />
                                            <span>Export</span>
                                            {expandedExport ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                                        </button>

                                        {expandedExport && (
                                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                                                <div className="py-1">
                                                    <button
                                                        onClick={exportToExcel}
                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Export to Excel
                                                    </button>
                                                    <button
                                                        onClick={exportToPDF}
                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Export to PDF
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <span className="text-sm text-gray-600">Interval:</span>
                                    <select 
                                        value={interval}
                                        onChange={(e) => handleIntervalChange(e.target.value)}
                                        className="border rounded px-2 py-1 text-sm"
                                    >
                                        <option value="hourly">Hourly</option>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="monthly">Monthly</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <Loader size={48} className="text-blue-600 animate-spin" />
                        </div>
                    ) : error ? (
                        <div className="bg-red-50 p-4 rounded-md mb-6">
                            <p className="text-red-600">{error}</p>
                        </div>
                    ) : (
                        <>
                            {/* Metrics Overview */}
                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6">
                                <MetricCard title="Total Sent" value={summaryData.total} />
                                <MetricCard title="Delivered" value={summaryData.delivered} color="green" />
                                <MetricCard title="Failed" value={summaryData.failed} color="red" />
                                <MetricCard title="Pending" value={summaryData.pending} color="yellow" />
                                <MetricCard title="Read" value={summaryData.read} color="blue" />
                                <MetricCard title="Other" value={summaryData.other} color="gray" />
                            </div>

                            {/* Charts */}
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
                                <div className="bg-white p-4 rounded-lg shadow">
                                    <h3 className="text-lg font-medium text-gray-900 mb-4">Template Distribution</h3>
                                    <div className="h-64">
                                        <TemplateDistributionChart data={summaryData.distribution} />
                                    </div>
                                </div>
                                <div className="bg-white p-4 rounded-lg shadow">
                                    <h3 className="text-lg font-medium text-gray-900 mb-4">Status Distribution</h3>
                                    <div className="h-64">
                                        <StatusDistributionChart 
                                            delivered={summaryData.delivered}
                                            failed={summaryData.failed}
                                            pending={summaryData.pending}
                                            read={summaryData.read}
                                            other={summaryData.other}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Message Table with Filters */}
                            <div className="bg-white rounded-lg shadow overflow-hidden mb-6">
                                <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                                    <h3 className="text-lg font-medium text-gray-900">Message Details</h3>
                                    <button 
                                        onClick={() => setShowFilters(!showFilters)}
                                        className="flex items-center space-x-1 text-gray-600 hover:text-gray-900"
                                    >
                                        <Filter size={16} />
                                        <span>{showFilters ? 'Hide Filters' : 'Show Filters'}</span>
                                    </button>
                                </div>
                                
                                {/* Filters */}
                                {showFilters && (
                                    <div className="p-4 bg-gray-50 border-b border-gray-200">
                                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Template Name
                                                </label>
                                                <input
                                                    type="text"
                                                    value={filters.templateName}
                                                    onChange={(e) => handleFilterChange('templateName', e.target.value)}
                                                    placeholder="Search templates..."
                                                    className="w-full border border-gray-300 rounded-md p-2 text-sm"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Status
                                                </label>
                                                <select
                                                    value={filters.status}
                                                    onChange={(e) => handleFilterChange('status', e.target.value)}
                                                    className="w-full border border-gray-300 rounded-md p-2 text-sm"
                                                >
                                                    <option value="">All Statuses</option>
                                                    <option value="delivered">Delivered</option>
                                                    <option value="read">Read</option>
                                                    <option value="failed">Failed</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="sent">Sent</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Recipient
                                                </label>
                                                <input
                                                    type="text"
                                                    value={filters.recipient}
                                                    onChange={(e) => handleFilterChange('recipient', e.target.value)}
                                                    placeholder="Phone number..."
                                                    className="w-full border border-gray-300 rounded-md p-2 text-sm"
                                                />
                                            </div>
                                            <div className="flex items-end">
                                                <button
                                                    onClick={resetFilters}
                                                    className="text-blue-600 hover:text-blue-800 text-sm"
                                                >
                                                    Reset Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {messagesLoading ? (
                                    <div className="flex justify-center items-center h-64">
                                        <Loader size={36} className="text-blue-600 animate-spin" />
                                    </div>
                                ) : messagesError ? (
                                    <div className="p-4 text-red-500">{messagesError}</div>
                                ) : (
                                    <>
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() => handleSort('templateName')}
                                                        >
                                                            <div className="flex items-center space-x-1">
                                                                <span>Template Name</span>
                                                                {filters.sortBy === 'templateName' && (
                                                                    filters.sortOrder === 'asc' ? 
                                                                    <ChevronUp size={14} /> : 
                                                                    <ChevronDown size={14} />
                                                                )}
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            <span>Recipient</span>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() => handleSort('status')}
                                                        >
                                                            <div className="flex items-center space-x-1">
                                                                <span>Status</span>
                                                                {filters.sortBy === 'status' && (
                                                                    filters.sortOrder === 'asc' ? 
                                                                    <ChevronUp size={14} /> : 
                                                                    <ChevronDown size={14} />
                                                                )}
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() => handleSort('timestamp')}
                                                        >
                                                            <div className="flex items-center space-x-1">
                                                                <span>Date</span>
                                                                {filters.sortBy === 'timestamp' && (
                                                                    filters.sortOrder === 'asc' ? 
                                                                    <ChevronUp size={14} /> : 
                                                                    <ChevronDown size={14} />
                                                                )}
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {messages.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                                                                No messages found matching your criteria
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        messages.map((message) => (
                                                            <tr key={message.id} className="hover:bg-gray-50">
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm font-medium text-gray-900">
                                                                        {message.templateName}
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm text-gray-500">
                                                                        {message.recipient}
                                                                        {message.recipientName && (
                                                                            <span className="text-xs text-gray-400 ml-2">
                                                                                ({message.recipientName})
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(message.status)}`}>
                                                                        {message.status.charAt(0).toUpperCase() + message.status.slice(1)}
                                                                    </span>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                    {format(new Date(message.timestamp), 'MMM dd, yyyy HH:mm')}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                        {/* Pagination */}
                                        {pagination.totalPages > 1 && (
                                            <div className="px-4 py-3 bg-gray-50 border-t border-gray-200 sm:px-6 flex items-center justify-between">
                                                <div className="flex-1 flex justify-between sm:hidden">
                                                    <button
                                                        onClick={() => goToPage(pagination.page - 1)}
                                                        disabled={pagination.page === 1}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${pagination.page === 1 ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        onClick={() => goToPage(pagination.page + 1)}
                                                        disabled={pagination.page === pagination.totalPages}
                                                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${pagination.page === pagination.totalPages ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                                    <div>
                                                        <p className="text-sm text-gray-700">
                                                            Showing <span className="font-medium">{messages.length ? (pagination.page - 1) * pagination.limit + 1 : 0}</span> to <span className="font-medium">{Math.min(pagination.page * pagination.limit, totalMessages)}</span> of <span className="font-medium">{totalMessages}</span> results
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                                            <button
                                                                onClick={() => goToPage(1)}
                                                                disabled={pagination.page === 1}
                                                                className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium ${pagination.page === 1 ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-500 hover:bg-gray-50'}`}
                                                            >
                                                                <span className="sr-only">First Page</span>
                                                                <ChevronLeft className="h-5 w-5" />
                                                                <ChevronLeft className="h-5 w-5 -ml-2" />
                                                            </button>
                                                            <button
                                                                onClick={() => goToPage(pagination.page - 1)}
                                                                disabled={pagination.page === 1}
                                                                className={`relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium ${pagination.page === 1 ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-500 hover:bg-gray-50'}`}
                                                            >
                                                                <span className="sr-only">Previous</span>
                                                                <ChevronLeft className="h-5 w-5" />
                                                            </button>
                                                            
                                                            {/* Page Numbers */}
                                                            {[...Array(Math.min(5, pagination.totalPages))].map((_, i) => {
                                                                // Logic to display page numbers around current page
                                                                let pageNum;
                                                                if (pagination.totalPages <= 5) {
                                                                    pageNum = i + 1;
                                                                } else if (pagination.page <= 3) {
                                                                    pageNum = i + 1;
                                                                } else if (pagination.page >= pagination.totalPages - 2) {
                                                                    pageNum = pagination.totalPages - 4 + i;
                                                                } else {
                                                                    pageNum = pagination.page - 2 + i;
                                                                }
                                                                
                                                                return (
                                                                    <button
                                                                        key={pageNum}
                                                                        onClick={() => goToPage(pageNum)}
                                                                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${pagination.page === pageNum ? 'z-10 bg-blue-50 border-blue-500 text-blue-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'}`}
                                                                    >
                                                                        {pageNum}
                                                                    </button>
                                                                );
                                                            })}
                                                            
                                                            <button
                                                                onClick={() => goToPage(pagination.page + 1)}
                                                                disabled={pagination.page === pagination.totalPages}
                                                                className={`relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium ${pagination.page === pagination.totalPages ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-500 hover:bg-gray-50'}`}
                                                            >
                                                                <span className="sr-only">Next</span>
                                                                <ChevronRight className="h-5 w-5" />
                                                            </button>
                                                            <button
                                                                onClick={() => goToPage(pagination.totalPages)}
                                                                disabled={pagination.page === pagination.totalPages}
                                                                className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium ${pagination.page === pagination.totalPages ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-500 hover:bg-gray-50'}`}
                                                            >
                                                                <span className="sr-only">Last Page</span>
                                                                <ChevronRight className="h-5 w-5" />
                                                                <ChevronRight className="h-5 w-5 -ml-2" />
                                                            </button>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </main>
        </div>
    );
};

export default TemplatePerformanceDashboard; 