import React, { useState, useEffect } from 'react';
import { apiService } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { ChevronDown, Send, AlertCircle, Loader, CheckCircle, Image, X, FileText } from 'lucide-react';

const TemplateSender = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [formFields, setFormFields] = useState({
        phone: '',
        language_code: 'en_US',
        components: []
    });
    const [previewData, setPreviewData] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [fetchingTemplates, setFetchingTemplates] = useState(true);

    // Fetch available templates on component mount
    useEffect(() => {
        fetchTemplates();
    }, []);

    // Update form fields when template is selected
    useEffect(() => {
        if (selectedTemplate) {
            generateFormFields(selectedTemplate);
        }
    }, [selectedTemplate]);

    const fetchTemplates = async () => {
        setFetchingTemplates(true);
        try {
            const response = await apiService.getTemplates({
                status: 'APPROVED', // Only show approved templates
                limit: 100
            });
            
            if (response.data?.status === 'success' && response.data?.data?.data) {
                setTemplates(response.data.data.data);
            } else {
                throw new Error('Invalid response structure');
            }
        } catch (err) {
            setError('Failed to load templates');
            console.error('Template loading error:', err);
        } finally {
            setFetchingTemplates(false);
        }
    };

    const generateFormFields = (template) => {
        let components = [];
        
        // Process header components if available
        if (template.components?.find(c => c.type === 'HEADER')) {
            const headerComponent = template.components.find(c => c.type === 'HEADER');
            if (headerComponent.format === 'IMAGE' || headerComponent.format === 'VIDEO' || headerComponent.format === 'DOCUMENT') {
                components.push({
                    type: 'header',
                    parameters: [
                        {
                            type: headerComponent.format.toLowerCase(),
                            [headerComponent.format.toLowerCase()]: {
                                link: ''
                            },
                            text: '' // Add text field for compatibility with both formats
                        }
                    ]
                });
            } else if (headerComponent.format === 'TEXT') {
                // For text headers with variables
                const params = [];
                for (let i = 0; i < headerComponent.text.match(/\{\{[^}]+\}\}/g)?.length || 0; i++) {
                    params.push({
                        type: 'text',
                        text: ''
                    });
                }
                
                if (params.length > 0) {
                    components.push({
                        type: 'header',
                        parameters: params
                    });
                }
            }
        }
        
        // Process body components if available
        if (template.components?.find(c => c.type === 'BODY')) {
            const bodyComponent = template.components.find(c => c.type === 'BODY');
            const bodyVariables = bodyComponent.text.match(/\{\{[^}]+\}\}/g) || [];
            
            if (bodyVariables.length > 0) {
                const params = bodyVariables.map(() => ({
                    type: 'text',
                    text: ''
                }));
                
                components.push({
                    type: 'body',
                    parameters: params
                });
            }
        }
        
        // Process button components if available
        if (template.components?.some(c => c.type === 'BUTTONS')) {
            const buttonComponents = template.components
                .filter(c => c.type === 'BUTTONS')
                .flatMap(c => c.buttons || []);
                
            buttonComponents.forEach((button, index) => {
                if (button.type === 'URL' && button.text.includes('{{')) {
                    components.push({
                        type: 'button',
                        sub_type: 'url',
                        index: index.toString(),
                        parameters: [
                            {
                                type: 'text',
                                text: ''
                            }
                        ]
                    });
                }
            });
        }
        
        setFormFields({
            phone: '',
            language_code: template.language || 'en_US',
            components
        });
        
        // Generate preview data
        setPreviewData({
            templateName: template.name,
            header: template.components?.find(c => c.type === 'HEADER'),
            body: template.components?.find(c => c.type === 'BODY'),
            buttons: template.components?.find(c => c.type === 'BUTTONS')?.buttons || []
        });
    };

    const handleTemplateChange = (e) => {
        const templateId = e.target.value;
        const template = templates.find(t => t.id === templateId);
        setSelectedTemplate(template);
        setError(null);
        setSuccess(null);
    };

    const handleInputChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value
        });
    };

    const handleParameterChange = (componentIndex, paramIndex, value) => {
        const updatedComponents = [...formFields.components];
        updatedComponents[componentIndex].parameters[paramIndex].text = value;
        
        setFormFields({
            ...formFields,
            components: updatedComponents
        });
    };

    const handleImageLinkChange = (componentIndex, paramIndex, value) => {
        const updatedComponents = [...formFields.components];
        const param = updatedComponents[componentIndex].parameters[paramIndex];
        
        // Handle different media types
        if (param.type === 'image') {
            param.image.link = value;
        } else if (param.type === 'document') {
            param.document.link = value;
        } else if (param.type === 'video') {
            param.video.link = value;
        }
        
        // Also update the text field for fallback/compatibility
        param.text = value;
        
        setFormFields({
            ...formFields,
            components: updatedComponents
        });
    };

    const validateForm = () => {
        if (!formFields.phone) {
            setError('Phone number is required');
            return false;
        }
        
        if (!selectedTemplate) {
            setError('Please select a template');
            return false;
        }
        
        // Check if all required parameters are filled
        for (const component of formFields.components) {
            for (const param of component.parameters) {
                if (param.type === 'text' && !param.text) {
                    setError('Please fill all template parameters');
                    return false;
                }
                if (param.type === 'image' && !param.image.link) {
                    setError('Please provide an image URL');
                    return false;
                }
            }
        }
        
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        
        if (!validateForm()) {
            return;
        }
        
        setLoading(true);
        
        try {
            const payload = {
                phone: formFields.phone,
                template_name: selectedTemplate.name,
                language_code: formFields.language_code,
                components: formFields.components
            };
            
            console.log('Sending template payload:', payload);
            
            // Use the dedicated sendTemplate method from apiService
            const response = await apiService.sendTemplate(payload);
            
            setSuccess('Template message sent successfully!');
            console.log('Template sent response:', response);
            
            // Reset form fields but keep the template selected
            const currentTemplate = selectedTemplate;
            setSelectedTemplate(null);
            setTimeout(() => setSelectedTemplate(currentTemplate), 0);
            
        } catch (err) {
            setError(err.message || 'Failed to send template message');
            console.error('Template sending error:', err);
        } finally {
            setLoading(false);
        }
    };

    const renderFormFields = () => {
        if (!selectedTemplate) {
            return null;
        }
        
        return (
            <>
                <div className="space-y-4 mt-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Recipient Phone Number
                        </label>
                        <input
                            type="text"
                            name="phone"
                            value={formFields.phone}
                            onChange={handleInputChange}
                            placeholder="919447111230"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            required
                        />
                        <p className="mt-1 text-xs text-gray-500">
                            Enter the full number with country code, without + or spaces
                        </p>
                    </div>
                    
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Language
                        </label>
                        <select
                            name="language_code"
                            value={formFields.language_code}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                            <option value="en_US">English (US)</option>
                            <option value="en_GB">English (UK)</option>
                            <option value="es_ES">Spanish</option>
                            <option value="pt_BR">Portuguese (Brazil)</option>
                            <option value="hi_IN">Hindi</option>
                            <option value="ar_AR">Arabic</option>
                            <option value="id_ID">Indonesian</option>
                            <option value="fr_FR">French</option>
                            <option value="de_DE">German</option>
                            <option value="it_IT">Italian</option>
                            <option value="ru_RU">Russian</option>
                            <option value="ja_JP">Japanese</option>
                            <option value="ko_KR">Korean</option>
                            <option value="zh_CN">Chinese (Simplified)</option>
                            <option value="zh_TW">Chinese (Traditional)</option>
                            <option value="nl_NL">Dutch</option>
                            <option value="tr_TR">Turkish</option>
                            <option value="vi_VN">Vietnamese</option>
                            <option value="th_TH">Thai</option>
                        </select>
                    </div>
                    
                    {formFields.components.map((component, componentIndex) => {
                        if (component.type === 'header') {
                            return (
                                <div key={`component-${componentIndex}`} className="p-3 bg-gray-50 rounded-md">
                                    <h3 className="text-sm font-medium text-gray-700 mb-2">Header Parameters</h3>
                                    {component.parameters.map((param, paramIndex) => {
                                        if (param.type === 'image') {
                                            return (
                                                <div key={`param-${paramIndex}`}>
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                        Image URL
                                                    </label>
                                                    <div className="flex">
                                                        <input
                                                            type="text"
                                                            value={param.image.link}
                                                            onChange={(e) => handleImageLinkChange(componentIndex, paramIndex, e.target.value)}
                                                            placeholder="https://example.com/image.jpg"
                                                            className="flex-1 px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                        />
                                                        <div className="inline-flex items-center px-3 py-2 text-sm text-gray-500 bg-gray-100 border border-l-0 border-gray-300 rounded-r-md">
                                                            <Image className="w-4 h-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else if (param.type === 'document') {
                                            return (
                                                <div key={`param-${paramIndex}`}>
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                        Document URL
                                                    </label>
                                                    <div className="flex">
                                                        <input
                                                            type="text"
                                                            value={param.document?.link || param.text || ''}
                                                            onChange={(e) => handleImageLinkChange(componentIndex, paramIndex, e.target.value)}
                                                            placeholder="https://example.com/document.pdf"
                                                            className="flex-1 px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                        />
                                                        <div className="inline-flex items-center px-3 py-2 text-sm text-gray-500 bg-gray-100 border border-l-0 border-gray-300 rounded-r-md">
                                                            <FileText className="w-4 h-4" />
                                                        </div>
                                                    </div>
                                                    <p className="mt-1 text-xs text-gray-500">
                                                        Provide a URL to a publicly accessible PDF or document
                                                    </p>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={`param-${paramIndex}`}>
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                        Header Parameter {paramIndex + 1}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={param.text}
                                                        onChange={(e) => handleParameterChange(componentIndex, paramIndex, e.target.value)}
                                                        placeholder="Enter value"
                                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            );
                        } else if (component.type === 'body') {
                            return (
                                <div key={`component-${componentIndex}`} className="p-3 bg-gray-50 rounded-md">
                                    <h3 className="text-sm font-medium text-gray-700 mb-2">Body Parameters</h3>
                                    {component.parameters.map((param, paramIndex) => (
                                        <div key={`param-${paramIndex}`}>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Body Parameter {paramIndex + 1}
                                            </label>
                                            <input
                                                type="text"
                                                value={param.text}
                                                onChange={(e) => handleParameterChange(componentIndex, paramIndex, e.target.value)}
                                                placeholder="Enter value"
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                            />
                                        </div>
                                    ))}
                                </div>
                            );
                        } else if (component.type === 'button') {
                            return (
                                <div key={`component-${componentIndex}`} className="p-3 bg-gray-50 rounded-md">
                                    <h3 className="text-sm font-medium text-gray-700 mb-2">Button Parameters (Button {parseInt(component.index) + 1})</h3>
                                    {component.parameters.map((param, paramIndex) => (
                                        <div key={`param-${paramIndex}`}>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Button Parameter
                                            </label>
                                            <input
                                                type="text"
                                                value={param.text}
                                                onChange={(e) => handleParameterChange(componentIndex, paramIndex, e.target.value)}
                                                placeholder="Enter value"
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                            />
                                        </div>
                                    ))}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
                
                <button
                    type="submit"
                    disabled={loading}
                    className="mt-6 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300"
                >
                    {loading ? (
                        <>
                            <Loader className="animate-spin mr-2 h-4 w-4" />
                            Sending...
                        </>
                    ) : (
                        <>
                            <Send className="mr-2 h-4 w-4" />
                            Send Template Message
                        </>
                    )}
                </button>
            </>
        );
    };

    const renderTemplatePreview = () => {
        if (!previewData) {
            return null;
        }
        
        // Function to replace placeholders with values
        const replacePlaceholders = (text, componentType) => {
            if (!text) return text;
            
            const component = formFields.components.find(c => c.type === componentType);
            if (!component) return text;
            
            let result = text;
            const placeholders = text.match(/\{\{[^}]+\}\}/g) || [];
            
            placeholders.forEach((placeholder, i) => {
                const paramValue = component.parameters[i]?.text || `[${i + 1}]`;
                result = result.replace(placeholder, paramValue);
            });
            
            return result;
        };
        
        return (
            <div className="mt-6 border rounded-lg overflow-hidden">
                <div className="bg-gray-50 px-4 py-2 border-b">
                    <h3 className="text-sm font-medium text-gray-700">Template Preview</h3>
                </div>
                <div className="p-4 bg-white">
                    <div className="max-w-xs mx-auto">
                        <div className="rounded-lg overflow-hidden shadow-md bg-white">
                            {previewData.header && (
                                <div className="p-3 bg-gray-100 border-b">
                                    {previewData.header.format === 'TEXT' && (
                                        <p className="font-medium text-gray-800">
                                            {replacePlaceholders(previewData.header.text, 'header')}
                                        </p>
                                    )}
                                    {previewData.header.format === 'IMAGE' && (
                                        <div className="relative h-40 bg-gray-200 flex items-center justify-center">
                                            {formFields.components.find(c => c.type === 'header')?.parameters[0]?.image?.link ? (
                                                <img 
                                                    src={formFields.components.find(c => c.type === 'header')?.parameters[0]?.image?.link}
                                                    alt="Header" 
                                                    className="max-h-full max-w-full object-contain"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = 'https://via.placeholder.com/300x200?text=Invalid+Image';
                                                    }}
                                                />
                                            ) : (
                                                <div className="text-gray-400 flex flex-col items-center">
                                                    <Image className="w-8 h-8 mb-2" />
                                                    <span className="text-xs">Image Preview</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            
                            {previewData.body && (
                                <div className="p-3">
                                    <p className="text-gray-700 text-sm whitespace-pre-line">
                                        {replacePlaceholders(previewData.body.text, 'body')}
                                    </p>
                                </div>
                            )}
                            
                            {previewData.buttons && previewData.buttons.length > 0 && (
                                <div className="px-3 pb-3 space-y-2">
                                    {previewData.buttons.map((button, index) => {
                                        let buttonText = button.text;
                                        
                                        // If button contains placeholders, replace them
                                        if (button.text.includes('{{')) {
                                            const buttonComponent = formFields.components.find(c => 
                                                c.type === 'button' && c.index === index.toString()
                                            );
                                            
                                            if (buttonComponent) {
                                                buttonText = replacePlaceholders(button.text, 'button');
                                            }
                                        }
                                        
                                        return (
                                            <button 
                                                key={index}
                                                className="w-full py-2 px-3 bg-gray-100 rounded-md text-indigo-600 text-sm font-medium hover:bg-gray-200"
                                                disabled
                                            >
                                                {buttonText}
                                            </button>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTemplateOptions = () => {
        // Group templates by name and category
        const groupedTemplates = {};
        
        templates.forEach(template => {
            const key = `${template.name}_${template.category}`;
            if (!groupedTemplates[key]) {
                groupedTemplates[key] = [];
            }
            groupedTemplates[key].push(template);
        });
        
        return Object.entries(groupedTemplates).map(([key, templatesGroup]) => {
            const [name, category] = key.split('_');
            
            // If only one template in group, render a simple option
            if (templatesGroup.length === 1) {
                const template = templatesGroup[0];
                return (
                    <option key={template.id} value={template.id}>
                        {template.name} ({template.category}) - {template.language}
                    </option>
                );
            }
            
            // If multiple templates with same name/category, group them in an optgroup
            return (
                <optgroup key={key} label={`${name} (${category})`}>
                    {templatesGroup.map(template => (
                        <option key={template.id} value={template.id}>
                            {template.language}
                        </option>
                    ))}
                </optgroup>
            );
        });
    };

    return (
        <div className="bg-white shadow overflow-hidden rounded-lg">
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Send WhatsApp Template Message
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Select a template and fill in the required information to send a message.
                </p>
            </div>
            
            {error && (
                <div className="mx-4 my-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700 flex items-start">
                    <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                    <span>{error}</span>
                </div>
            )}
            
            {success && (
                <div className="mx-4 my-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700 flex items-start">
                    <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                    <span>{success}</span>
                </div>
            )}
            
            <div className="px-4 py-5 sm:p-6">
                <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Select Template
                            </label>
                            <div className="relative">
                                <select
                                    value={selectedTemplate?.id || ''}
                                    onChange={handleTemplateChange}
                                    className="w-full appearance-none px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    disabled={fetchingTemplates}
                                >
                                    <option value="">-- Select a template --</option>
                                    {renderTemplateOptions()}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    {fetchingTemplates ? 
                                        <Loader className="h-4 w-4 animate-spin" /> : 
                                        <ChevronDown className="h-4 w-4" />
                                    }
                                </div>
                            </div>
                            {fetchingTemplates && (
                                <p className="mt-1 text-xs text-gray-500">Loading templates...</p>
                            )}
                        </div>
                        
                        {selectedTemplate && (
                            <div className="p-3 bg-blue-50 border border-blue-200 rounded-md">
                                <h4 className="text-sm font-medium text-blue-800 mb-1">
                                    {selectedTemplate.name}
                                </h4>
                                <p className="text-xs text-blue-600">
                                    Category: {selectedTemplate.category} | Language: {selectedTemplate.language}
                                </p>
                            </div>
                        )}
                    </div>
                    
                    {renderFormFields()}
                </form>
                
                {renderTemplatePreview()}
            </div>
        </div>
    );
};

export default TemplateSender; 