import React from 'react';

const variantStyles = {
    primary: 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:shadow-md',
    secondary: 'bg-white border border-gray-200 text-gray-700 hover:bg-gray-50',
    success: 'bg-green-500 text-white hover:bg-green-600',
    danger: 'bg-red-500 text-white hover:bg-red-600',
    warning: 'bg-yellow-500 text-white hover:bg-yellow-600'
};

const sizeStyles = {
    sm: 'py-1.5 px-3 text-xs',
    md: 'py-2 px-4 text-sm',
    lg: 'py-2.5 px-5 text-base'
};

const Button = ({ 
    children, 
    variant = 'primary', 
    size = 'md', 
    icon,
    iconPosition = 'left',
    fullWidth = false,
    disabled = false,
    onClick,
    type = 'button',
    className = ''
}) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`
                ${variantStyles[variant]}
                ${sizeStyles[size]}
                ${fullWidth ? 'w-full' : ''}
                rounded-lg font-medium transition-all duration-200
                flex items-center justify-center
                ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:translate-y-[-1px]'}
                ${className}
            `}
        >
            {icon && iconPosition === 'left' && <span className="mr-2">{icon}</span>}
            {children}
            {icon && iconPosition === 'right' && <span className="ml-2">{icon}</span>}
        </button>
    );
};

export default Button; 