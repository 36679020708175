//src/components/flows/ActionNodeProperties.js
import React, { useState } from 'react';
import ActionNodeResponseMapping from './ActionNodeResponseMapping';
import {
    Settings,
    FileCode,
    ArrowLeftRight,
    SlidersHorizontal,
    Link,
    Webhook,
    Code,
    Trash2
} from 'lucide-react';
const ActionNodeProperties = ({ data, onChange }) => {
    const [activeTab, setActiveTab] = useState('config');
    const [newMapping, setNewMapping] = useState({ target: '', source: '' });

    // Helper function to update nested data
    const updateData = (updates) => {
        onChange({
            ...data,
            ...updates
        });
    };



    return (
        <div className="space-y-6">
            {/* Existing Action Type Selection */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Action Type</label>
                <div className="grid grid-cols-3 gap-2">
                    {[
                        { value: 'api_call', icon: Link, label: 'API Call' },
                        //{ value: 'webhook', icon: Webhook, label: 'Webhook' },
                        { value: 'function', icon: Code, label: 'Function' },
                    ].map(({ value, icon: Icon, label }) => (
                        <button
                            key={value}
                            onClick={() => updateData({ actionType: value })}
                            className={`
                                p-3 flex flex-col items-center gap-1 rounded-lg border-2 transition-all
                                ${data.actionType === value
                                    ? 'border-blue-500 bg-blue-50 text-blue-700'
                                    : 'border-gray-200 hover:border-gray-300 text-gray-600 hover:bg-gray-50'
                                }
                            `}
                        >
                            <Icon size={20} />
                            <span className="text-xs font-medium">{label}</span>
                        </button>
                    ))}
                </div>
            </div>

            {/* Custom Tab Navigation */}
            {data.actionType && data.actionType === 'api_call' && (
                <div className="space-y-4">
                    <div className="border-b">
                        <div className="flex">
                            {[
                                { id: 'config', icon: Settings, label: 'Cf' },
                                { id: 'headers', icon: FileCode, label: 'He' },
                                { id: 'response', icon: ArrowLeftRight, label: 'Re' },
                                { id: 'advanced', icon: SlidersHorizontal, label: 'Ad' }
                            ].map((tab) => (
                                <button
                                    key={tab.id}
                                    onClick={() => setActiveTab(tab.id)}
                                    className={`
              px-4 py-2 text-sm font-medium border-b-2 transition-colors flex items-center gap-2
              ${activeTab === tab.id
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        }
            `}
                                >
                                    <tab.icon size={16} />
                                    {tab.label}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Configuration Tab */}
                    {activeTab === 'config' && (
                        <div className="space-y-4">
                            {data.actionType === 'api_call' && (
                                <>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Method</label>
                                        <select
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={data.method || 'GET'}
                                            onChange={(e) => updateData({ method: e.target.value })}
                                        >
                                            <option value="">Select METHOD</option>
                                            <option value="GET">GET</option>
                                            <option value="POST">POST</option>
                                            <option value="PUT">PUT</option>
                                            <option value="DELETE">DELETE</option>
                                            <option value="PATCH">PATCH</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Endpoint URL</label>
                                        <input
                                            type="url"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={data.endpoint || ''}
                                            onChange={(e) => updateData({ endpoint: e.target.value })}
                                            placeholder="https://api.example.com/endpoint"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Request Body</label>
                                        <textarea
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 font-mono text-sm"
                                            rows={4}
                                            value={data.body || ''}
                                            onChange={(e) => updateData({ body: e.target.value })}
                                            placeholder='{"key": "value"}'
                                        />
                                    </div>
                                </>
                            )}

                        </div>
                    )}

                    {/* Headers Tab - Existing implementation */}
                    {activeTab === 'headers' && (
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Request Headers</label>
                                <textarea
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 font-mono text-sm"
                                    rows={6}
                                    value={data.headers || ''}
                                    onChange={(e) => updateData({ headers: e.target.value })}
                                    placeholder={'{\n  "Content-Type": "application/json",\n  "Authorization": "Bearer {{token}}"\n}'}
                                />
                            </div>
                        </div>
                    )}

                    {/* New Response Tab */}
                    {activeTab === 'response' && (
                        <ActionNodeResponseMapping
                            data={data}
                            onChange={updateData}
                        />
                    )}

                    {/* Advanced Tab */}
                    {activeTab === 'advanced' && (
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Success Check Path</label>
                                <input
                                    type="text"
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    value={data.successPath || ''}
                                    onChange={(e) => updateData({ successPath: e.target.value })}
                                    placeholder="response.success"
                                />
                                <p className="mt-1 text-xs text-gray-500">
                                    Path to check for success in the response (e.g., response.success)
                                </p>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Retry Configuration</label>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-xs text-gray-600 mb-1">Max Retries</label>
                                        <input
                                            type="number"
                                            min="0"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={data.maxRetries || ''}
                                            onChange={(e) => updateData({ maxRetries: e.target.value })}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-xs text-gray-600 mb-1">Retry Delay (ms)</label>
                                        <input
                                            type="number"
                                            min="0"
                                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            value={data.retryDelay || ''}
                                            onChange={(e) => updateData({ retryDelay: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Timeout (ms)</label>
                                <input
                                    type="number"
                                    min="0"
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    value={data.timeout || ''}
                                    onChange={(e) => updateData({ timeout: e.target.value })}
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Error Handling</label>
                                <select
                                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    value={data.errorHandling || ''}
                                    onChange={(e) => updateData({ errorHandling: e.target.value })}
                                >
                                    <option value="stop">Stop Flow</option>
                                    <option value="continue">Continue Flow</option>
                                    <option value="retry">Retry Action</option>
                                    <option value="alternate">Use Alternate Path</option>
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            )}


            {data.actionType === 'function' && (
                <>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Function Name</label>
                        <select
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            value={data.functionName || ''}
                            onChange={(e) => updateData({ functionName: e.target.value })}
                        >
                            <option value="">Select function...</option>
                            <optgroup label="String Functions">
                                <option value="String.prototype.toLowerCase">toLowerCase</option>
                                <option value="String.prototype.toUpperCase">toUpperCase</option>
                                <option value="String.prototype.trim">trim</option>
                                <option value="String.prototype.substring">substring</option>
                                <option value="String.prototype.split">split</option>
                                <option value="String.prototype.replace">replace</option>
                            </optgroup>
                            <optgroup label="Number Functions">
                                <option value="Number.parseInt">parseInt</option>
                                <option value="Number.parseFloat">parseFloat</option>
                                <option value="Number.prototype.toFixed">toFixed</option>
                                <option value="Math.round">Math.round</option>
                                <option value="Math.floor">Math.floor</option>
                                <option value="Math.ceil">Math.ceil</option>
                                <option value="Math.abs">Math.abs</option>
                                <option value="Math.random">Math.random</option>

                            </optgroup>
                            <optgroup label="Array Functions">
                                <option value="Array.prototype.join">join</option>
                                <option value="Array.prototype.slice">slice</option>
                                <option value="Array.prototype.indexOf">indexOf</option>
                                <option value="Array.from">Array.from</option>
                                <option value="Array.isArray">Array.isArray</option>
                                <option value="custom.formatNumberedList">Format Numbered List</option>
                            </optgroup>
                            <optgroup label="Date Functions">
                                <option value="Date.now">Date.now</option>
                                <option value="Date.parse">Date.parse</option>
                                <option value="Date.prototype.toLocaleString">toLocaleString</option>
                                <option value="Date.prototype.toISOString">toISOString</option>
                            </optgroup>
                            <optgroup label="Object Functions">
                                <option value="Object.keys">Object.keys</option>
                                <option value="Object.values">Object.values</option>
                                <option value="Object.entries">Object.entries</option>
                                <option value="Object.assign">Object.assign</option>
                            </optgroup>
                            <optgroup label="JSON Functions">
                                <option value="JSON.parse">JSON.parse</option>
                                <option value="JSON.stringify">JSON.stringify</option>
                            </optgroup>
                            <optgroup label="Global Functions">
                                <option value="encodeURIComponent">encodeURIComponent</option>
                                <option value="decodeURIComponent">decodeURIComponent</option>
                                <option value="parseInt">parseInt</option>
                                <option value="parseFloat">parseFloat</option>
                            </optgroup>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Parameters</label>

                        <textarea
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 font-mono text-sm"
                            rows={4}
                            value={data.parameters || ''}
                            onChange={(e) => updateData({ parameters: e.target.value })}
                            placeholder='["param1", "param2"]'
                        />


                        <p className="mt-1 text-xs text-gray-500">
                            Enter parameters as JSON array. You can use variables like {'{{user.name}}'}
                        </p>
                    </div>

                    {/* Add Variable Storage Section */}
                    <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                        <h4 className="text-sm font-medium text-gray-700 mb-3">Store Result</h4>
                        <div className="space-y-3">
                            <div>
                                <label className="block text-xs text-gray-500 mb-1">Variable Context</label>
                                <select
                                    className="w-full rounded-md border-gray-300 text-sm"
                                    value={data.variableContext || 'flow'}
                                    onChange={(e) => updateData({ variableContext: e.target.value })}
                                >
                                    <option value="flow">Flow Context</option>
                                    <option value="user">User Context</option>
                                    <option value="session">Session Context</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-xs text-gray-500 mb-1">Variable Name</label>
                                <input
                                    type="text"
                                    className="w-full rounded-md border-gray-300 text-sm"
                                    value={data.variableName || ''}
                                    onChange={(e) => updateData({ variableName: e.target.value })}
                                    placeholder="Enter variable name"
                                />
                            </div>
                        </div>
                    </div>

                </>
            )}


        </div>
    );
};

export default ActionNodeProperties;