import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { apiService } from '../../services/api';
import {
    Bell,
    Calendar,
    Clock,
    Filter,
    Search,
    CheckCircle,
    User,
    MessageSquare,
    AlertTriangle,
    RefreshCcw
} from 'lucide-react';
import { format } from 'date-fns';

const ActivityLog = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activities, setActivities] = useState([]);
    const [filters, setFilters] = useState({
        type: '',
        startDate: '',
        endDate: '',
        search: ''
    });
    const [unreadCount, setUnreadCount] = useState(0);
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 20,
        pages: 1
    });

    const fetchActivities = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            
            // Build params object for fetchWithAuth
            const params = {};
            if (filters.type) params.type = filters.type;
            if (filters.startDate) params.startDate = filters.startDate;
            if (filters.endDate) params.endDate = filters.endDate;
            if (filters.search) params.search = filters.search;
            
            // If not admin, only fetch user's own activities
            if (user?.role !== 'admin') {
                params.userId = user.id;
            }
            //define method as GET
            params.method = 'GET';
            //console.log('params-------->',params);
            
            const response = await apiService.fetchWithAuth('/api/activities', {
                params
            });

            //console.log('response-------->',response);  
            
            if (response.activities) {
                setActivities(response.activities);
                // Count unread activities
                const unread = response.activities.filter(activity => !activity.read).length;
                setUnreadCount(unread);
                
                // Save pagination data if available
                if (response.pagination) {
                    setPagination(response.pagination);
                }
            } else {
                throw new Error('Invalid response structure');
            }
        } catch (err) {
            setError('Failed to load activities');
            console.error('Activity loading error:', err);
        } finally {
            setLoading(false);
        }
    }, [filters, user]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    const markAsRead = async (activityId) => {
        try {
            await apiService.fetchWithAuth('/api/activities/mark-read', {
                method: 'POST',
                body: JSON.stringify({
                    ids: [activityId],
                    markAllAsRead: false
                })
            });
            
            // Update local state
            setActivities(prevActivities => 
                prevActivities.map(activity => 
                    activity._id === activityId || activity.id === activityId
                        ? { ...activity, read: true } 
                        : activity
                )
            );
            
            // Update unread count
            setUnreadCount(prev => Math.max(0, prev - 1));
        } catch (err) {
            console.error('Error marking activity as read:', err);
        }
    };

    const markAllAsRead = async () => {
        try {
            await apiService.fetchWithAuth('/api/activities/mark-read', {
                method: 'POST',
                body: JSON.stringify({
                    ids: [],
                    markAllAsRead: true
                })
            });
            
            // Update local state
            setActivities(prevActivities => 
                prevActivities.map(activity => ({ ...activity, read: true }))
            );
            
            // Reset unread count
            setUnreadCount(0);
        } catch (err) {
            console.error('Error marking all activities as read:', err);
        }
    };

    const getActivityIcon = (type, sourceType) => {
        switch (sourceType) {
            case 'auth':
                return <User className="w-5 h-5 text-blue-500" />;
            case 'system':
                return <AlertTriangle className="w-5 h-5 text-orange-500" />;
            case 'whatsapp':
                return <MessageSquare className="w-5 h-5 text-green-500" />;
            case 'template':
                return <CheckCircle className="w-5 h-5 text-purple-500" />;
            default:
                return <Bell className="w-5 h-5 text-gray-500" />;
        }
    };

    const formatActivityContent = (activity) => {
        const { type, data, sourceType } = activity;
        
        switch (sourceType) {
            case 'auth':
                if (type === 'login_success') {
                    return `${data?.username || 'User'} logged in successfully`;
                } else if (type === 'login_failed') {
                    return `Failed login attempt for ${data?.username || 'unknown user'}`;
                }
                return type.replace(/_/g, ' ');
                
            case 'system':
                return type.replace(/_/g, ' ');
                
            case 'whatsapp':
                if (type === 'messages_status_updates') {
                    return `Message ${data?.status || 'status updated'} for ${data?.recipient || 'a recipient'}`;
                } else if (type === 'message_template_status_update') {
                    return `Template ${data?.name || ''} is now ${data?.status || 'updated'}`;
                }
                return type.replace(/_/g, ' ');
                
            case 'user':
                if (data?.action) {
                    return `${data.action.replace(/_/g, ' ')} ${data?.details || ''}`;
                }
                return type.replace(/_/g, ' ');
                
            default:
                return type.replace(/_/g, ' ');
        }
    };

    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: value
        }));
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                            Activity Log
                        </h1>

                        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                            <button
                                onClick={fetchActivities}
                                disabled={loading}
                                className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
                            >
                                <RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />
                                Refresh
                            </button>
                            {unreadCount > 0 && (
                                <button
                                    onClick={markAllAsRead}
                                    className="flex items-center justify-center gap-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                                >
                                    <CheckCircle size={16} />
                                    Mark All as Read ({unreadCount})
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </header>

            {/* Filters */}
            <div className="bg-white border-b">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                                <input
                                    type="text"
                                    placeholder="Search activities..."
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    value={filters.search}
                                    onChange={(e) => handleFilterChange('search', e.target.value)}
                                />
                            </div>
                        </div>
                        <select
                            className="border rounded-lg px-4 py-2 bg-white"
                            value={filters.type}
                            onChange={(e) => handleFilterChange('type', e.target.value)}
                        >
                            <option value="">All Types</option>
                            <option value="user_action">User Actions</option>
                            <option value="system_event">System Events</option>
                            <option value="whatsapp_event">WhatsApp Events</option>
                            <option value="template_status_update">Template Updates</option>
                        </select>
                        <div className="flex items-center gap-2">
                            <div className="relative">
                                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <input
                                    type="date"
                                    placeholder="Start Date"
                                    className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    value={filters.startDate}
                                    onChange={(e) => handleFilterChange('startDate', e.target.value)}
                                />
                            </div>
                            <span className="text-gray-500">to</span>
                            <div className="relative">
                                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <input
                                    type="date"
                                    placeholder="End Date"
                                    className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    value={filters.endDate}
                                    onChange={(e) => handleFilterChange('endDate', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <main className="flex-1 overflow-auto py-6 px-4 sm:px-6">
                <div className="max-w-7xl mx-auto">
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                        </div>
                    ) : error ? (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                            {error}
                        </div>
                    ) : activities.length === 0 ? (
                        <div className="bg-white rounded-lg shadow p-6 text-center">
                            <p className="text-gray-500">No activities found</p>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {activities.map((activity) => (
                                <div 
                                    key={activity._id || activity.id}
                                    onClick={() => !activity.read && markAsRead(activity._id || activity.id)}
                                    className={`
                                        bg-white rounded-lg shadow-sm p-4 border-l-4
                                        ${activity.read ? 'border-gray-200' : 'border-blue-500 cursor-pointer'}
                                        hover:shadow-md transition-shadow
                                    `}
                                >
                                    <div className="flex items-start gap-4">
                                        <div className="p-2 bg-gray-100 rounded-full">
                                            {getActivityIcon(activity.type, activity.sourceType)}
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex justify-between items-start">
                                                <h3 className={`font-medium ${activity.read ? 'text-gray-700' : 'text-gray-900'}`}>
                                                    {formatActivityContent(activity)}
                                                </h3>
                                                <div className="flex items-center gap-2 text-sm text-gray-500">
                                                    <Clock size={14} />
                                                    <span>{format(new Date(activity.timestamp), 'MMM d, yyyy h:mm a')}</span>
                                                </div>
                                            </div>
                                            
                                            {activity.userId && (
                                                <p className="text-sm text-gray-500 mt-1">
                                                    By: {activity.userId.username || activity.userId.email || 'Unknown User'}
                                                </p>
                                            )}
                                            
                                            {activity.data && Object.keys(activity.data).length > 0 && (
                                                <div className="mt-2 text-sm">
                                                    <details className="cursor-pointer">
                                                        <summary className="text-blue-500 hover:text-blue-700">Show details</summary>
                                                        <pre className="mt-2 p-2 bg-gray-50 rounded text-xs overflow-auto max-h-40">
                                                            {JSON.stringify(activity.data, null, 2)}
                                                        </pre>
                                                    </details>
                                                </div>
                                            )}
                                            
                                            {!activity.read && (
                                                <div className="mt-2">
                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                                        New
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default ActivityLog; 