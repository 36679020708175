//src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { apiService } from '../services/api';
import { socketService } from '../services/socket';
import activityLogger from '../utils/activityLogger';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    /*
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const data = await apiService.validateToken(token);
                    if (data?.user) {
                        let updatedUser = data.user;
                        // Add token to user object
                        updatedUser.token = token;
                        setUser(updatedUser.user);
                        // Initialize socket connection with the token
                        try {
                            socketService.connect(token);
                        } catch (err) {
                            console.error('Socket connection error:', err);
                        }
                    } else {
                        localStorage.removeItem('token');
                    }
                } catch (err) {
                    console.error('Auth validation error:', err);
                    localStorage.removeItem('token');
                }
            }
            setLoading(false);
        };

        checkAuth();

        // Cleanup function
        return () => {
            socketService.disconnect();
        };
    }, []);
    */

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    // Validate the token with your backend
                    const data = await apiService.validateToken(token);
                    let updatedUser = data.user;
                    // Add token to user object
                    updatedUser.token = token;
                    setUser(updatedUser);
                    
                    // Request notification permission for activities
                    activityLogger.requestNotificationPermission();
                } catch (err) {
                    console.error('Auth validation error:', err);
                    localStorage.removeItem('token');
                }
            }
            setLoading(false);
        };

        checkAuth();
    }, []);

    const clearError = () => {
        setError(null);
    };

    const login = async (username, password) => {
        try {
            // Clear any previous errors
            setError(null);
            
            // Use apiService instead of direct fetch
            const data = await apiService.login(username, password);
            
            // Store token and user data
            localStorage.setItem('token', data.token);
            
            // Update user state with token included
            let updatedUser = data.user;
            updatedUser.token = data.token;
            setUser(updatedUser);
            
            // Store additional user info in localStorage
            localStorage.setItem('role', data?.user?.role);
            localStorage.setItem('username', data?.user?.username);
            localStorage.setItem('email', data?.user?.email);
            
            // Log the login activity
            await activityLogger.logAuthActivity('login', updatedUser.id, {
                username: updatedUser.username,
                role: updatedUser.role
            });
            
            // Request notification permission
            activityLogger.requestNotificationPermission();
            
            return data;
        } catch (err) {
            // Set error message to be displayed in the UI
            const errorMessage = err.message || 'Invalid username or password. Please try again.';
            setError(errorMessage);
            throw err;
        }
    };

    const logout = async () => {
        try {
            // Log the logout activity before clearing user data
            if (user) {
                await activityLogger.logAuthActivity('logout', user.id, {
                    username: user.username,
                    role: user.role
                });
            }
            
            // Use apiService for logout
            await apiService.logout();
        } catch (err) {
            console.error('Logout error:', err);
        } finally {
            // Always clear local data
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('username');
            localStorage.removeItem('email');
            setUser(null);
        }
    };

    const value = {
        user,
        loading,
        error,
        setError,
        clearError,
        login,
        logout,
        isAuthenticated: !!user
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Hook for consuming the auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;

