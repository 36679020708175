import React, { useState, useEffect } from 'react';
import { ExternalLink, Instagram, User, Image as ImageIcon, FileVideo } from 'lucide-react';
import Modal from '../ui/Modal';
import { getInstagramContentType, getInstagramContentId, getInstagramEmbedUrl } from '../../utils/linkUtils';

const InstagramModal = ({ url, isOpen, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    // Get content type and ID
    const contentType = getInstagramContentType(url);
    const contentId = getInstagramContentId(url);
    const embedUrl = getInstagramEmbedUrl(url);
    
    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            setError(null);
            
            // Simulate a loading delay for embedded content
            const timer = setTimeout(() => {
                setLoading(false);
            }, 1000);
            
            return () => clearTimeout(timer);
        }
    }, [isOpen, url]);
    
    if (!url || !contentType || !contentId) {
        return null;
    }
    
    // Content for profile view
    const renderProfileContent = () => {
        return (
            <div className="flex flex-col items-center bg-white p-6 rounded-lg max-w-md mx-auto text-center">
                <div className="bg-gradient-to-tr from-yellow-400 via-red-500 to-purple-600 p-1 rounded-full mb-4">
                    <div className="bg-white p-1 rounded-full">
                        <User size={64} className="text-gray-800" />
                    </div>
                </div>
                <h3 className="text-xl font-bold mb-2">@{contentId}</h3>
                <p className="text-gray-600 mb-4">Instagram Profile</p>
                <a 
                    href={url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                >
                    <Instagram size={20} className="mr-2" />
                    View Profile on Instagram
                </a>
            </div>
        );
    };
    
    // Content for posts, reels, and stories
    const renderEmbeddedContent = () => {
        const contentTypeIcon = {
            post: <ImageIcon size={24} className="mr-2" />,
            reel: <FileVideo size={24} className="mr-2" />,
            story: <Instagram size={24} className="mr-2" />
        };
        
        const contentTypeLabel = {
            post: 'Post',
            reel: 'Reel',
            story: 'Story'
        };
        
        if (loading) {
            return (
                <div className="flex flex-col items-center justify-center p-6 bg-gray-50 rounded-lg animate-pulse min-h-[300px]">
                    <Instagram size={40} className="text-gray-300 mb-4" />
                    <p className="text-gray-400">Loading Instagram content...</p>
                </div>
            );
        }
        
        if (error) {
            return (
                <div className="flex flex-col items-center justify-center p-6 bg-gray-50 rounded-lg min-h-[200px]">
                    <p className="text-red-500 mb-4">Could not load Instagram content</p>
                    <a 
                        href={url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="flex items-center justify-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                    >
                        <Instagram size={20} className="mr-2" />
                        View on Instagram
                    </a>
                </div>
            );
        }
        
        // For posts and reels, we can attempt to embed them
        if ((contentType === 'post' || contentType === 'reel') && embedUrl) {
            return (
                <div className="flex flex-col">
                    <div className="aspect-square bg-gray-100 relative">
                        <iframe
                            src={embedUrl}
                            className="w-full h-full"
                            frameBorder="0"
                            scrolling="no"
                            allowtransparency="true"
                            title="Instagram Embedded Content"
                        ></iframe>
                        
                        {/* Fallback in case iframe doesn't load */}
                        <div className="absolute inset-0 bg-gray-100 opacity-0 pointer-events-none">
                            <div className="flex flex-col items-center justify-center h-full">
                                <p className="text-gray-500 mb-2">Instagram content couldn't be displayed</p>
                                <a 
                                    href={url} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:underline"
                                >
                                    View on Instagram
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
        // For stories and other content that can't be embedded
        return (
            <div className="flex flex-col items-center bg-white p-6 rounded-lg max-w-md mx-auto text-center">
                <div className="bg-gradient-to-tr from-yellow-400 via-red-500 to-purple-600 p-6 rounded-lg mb-4 inline-flex">
                    {contentTypeIcon[contentType] || <Instagram size={40} className="text-white" />}
                </div>
                <h3 className="text-xl font-bold mb-2">Instagram {contentTypeLabel[contentType]}</h3>
                <p className="text-gray-600 mb-4">
                    {contentType === 'story' 
                        ? `@${contentId}'s story can only be viewed on Instagram` 
                        : 'This content cannot be embedded'}
                </p>
                <a 
                    href={url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                >
                    <Instagram size={20} className="mr-2" />
                    View on Instagram
                </a>
            </div>
        );
    };
    
    const titleMap = {
        profile: `Instagram Profile: @${contentId}`,
        post: 'Instagram Post',
        reel: 'Instagram Reel',
        story: 'Instagram Story'
    };
    
    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
            size={contentType === 'profile' ? 'md' : 'lg'}
            title={titleMap[contentType] || 'Instagram Content'}
        >
            <div className="flex flex-col">
                {contentType === 'profile' ? renderProfileContent() : renderEmbeddedContent()}
                
                <div className="mt-4 flex justify-between items-center">
                    <a 
                        href={url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 flex items-center"
                    >
                        <ExternalLink size={16} className="mr-1" />
                        Open on Instagram
                    </a>
                </div>
            </div>
        </Modal>
    );
};

export default InstagramModal; 