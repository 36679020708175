import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SocketProvider } from './contexts/SocketContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from './components/layouts/AdminLayout';
import AgentChat from './components/chat/AgentChat';
import LoginForm from './components/auth/LoginForm';
import Loading from './components/shared/Loading';
import WhatsAppDashboard from './components/dashboard/WhatsAppDashboard';
import MetricsDashboard from './components/dashboard/MetricsDashboard';
import SentimentDashboard from './components/dashboard/SentimentDashboard';
import UserManagement from './components/dashboard/UserManagement';
import TemplateManager from './components/templates/TemplateManager';
import TemplateForm from './components/templates/TemplateForm';
import TemplateSender from './components/templates/TemplateSender';
import TemplatePerformanceDashboard from './components/templates/TemplatePerformanceDashboard';
import ChatFlowBuilder from './components/flows/ChatFlowBuilder';
import EmbeddedChatPage from './components/flows/EmbeddedChatPage';
import FlowList from './components/flows/FlowList';
import ActivityLog from './components/activities/ActivityLog';
import UserProfile from './components/profile/UserProfile';

// Protected Route with Layout Component
const ProtectedRoute = ({ children, layout: Layout = React.Fragment, requiredRole }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading fullScreen />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/" />;
  }

  return <Layout>{children}</Layout>;
};

// Public Route Component
const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading fullScreen />;
  }

  if (user) {
    return <Navigate to="/" />;
  }

  return children;
};

// Toast Component for notifications
const Toast = ({ message, type, onClose }) => {
  const types = {
    success: 'bg-green-100 border-green-400 text-green-700',
    error: 'bg-red-100 border-red-400 text-red-700',
    warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
    info: 'bg-blue-100 border-blue-400 text-blue-700',
  };
  
  return (
    <div className={`${types[type] || types.info} border px-4 py-3 rounded-lg shadow-md animate-fade-in relative max-w-md w-full`}>
      <strong className="font-bold mr-1">{type.charAt(0).toUpperCase() + type.slice(1)}:</strong>
      <span>{message}</span>
      <button
        className="absolute top-0 bottom-0 right-0 px-4 py-3"
        onClick={onClose}
      >
        <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M10 8.586l3.293-3.293a1 1 0 0 1 1.414 1.414L11.414 10l3.293 3.293a1 1 0 0 1-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 0 1-1.414-1.414L8.586 10 5.293 6.707a1 1 0 0 1 1.414-1.414L10 8.586z"
          />
        </svg>
      </button>
    </div>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <SocketProvider>
          <Routes>
            {/* Public Routes */}
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginForm />
                </PublicRoute>
              }
            />

            <Route path="/embedded-chat" element={<EmbeddedChatPage />} />

            {/* Admin Routes */}
            <Route
              path="/"
              element={<Navigate to="/admin/dashboard/overview" replace />}
            />

            {/* User Profile Route */}
            <Route
              path="/profile"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <UserProfile />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/dashboard/overview"
              element={
                <ProtectedRoute layout={AdminLayout} requiredRole="admin">
                  <WhatsAppDashboard />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/admin/dashboard/metrics"
              element={
                <ProtectedRoute layout={AdminLayout} requiredRole="admin">
                  <MetricsDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/dashboard/sentiment"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <SentimentDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/templates"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <TemplatePerformanceDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/users"
              element={
                <ProtectedRoute layout={AdminLayout} requiredRole="admin">
                  <UserManagement />
                </ProtectedRoute>
              }
            />

            {/* Activities Route */}
            <Route
              path="/activities"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <ActivityLog />
                </ProtectedRoute>
              }
            />

            {/* Template Routes */}
            <Route
              path="/templates"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <TemplateManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="/templates/create"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <TemplateForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/templates/edit/:id"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <TemplateForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/templates/send"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <TemplateSender />
                </ProtectedRoute>
              }
            />

            {/* Chat Flow Builder */}
            <Route
              path="/flows/list"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <FlowList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/flows"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <ChatFlowBuilder />
                </ProtectedRoute>
              }
            />

            {/* Chat Route */}
            <Route
              path="/chat"
              element={
                <ProtectedRoute layout={AdminLayout}>
                  <AgentChat />
                </ProtectedRoute>
              }
            />

            {/* Catch all route */}
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </Routes>

          {/* React Toastify Container */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </SocketProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;