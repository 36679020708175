import React, { useState, useEffect } from 'react';
import { ExternalLink, Youtube, Link, Image, AlertCircle } from 'lucide-react';
import { isYouTubeUrl, getYouTubeVideoId } from '../../utils/linkUtils';

const LinkPreview = ({ url, onClick }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const isYoutube = isYouTubeUrl(url);
    
    // Simulated metadata fetch - in a real app, you would fetch this from an API
    useEffect(() => {
        setLoading(true);
        setError(null);
        
        // Mock API delay
        const fetchTimeout = setTimeout(() => {
            try {
                if (isYoutube) {
                    const videoId = getYouTubeVideoId(url);
                    setMetadata({
                        title: 'YouTube Video',
                        description: 'Click to play this YouTube video',
                        image: `https://img.youtube.com/vi/${videoId}/0.jpg`,
                        siteName: 'YouTube'
                    });
                } else {
                    // For demo, we're setting mock data based on URL
                    // You would typically fetch this from an API that extracts metadata
                    const domain = new URL(url).hostname;
                    setMetadata({
                        title: `Website from ${domain}`,
                        description: 'Click to open this website',
                        image: null,
                        siteName: domain
                    });
                }
                setLoading(false);
            } catch (err) {
                console.error('Error processing URL:', err);
                setError('Could not load preview');
                setLoading(false);
            }
        }, 500); // Simulate network delay
        
        return () => clearTimeout(fetchTimeout);
    }, [url, isYoutube]);
    
    // Loading state
    if (loading) {
        return (
            <div className="border rounded-lg p-3 bg-gray-50 animate-pulse flex items-center space-x-2">
                <Link className="w-5 h-5 text-gray-400" />
                <span className="text-sm text-gray-500">Loading preview...</span>
            </div>
        );
    }
    
    // Error state
    if (error) {
        return (
            <div className="border rounded-lg p-3 bg-gray-50 flex items-center space-x-2">
                <AlertCircle className="w-5 h-5 text-red-500" />
                <span className="text-sm text-gray-700">{error}</span>
                <a 
                    href={url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-500 text-sm ml-auto hover:underline flex items-center"
                    onClick={(e) => {
                        e.preventDefault();
                        onClick(url);
                    }}
                >
                    <ExternalLink size={14} className="mr-1" />
                    Open
                </a>
            </div>
        );
    }
    
    // YouTube preview
    if (isYoutube) {
        return (
            <div 
                className="border rounded-lg overflow-hidden bg-white shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                onClick={() => onClick(url)}
            >
                <div className="relative">
                    <img 
                        src={metadata.image} 
                        alt="YouTube Thumbnail" 
                        className="w-full object-cover"
                        style={{ maxHeight: '180px' }}
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="bg-red-600 bg-opacity-80 rounded-full p-3">
                            <Youtube className="w-6 h-6 text-white" />
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="flex items-start">
                        <Youtube className="w-4 h-4 mt-1 text-red-600 flex-shrink-0" />
                        <div className="ml-2">
                            <h3 className="font-medium text-sm line-clamp-1">
                                {metadata.title}
                            </h3>
                            <p className="text-xs text-gray-500 mt-1">
                                Click to play video
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    // Regular link preview
    return (
        <div 
            className="border rounded-lg overflow-hidden bg-white shadow-sm hover:shadow-md transition-shadow cursor-pointer flex"
            onClick={() => onClick(url)}
        >
            {metadata.image && (
                <div className="w-1/4 bg-gray-100 flex-shrink-0">
                    <img 
                        src={metadata.image} 
                        alt={metadata.title} 
                        className="w-full h-full object-cover"
                    />
                </div>
            )}
            <div className="p-3 flex-1">
                <div className="flex items-start">
                    <ExternalLink className="w-4 h-4 mt-1 text-blue-500 flex-shrink-0" />
                    <div className="ml-2">
                        <h3 className="font-medium text-sm line-clamp-1">
                            {metadata.title}
                        </h3>
                        <p className="text-xs text-gray-500 mt-1 line-clamp-1">
                            {metadata.description}
                        </p>
                        <div className="text-xs text-gray-400 mt-2 flex items-center">
                            <Link size={12} className="mr-1" /> 
                            {metadata.siteName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkPreview; 