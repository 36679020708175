import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const TemplateDistributionChart = ({ data = [] }) => {
    // Define chart data
    const chartData = {
        labels: data.map(item => item.name),
        datasets: [
            {
                label: 'Message Count',
                data: data.map(item => item.count),
                backgroundColor: 'rgba(59, 130, 246, 0.7)', // blue color
                borderColor: 'rgb(59, 130, 246)',
                borderWidth: 1
            }
        ]
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const value = context.raw;
                        const percentage = data[context.dataIndex]?.percentage || 0;
                        return [`Count: ${value}`, `Percentage: ${percentage.toFixed(1)}%`];
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Messages'
                },
                ticks: {
                    precision: 0
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Template Name'
                }
            }
        }
    };

    return (
        <div className="h-full w-full">
            {data.length === 0 ? (
                <div className="flex items-center justify-center h-full">
                    <p className="text-gray-500">No template data available</p>
                </div>
            ) : (
                <Bar data={chartData} options={options} />
            )}
        </div>
    );
};

export default TemplateDistributionChart; 