import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import { CSSTransition } from 'react-transition-group';

const Modal = ({ isOpen, onClose, children, title, size = 'md' }) => {
  // Add logging for debugging
  useEffect(() => {
    console.log(`Modal isOpen state: ${isOpen}, size: ${size}`);
  }, [isOpen, size]);

  // Handle escape key press to close modal
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
      // Prevent body scrolling when modal is open
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      // Restore body scrolling when modal is closed
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  // Size classes
  const sizeClasses = {
    sm: 'max-w-md',
    md: 'max-w-2xl',
    lg: 'max-w-4xl',
    xl: 'max-w-6xl',
    full: 'max-w-full max-h-full m-4 rounded-none'
  };

  return (
    <div className={isOpen ? "fixed inset-0 z-50 flex items-center justify-center" : "hidden"}>
      {/* Backdrop with animation */}
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames="media-backdrop"
        unmountOnExit
      >
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm" 
          onClick={onClose}
        />
      </CSSTransition>
      
      {/* Modal Content with animation */}
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames="media-modal"
        unmountOnExit
      >
        <div className={`relative z-10 ${sizeClasses[size]} w-full ${size === 'full' ? 'h-full' : ''} bg-white rounded-lg shadow-xl`}>
          {/* Modal Header */}
          {title && (
            <div className="flex items-center justify-between p-4 border-b">
              <h3 className="text-lg font-medium">{title}</h3>
              <button
                type="button"
                className="text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={onClose}
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          )}
          
          {/* Close button for modals without a header */}
          {!title && (
            <button
              type="button"
              className="absolute top-4 right-4 z-10 p-2 rounded-full bg-white bg-opacity-70 hover:bg-opacity-100 text-gray-600 hover:text-gray-900 focus:outline-none transition-colors"
              onClick={onClose}
            >
              <X className="w-6 h-6" />
            </button>
          )}
          
          {/* Modal Body */}
          <div className={`${title ? 'p-6' : 'p-0'} ${size === 'full' ? 'h-full' : ''}`}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Modal; 