//src/components/chat/AgentChat.js
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { apiService } from '../../services/api';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import MessageList from './MessageList';
import Loading from '../shared/Loading';
import ConversationItem from './ConversationItem';
import { useSocket } from '../../hooks/useSocket';
import UploadProgressBar from './UploadProgressBar';
import Header from '../shared/Header';
import Card from '../ui/Card';
import Button from '../ui/Button';
import { Search, Filter, X, Phone, Video, MoreVertical, RefreshCw, MessageSquare } from 'lucide-react';



const AgentChat = () => {
    const { user } = useAuth();
    const socket = useSocket();
    const [conversations, setConversations] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [goToBottom, setGoToBottom] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const loadingRef = useRef(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingFile, setUploadingFile] = useState(null);

    // Load initial conversations
    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                setInitialLoading(true);
                const data = await apiService.getConversations();
                //console.log('Conversations:', data);
                setConversations(data);
            } catch (err) {
                setError('Failed to load conversations');
                console.error('Error loading conversations:', err);
            } finally {
                setLoading(false);
                // Set initialLoading to false after a short delay to prevent immediate loadMore trigger
                setTimeout(() => setInitialLoading(false), 100);
            }
        };
        load();
    }, []);



    useEffect(() => {
        console.log('Socket------>>>:', socket);
        if (!socket.connected) return;

        const handleNewMessage = (data) => {
            if (!data.message) return;

            setGoToBottom(true);
            if (currentChat?.phone === data.message.from?.phone) {
                setMessages(prevMessages => [...prevMessages, data.message]);

                // Mark message as read
                if (data.message.direction === 'incoming' && data.message.status !== 'read') {
                    apiService.markMessagesAsRead([data.message]);
                }
            }

            // Update conversations list, handling both existing and new conversations
            setConversations(prevConversations => {
                const existingChatIndex = prevConversations.findIndex(
                    chat => chat.phone === data.message.from?.phone
                );

                if (existingChatIndex !== -1) {
                    // Update existing conversation
                    const updatedConversations = [...prevConversations];
                    const chat = updatedConversations[existingChatIndex];

                    updatedConversations[existingChatIndex] = {
                        ...chat,
                        lastMessage: data.message.content?.text || data.message.content?.caption || "Media",
                        type: data.message.type,
                        lastMessageTime: data.message.timestamp,
                        unreadCount: chat.phone !== currentChat?.phone
                            ? (chat.unreadCount || 0) + 1
                            : chat.unreadCount
                    };

                    // Move updated conversation to top
                    const [updatedChat] = updatedConversations.splice(existingChatIndex, 1);
                    return [updatedChat, ...updatedConversations];
                } else {
                    // Add new conversation at the top
                    const newConversation = {
                        phone: data.message.from?.phone,
                        name: data.message.from?.name || data.message.from?.phone,
                        lastMessage: data.message.content?.text || data.message.content?.caption || "Media",
                        type: data.message.type,
                        lastMessageTime: data.message.timestamp,
                        unreadCount: 1
                    };
                    return [newConversation, ...prevConversations];
                }
            });
        };

        const handleAgentMessage = (data) => {
            if (data.direction === "outgoing" && currentChat?.phone === data.to?.phone) {
                setGoToBottom(true);
                setMessages(prevMessages => [...prevMessages, data]);
            }
        };

        const handleStatusUpdate = (data) => {
            if (!data.messageId || !data.status) return;

            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.messageId === data.messageId
                        ? { ...msg, status: data.status }
                        : msg
                )
            );
        };

        console.log('Adding socket listeners');

        socket.onMessage(handleNewMessage);
        socket.onAgentMessageSent(handleAgentMessage);
        socket.onStatusUpdate(handleStatusUpdate); 


        // Cleanup
        return () => {
            socket.off('whatsapp_message_received', handleNewMessage);
            socket.off('agent_message_sent', handleAgentMessage);
            socket.off('message_status_update', handleStatusUpdate);
        };
    }, [socket.connected, currentChat]);







    const handleChatSelect = async (chat) => {
        try {
            // Reset states
            setCurrentChat(chat);
            setMessages([]);
            setCurrentPage(1);
            setHasMore(true);
            setGoToBottom(true);
            setInitialLoading(true);

            const data = await apiService.getChatHistory(chat.phone, 1, 50);
            console.log('Chat History:', data);
            setMessages(data.messages);
            setHasMore(data.pagination.hasMore);

            // Mark messages as read
            const unreadMessages = data.messages.filter(
                msg => msg.direction === 'incoming' && msg.status !== 'read'
            );
            if (unreadMessages.length > 0) {
                await apiService.markMessagesAsRead(unreadMessages);
                // Update conversation unread count
                setConversations(prevConversations =>
                    prevConversations.map(conv =>
                        conv.phone === chat.phone
                            ? { ...conv, unreadCount: 0 }
                            : conv
                    )
                );
            }
        } catch (err) {
            setError('Failed to load chat history');
            console.error('Error loading chat history:', err);
        } finally {
            setTimeout(() => setInitialLoading(false), 100);
        }
    };



    const loadMoreMessages = async () => {
        if (!currentChat || loadingRef.current || !hasMore || initialLoading) return;

        try {
            loadingRef.current = true;
            setLoadingMore(true);
            setGoToBottom(false);

            const nextPage = currentPage + 1;
            const data = await apiService.getChatHistory(currentChat.phone, nextPage, 50);

            // Add new messages to the beginning of the list
            setMessages(prevMessages => {
                const newMessages = [...data.messages, ...prevMessages];
                return newMessages.filter((message, index, self) =>
                    index === self.findIndex(m => m.messageId === message.messageId)
                );
            });

            setCurrentPage(nextPage);
            setHasMore(data.pagination.hasMore);

        } catch (err) {
            setError('Failed to load more messages');
            console.error('Error loading more messages:', err);
        } finally {
            // Add a small delay before allowing next load
            setTimeout(() => {
                loadingRef.current = false;
                setLoadingMore(false);
            }, 1000);
        }
    };


    const handleSendMessage = async (message) => {
        if (!currentChat || !message.trim()) return;

        try {
            await apiService.sendMessage(currentChat.phone, message, currentChat.name);
            setGoToBottom(true);
        } catch (err) {
            setError('Failed to send message');
            console.error('Error sending message:', err);
        }
    };

    const handleSendMedia = async (file, caption) => {
        if (!currentChat || !file) return;

        // Add file size validation
        const MAX_FILE_SIZE = 16 * 1024 * 1024; // 16MB
        if (file.size > MAX_FILE_SIZE) {
            setError('File size exceeds 16MB limit');
            return;
        }

        try {
            setUploadingFile(file);
            setUploadProgress(0);

            const xhr = new XMLHttpRequest();

            await new Promise((resolve, reject) => {
                // Add timeout handler
                xhr.timeout = 30000; // 30 seconds timeout
                xhr.ontimeout = () => reject(new Error('Upload timed out'));

                xhr.upload.addEventListener('progress', (event) => {
                    if (event.lengthComputable) {
                        const progress = (event.loaded / event.total) * 100;
                        setUploadProgress(Math.min(99, progress)); // Cap at 99% until fully complete
                    }
                });

                xhr.addEventListener('load', () => {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        setUploadProgress(100); // Set to 100% on success
                        resolve(xhr.response);
                    } else {
                        reject(new Error(`Upload failed: ${xhr.statusText}`));
                    }
                });

                xhr.addEventListener('error', () => {
                    reject(new Error('Network error occurred during upload'));
                });

                xhr.addEventListener('abort', () => {
                    reject(new Error('Upload was cancelled'));
                });

                const formData = new FormData();
                formData.append('file', file);
                formData.append('recipientPhone', currentChat.phone);
                formData.append('recipientName', currentChat.name);
                formData.append('type', apiService.getFileType(file));
                if (caption) formData.append('caption', caption);

                const token = localStorage.getItem('token');
                if (!token) {
                    reject(new Error('Authentication token not found'));
                    return;
                }

                xhr.open('POST', `${process.env.REACT_APP_API_URL}/api/agent/media`);
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                xhr.responseType = 'json';
                xhr.send(formData);
            });

            setGoToBottom(true);
        } catch (err) {
            setError(err.message || 'Failed to send media');
            console.error('Error sending media:', err);
        } finally {
            // Delay cleanup to show completed state
            setTimeout(() => {
                setUploadingFile(null);
                setUploadProgress(0);
            }, 1500); // Increased delay to show completion
        }
    };

    return (
        /* this div height should be caluclated screen height - 100px   */
        <div className="flex h-screen bg-gray-100" style={{ height: `calc(100vh - 75px)` }}>
            {/* Conversations List */}
            <div className="w-80 bg-white border-r flex flex-col">
                <div className="p-4 border-b">
                    <h1 className="text-xl font-semibold text-gray-800">Conversations</h1>
                </div>
                <div className="flex-1 overflow-y-auto">
                    {conversations.map(chat => (
                        <ConversationItem
                            key={chat.phone}
                            conversation={chat}
                            isActive={currentChat?.phone === chat.phone}
                            onClick={() => handleChatSelect(chat)}
                        />
                    ))}
                </div>
            </div>

            {/* Chat Area */}
            <div className="flex-1 flex flex-col">
                {currentChat ? (
                    <>
                        <ChatHeader currentChat={currentChat} />
                        <MessageList
                            messages={messages}
                            loading={loadingMore}
                            hasMore={hasMore}
                            onLoadMore={loadMoreMessages}
                            goToBottom={goToBottom}
                            initialLoading={initialLoading}
                        />
                        <ChatInput
                            onSendMessage={handleSendMessage}
                            onSendMedia={handleSendMedia}
                            disabled={!currentChat}
                        />
                    </>
                ) : (
                    <div className="flex-1 flex items-center justify-center bg-gray-50">
                        <div className="text-center text-gray-500">
                            Select a conversation to start messaging
                        </div>
                    </div>
                )}
            </div>
            {/* Upload Progress Bar */}
            {uploadingFile && (
                <UploadProgressBar
                    progress={uploadProgress}
                    fileName={uploadingFile.name}
                />
            )}
            {/* Error Toast */}
            {error && (
                <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg">
                    {error}
                    <button
                        onClick={() => setError(null)}
                        className="ml-4 text-red-700 hover:text-red-900"
                    >
                        ×
                    </button>
                </div>
            )}
        </div>
    );
};

export default AgentChat;