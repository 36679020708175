import React, { useState, useRef, useEffect } from 'react';
import { Search, Settings, User, LogOut } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import NotificationBell from '../notifications/NotificationBell';

const Header = ({ 
    title, 
    subtitle,
    showSearch = true,
    onSearch,
    actions
}) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [showUserMenu, setShowUserMenu] = useState(false);
    const userMenuRef = useRef(null);
    
    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
                setShowUserMenu(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    
    return (
        <header className="bg-white border-b border-gray-100 py-4 px-6">
            <div className="flex justify-between items-center">
                {/* Page Title */}
                <div>
                    <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
                    {subtitle && <p className="text-sm text-gray-500 mt-1">{subtitle}</p>}
                </div>
                
                {/* Right Side Actions */}
                <div className="flex items-center space-x-4">
                    {/* Search */}
                    {showSearch && (
                        <div className="relative">
                            <input 
                                type="text" 
                                placeholder="Search..." 
                                className="pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm"
                                onChange={(e) => onSearch && onSearch(e.target.value)}
                            />
                            <Search size={18} className="absolute left-3 top-2.5 text-gray-400" />
                        </div>
                    )}
                    
                    {/* Custom Actions */}
                    {actions && actions}
                    
                    {/* Notifications */}
                    <NotificationBell />
                    
                    {/* User Menu */}
                    <div className="relative" ref={userMenuRef}>
                        <button 
                            onClick={() => setShowUserMenu(!showUserMenu)}
                            className="h-10 w-10 rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center text-white font-medium hover:opacity-90 transition-opacity"
                        >
                            {user?.username?.[0]?.toUpperCase() || 'U'}
                        </button>
                        
                        {/* Dropdown Menu */}
                        {showUserMenu && (
                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50 border border-gray-100">
                                <div className="px-4 py-2 border-b border-gray-100">
                                    <p className="text-sm font-medium text-gray-900">{user?.username || 'User'}</p>
                                    <p className="text-xs text-gray-500">{user?.role || 'Role'}</p>
                                </div>
                                
                                <button 
                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                    onClick={() => {
                                        setShowUserMenu(false);
                                        navigate('/profile');
                                    }}
                                >
                                    <User size={16} className="mr-2" />
                                    Profile
                                </button>
                                
                                <button 
                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                    onClick={handleLogout}
                                >
                                    <LogOut size={16} className="mr-2" />
                                    Log out
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header; 