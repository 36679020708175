// src/components/layouts/AdminLayout.js
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
    BarChart2,
    MessageSquare,
    Users,
    Settings,
    ChevronLeft,
    ChevronRight,
    LogOut,
    Menu,
    CircleGauge,
    Laugh,
    FileText,
    Share2,
    Activity,
    Bell
} from 'lucide-react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '../ui/tooltip';
import Header from '../shared/Header';

const AdminLayout = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { user, logout } = useAuth();
    const location = useLocation();

    useEffect(() => {
        // Get saved state from localStorage or default to true (collapsed)
        const savedState = localStorage.getItem('sidebarCollapsed');
        setIsCollapsed(savedState === null ? true : JSON.parse(savedState));
    }, []);

    // Update localStorage when state changes (optional)
    const toggleSidebar = () => {
        const newState = !isCollapsed;
        setIsCollapsed(newState);
        localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));
    };

    const navigation = [
        {
            name: 'Dashboard',
            icon: BarChart2,
            href: '/admin/dashboard/overview',
            matchPaths: ['/admin/dashboard/overview'],
            description: 'View analytics and reports',
            requiredRole: 'admin',
            category: 'Dashboard'
        },
        //Engagement Dashboard
        {
            name: 'Engagement',
            icon: CircleGauge,
            href: '/admin/dashboard/metrics',
            matchPaths: ['/admin/dashboard/metrics'],
            description: 'View engagement metrics',
            requiredRole: 'admin',
            category: 'Dashboard'
        },
        //Sentiment Dashboard
        {
            name: 'Sentiment',
            icon: Laugh,
            href: '/admin/dashboard/sentiment',
            matchPaths: ['/admin/dashboard/sentiment'],
            description: 'View sentiment analysis',
            requiredRole: 'admin',
            category: 'Dashboard'
        },
        {
            name: 'Templates',
            icon: BarChart2,
            href: '/dashboard/templates',
            matchPaths: ['/dashboard/templates'],
            description: 'View template message analytics',
            requiredRole: 'admin',
            category: 'Dashboard'
        },
        {
            name: 'Chat',
            icon: MessageSquare,
            href: '/chat',
            matchPaths: ['/chat'],
            description: 'Manage conversations',
            category: 'Communication'
        },
        {
            name: 'Templates',
            icon: FileText,
            href: '/templates',
            matchPaths: ['/templates', '/templates/create', '/templates/edit'],
            description: 'Manage message templates',
            requiredRole: 'admin',
            category: 'Communication'
        },
        {
            name: 'Flow Builder',
            icon: Share2,
            href: '/flows/list',
            matchPaths: ['/flows/list', '/flows/create', '/flows/edit', '/flows'],
            description: 'Design conversation flows',
            category: 'Management'
        },
        {
            name: 'Activities',
            icon: Activity,
            href: '/activities',
            matchPaths: ['/activities'],
            description: 'View system activities and notifications',
            category: 'Management'
        },
        {
            name: 'Users',
            icon: Users,
            href: '/admin/users',
            matchPaths: ['/admin/users'],
            description: 'Manage user accounts',
            requiredRole: 'admin',
            category: 'Management'
        },
        {
            name: 'Settings',
            icon: Settings,
            href: '/admin/settings',
            matchPaths: ['/admin/settings'],
            description: 'Configure system settings',
            requiredRole: 'admin',
            category: 'Management'
        },
    ];

    const isActivePath = (matchPaths) => {
        return matchPaths.some(path => location.pathname.startsWith(path));
    };

    const NavItem = ({ item }) => {
        const Icon = item.icon;
        const isActive = isActivePath(item.matchPaths);

        return (
            <TooltipProvider delayDuration={300}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <NavLink
                            to={item.href}
                            className={`
                                flex items-center px-3 py-2.5 rounded-lg
                                ${isActive
                                    ? 'bg-gradient-to-r from-blue-50 to-indigo-50 text-blue-600 font-medium'
                                    : 'text-gray-600 hover:bg-gray-50'
                                }
                                ${isCollapsed ? 'justify-center' : ''}
                                transition-all duration-200 group relative
                            `}
                        >
                            <div className={`
                                ${isActive ? 'bg-blue-100 text-blue-600' : 'text-gray-400 group-hover:text-gray-500'}
                                rounded-md p-1.5 flex items-center justify-center
                            `}>
                                <Icon size={20} />
                            </div>
                            {!isCollapsed && (
                                <span className="ml-3 text-sm">{item.name}</span>
                            )}
                            {!isCollapsed && isActive && (
                                <div className="w-1 h-8 bg-blue-500 rounded-l-full absolute right-0"></div>
                            )}
                        </NavLink>
                    </TooltipTrigger>
                    {isCollapsed && (
                        <TooltipContent side="right" className="flex flex-col">
                            <p className="font-semibold">{item.name}</p>
                            <p className="text-xs text-gray-500">{item.description}</p>
                        </TooltipContent>
                    )}
                </Tooltip>
            </TooltipProvider>
        );
    };

    // Group navigation by category
    const categories = [...new Set(navigation.map(item => item.category))];

    return (
        <div className="flex h-screen bg-gray-50">
            {/* Sidebar */}
            <div
                className={`${isCollapsed ? 'w-20' : 'w-72'} 
                    bg-white shadow-lg transition-all duration-300 ease-in-out relative
                    border-r border-gray-100 flex flex-col h-screen`}
            >
                {/* Toggle Button */}
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                onClick={toggleSidebar}
                                className="absolute -right-3 top-8 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 z-10"
                            >
                                {isCollapsed ? (
                                    <ChevronRight size={20} />
                                ) : (
                                    <ChevronLeft size={20} />
                                )}
                            </button>
                        </TooltipTrigger>
                        <TooltipContent side="right">
                            {isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>

                {/* Sidebar Content */}
                <div className="flex flex-col h-full">
                    {/* Logo Area */}
                    <div className={`
                        flex items-center h-20 border-b border-gray-100
                        ${isCollapsed ? 'justify-center px-2' : 'px-6'}
                    `}>
                        <div className="flex items-center">
                            <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-xl p-2 shadow-md">
                                <MessageSquare size={isCollapsed ? 24 : 28} />
                            </div>
                            {!isCollapsed && (
                                <span className="ml-3 font-bold text-xl text-gray-800">
                                    WhatsApp Agent
                                </span>
                            )}
                        </div>
                    </div>

                    {/* Navigation with Categories */}
                    <div className="flex-1 overflow-y-auto py-6">
                        {categories.map(category => {
                            const categoryItems = navigation.filter(item => 
                                item.category === category && 
                                (!item.requiredRole || item.requiredRole === user.role)
                            );
                            
                            if (categoryItems.length === 0) {
                                return null;
                            }
                            
                            return (
                                <div key={category} className={`px-${isCollapsed ? '3' : '6'} mb-6`}>
                                    {!isCollapsed && (
                                        <p className="text-xs font-semibold text-gray-500 uppercase tracking-wider mb-2">
                                            {category}
                                        </p>
                                    )}
                                    <nav className="space-y-1">
                                        {categoryItems.map(item => (
                                            <NavItem key={item.name} item={item} />
                                        ))}
                                    </nav>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col overflow-hidden">
                {/* Global header with notifications */}
                <Header 
                    title={getPageTitle(location.pathname)}
                    showSearch={false}
                />
                <div className="flex-1 overflow-auto">
                    {children}
                </div>
            </div>
        </div>
    );
};

// Helper function to get page title based on current path
const getPageTitle = (path) => {
    if (path.startsWith('/admin/dashboard/overview')) return 'WhatsApp Analytics Dashboard';
    if (path.startsWith('/admin/dashboard/metrics')) return 'Engagement Metrics';
    if (path.startsWith('/admin/dashboard/sentiment')) return 'Sentiment Analysis';
    if (path.startsWith('/dashboard/templates')) return 'Template Messages';
    if (path.startsWith('/chat')) return 'Chat';
    if (path.startsWith('/templates')) return 'Templates';
    if (path.startsWith('/flows')) return 'Flow Builder';
    if (path.startsWith('/activities')) return 'Activities';
    if (path.startsWith('/admin/users')) return 'Users';
    if (path.startsWith('/admin/settings')) return 'Settings';
    return 'WhatsApp Agent';
};

export default AdminLayout;