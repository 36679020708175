// src/components/templates/TemplateForm.js
import React, { useState,useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../services/api';
import {
    X,
    Plus,
    Image,
    Video,
    FileText,
    MapPin,
    Type,
    Phone,
    Link,
    MessageSquare,
    Info
} from 'lucide-react';

const ComponentTypeIcons = {
    TEXT: Type,
    IMAGE: Image,
    VIDEO: Video,
    DOCUMENT: FileText,
    LOCATION: MapPin
};

const ButtonTypeIcons = {
    URL: Link,
    PHONE_NUMBER: Phone,
    QUICK_REPLY: MessageSquare
};

const TemplateForm = ({ template, onSubmit, onCancel }) => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(template || {
        name: '',
        category: 'MARKETING',
        language: 'en_US',
        parameter_format: 'POSITIONAL',
        allow_category_change: true,
        components: [
            {
                type: 'BODY',
                text: '',
                example: {
                    body_text: [[]]
                }
            }
        ]
    });

    const [errors, setErrors] = useState({});
    const [previewMode, setPreviewMode] = useState(false);

    useEffect(() => {
        if (id) {
            loadTemplate(id);
        }
    }, [id]);

    const loadTemplate = async (id) => {
        try {
            setLoading(true);
            const response = await apiService.getTemplateById(id);
            if (response.data?.data) {
                const templateData = response.data.data;
                // Store the original category for comparison during editing
                templateData.originalCategory = templateData.category;
                setFormData(templateData);
            }
        } catch (error) {
            console.error('Error loading template:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        
        const formatComponents = (components) => {
            // Sort components in the correct order: HEADER -> BODY -> FOOTER -> BUTTONS
            const componentOrder = { HEADER: 1, BODY: 2, FOOTER: 3, BUTTONS: 4 };
            
            // First sort the components
            const sortedComponents = [...components].sort((a, b) => 
                componentOrder[a.type] - componentOrder[b.type]
            );

            return sortedComponents.map(component => {
                switch (component.type) {
                    case 'HEADER':
                        const headerComponent = {
                            type: 'HEADER',
                            format: component.format || 'TEXT'
                        };

                        // Add text for TEXT format headers - always include text field for TEXT format
                        if (component.format === 'TEXT') {
                            // Text field is required for TEXT format headers, even if empty
                            headerComponent.text = component.text || '';
                            
                            // Always include example for TEXT headers
                            headerComponent.example = {
                                header_text: [component.text || '']
                            };
                        } else if (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(component.format) && 
                                   component.example?.header_handle?.[0]?.url) {
                            // For media headers, include the media example
                            headerComponent.example = {
                                header_handle: [{
                                    type: component.format.toLowerCase(),
                                    url: component.example.header_handle[0].url
                                }]
                            };
                        }

                        return headerComponent;

                    case 'BODY':
                        const bodyText = component.text || '';
                        // Count variables in body text ({{1}}, {{2}}, etc.)
                        const varMatches = bodyText.match(/\{\{(\d+)\}\}/g) || [];
                        const varCount = new Set(varMatches.map(v => v.match(/\d+/)[0])).size;
                        
                        const bodyComponent = {
                            type: 'BODY',
                            text: bodyText
                        };

                        // Include examples if variables exist
                        if (varCount > 0) {
                            const examples = component.example?.body_text?.[0] || [];
                            // Ensure we have examples for all variables
                            bodyComponent.example = {
                                body_text: [examples.slice(0, varCount)]
                            };
                        }

                        return bodyComponent;

                    case 'FOOTER':
                        return {
                            type: 'FOOTER',
                            text: component.text || ''
                        };

                    case 'BUTTONS':
                        return {
                            type: 'BUTTONS',
                            buttons: (component.buttons || []).map(button => {
                                const basicButton = {
                                    type: button.type,
                                    text: button.text || ''
                                };

                                if (button.type === 'URL' && button.url) {
                                    return { ...basicButton, url: button.url };
                                }
                                if (button.type === 'PHONE_NUMBER' && button.phoneNumber) {
                                    return { ...basicButton, phone_number: button.phoneNumber };
                                }
                                return basicButton; // QUICK_REPLY type
                            })
                        };

                    default:
                        return component;
                }
            });
        };

        const createApiData = (formData) => {
            const apiData = {
                name: formData.name.toLowerCase().replace(/\s+/g, '_'),
                language: formData.language,
                category: formData.category,
                components: formatComponents(formData.components)
            };

            // Add optional parameters
            if (formData.parameter_format) {
                apiData.parameter_format = formData.parameter_format;
            }

            if (typeof formData.allow_category_change === 'boolean') {
                apiData.allow_category_change = formData.allow_category_change;
            }

            return apiData;
        };

       

        if (id) {
            // For updates, only send category and components
            // According to WhatsApp API requirements, we can only edit category or components
            // and the template must be in APPROVED, REJECTED, or PAUSED status
            let updateData = {};
            
            // We need to check if the template's status allows editing
            const allowedStatuses = ['APPROVED', 'REJECTED', 'PAUSED'];
            if (!allowedStatuses.includes(formData.status)) {
                setErrors({
                    ...errors,
                    form: 'Only templates with APPROVED, REJECTED, or PAUSED status can be edited'
                });
                return;
            }
            
            // Cannot edit category of approved templates
            if (formData.status === 'APPROVED' && formData.category !== formData.originalCategory) {
                setErrors({
                    ...errors,
                    category: 'Cannot change the category of an approved template'
                });
                return;
            }
            
            // Determine what's being updated - category, components, or both
            if (formData.category !== formData.originalCategory) {
                updateData.category = formData.category;
            }
            
            // Always include components when editing
            updateData.components = formatComponents(formData.components);
            
            // Ensure category is always included even if not changed
            if (!updateData.category) {
                updateData.category = formData.category;
            }
            
            console.log("Template update payload:", JSON.stringify(updateData));
            
            try {
                await apiService.updateTemplate(id, updateData);
                navigate('/templates');
            } catch (error) {
                console.error('Error updating template:', error);
                console.error('Error details:', error.response?.data || error.message);
                setErrors({
                    ...errors,
                    form: 'Failed to update template. Please check console for details.'
                });
            }
        } else {
            // For creation, send the complete template data
            try {
                const createData = createApiData(formData);
                console.log("Template create payload:", JSON.stringify(createData));
                await apiService.createTemplate(createData);
                navigate('/templates');
            } catch (error) {
                console.error('Error creating template:', error);
                console.error('Error details:', error.response?.data || error.message);
                setErrors({
                    ...errors,
                    form: 'Failed to create template. Please check console for details.'
                });
            }
        }
    };

    const handleCancel = () => {
        navigate('/templates');
    };


    const validateForm = () => {
        const newErrors = {};

        // Validate template name - WhatsApp requires lowercase, numbers, and underscores
        if (!formData.name) {
            newErrors.name = 'Template name is required';
        } else if (!/^[a-z0-9_]+$/.test(formData.name)) {
            newErrors.name = 'Template name must contain only lowercase letters, numbers, and underscores';
        }

        // Validate components exist
        if (!formData.components || !formData.components.length) {
            newErrors.components = 'At least one component is required';
        } else {
            // Check if body component exists (required by WhatsApp)
            const hasBodyComponent = formData.components.some(comp => comp.type === 'BODY');
            if (!hasBodyComponent) {
                newErrors.components = 'A BODY component is required for all templates';
            }
        }

        // Validate component lengths and content
        formData.components.forEach((component, index) => {
            // HEADER validation
            if (component.type === 'HEADER') {
                if (component.format === 'TEXT') {
                    if (!component.text || component.text.trim() === '') {
                        newErrors[`component_${index}`] = 'Header text is required for TEXT format';
                    } else if (component.text.length > 60) {
                        newErrors[`component_${index}`] = 'Header text must not exceed 60 characters';
                    }
                    
                    // Automatically add empty text if missing to prevent API errors
                    if (!component.text) {
                        const newComponents = [...formData.components];
                        newComponents[index] = { ...newComponents[index], text: '' };
                        setFormData({
                            ...formData,
                            components: newComponents
                        });
                    }
                } else if (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(component.format)) {
                    if (!component.example?.header_handle?.[0]?.url) {
                        newErrors[`component_${index}`] = `Example URL is required for ${component.format} format`;
                    }
                }
            }
            
            // BODY validation
            if (component.type === 'BODY') {
                if (!component.text || component.text.trim() === '') {
                    newErrors[`component_${index}`] = 'Body text is required';
                } else if (component.text.length > 1024) {
                    newErrors[`component_${index}`] = 'Body text must not exceed 1024 characters';
                }
                
                // Validate variables and examples
                const varMatches = component.text.match(/\{\{(\d+)\}\}/g) || [];
                const uniqueVars = new Set(varMatches.map(v => v.match(/\d+/)[0]));
                
                if (uniqueVars.size > 0) {
                    const examples = component.example?.body_text?.[0] || [];
                    
                    // Check if we have examples for all variables
                    if (examples.length < uniqueVars.size) {
                        newErrors[`component_${index}_examples`] = `Examples are required for all ${uniqueVars.size} variables`;
                    } else {
                        // Check if any example is empty
                        const hasEmptyExample = examples.some((ex, i) => i < uniqueVars.size && (!ex || ex.trim() === ''));
                        if (hasEmptyExample) {
                            newErrors[`component_${index}_examples`] = 'All variable examples must have values';
                        }
                    }
                }
            }
            
            // FOOTER validation
            if (component.type === 'FOOTER') {
                if (component.text && component.text.length > 60) {
                    newErrors[`component_${index}`] = 'Footer text must not exceed 60 characters';
                }
            }
        });

        // Validate buttons
        const buttonsComponent = formData.components.find(c => c.type === 'BUTTONS');
        if (buttonsComponent) {
            const buttons = buttonsComponent.buttons || [];
            
            if (buttons.length > 3) {
                newErrors.buttons = 'Maximum 3 buttons allowed per template';
            }
            
            // Validate individual buttons
            buttons.forEach((button, buttonIndex) => {
                if (!button.text || button.text.trim() === '') {
                    newErrors[`button_${buttonIndex}`] = 'Button text is required';
                }
                
                if (button.type === 'URL' && (!button.url || button.url.trim() === '')) {
                    newErrors[`button_${buttonIndex}_url`] = 'URL is required for URL button type';
                }
                
                if (button.type === 'PHONE_NUMBER' && (!button.phoneNumber || button.phoneNumber.trim() === '')) {
                    newErrors[`button_${buttonIndex}_phone`] = 'Phone number is required for PHONE_NUMBER button type';
                }
            });
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    const addComponent = (type) => {
        let newComponent = {
            type,
            text: '',
        };

        switch (type) {
            case 'HEADER':
                newComponent.format = 'TEXT';
                // Ensure text field is included for TEXT headers (required by WhatsApp API)
                newComponent.text = '';
                newComponent.example = {
                    header_text: [''] // Use empty array with empty string for text headers
                };
                break;
            case 'BODY':
                newComponent.example = { body_text: [[]] };
                break;
            case 'BUTTONS':
                newComponent.buttons = [];
                break;
            default:
                break;
        }

        setFormData({
            ...formData,
            components: [...formData.components, newComponent]
        });
    };

    const removeComponent = (index) => {
        const newComponents = formData.components.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            components: newComponents
        });
    };

    const updateComponent = (index, updates) => {
        const newComponents = [...formData.components];
        newComponents[index] = { ...newComponents[index], ...updates };
        setFormData({
            ...formData,
            components: newComponents
        });
    };

    const addButton = (componentIndex) => {
        const newComponents = [...formData.components];
        const component = newComponents[componentIndex];

        if (!component.buttons) {
            component.buttons = [];
        }

        if (component.buttons.length < 3) {
            component.buttons.push({
                type: 'QUICK_REPLY',
                text: ''
            });
            setFormData({ ...formData, components: newComponents });
        }
    };

    const updateButton = (componentIndex, buttonIndex, updates) => {
        const newComponents = [...formData.components];
        const component = newComponents[componentIndex];
        component.buttons[buttonIndex] = { ...component.buttons[buttonIndex], ...updates };
        setFormData({ ...formData, components: newComponents });
    };

    const removeButton = (componentIndex, buttonIndex) => {
        const newComponents = [...formData.components];
        const component = newComponents[componentIndex];
        component.buttons = component.buttons.filter((_, i) => i !== buttonIndex);
        setFormData({ ...formData, components: newComponents });
    };

    const togglePreview = () => {
        setPreviewMode(!previewMode);
    };

    const renderPreview = () => {
        // Preview component rendering WhatsApp-like template
        return (
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm mx-auto">
                <div className="border border-gray-200 rounded-lg overflow-hidden">
                    {/* WhatsApp-style interface */}
                    <div className="bg-green-100 p-3 flex items-center border-b border-gray-200">
                        <div className="w-10 h-10 bg-green-600 rounded-full flex items-center justify-center">
                            <span className="text-white font-bold">WA</span>
                        </div>
                        <div className="ml-3">
                            <div className="text-sm font-medium text-gray-900">WhatsApp Business</div>
                            <div className="text-xs text-gray-500">Template Preview</div>
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 p-4 space-y-4">
                        {/* Template content */}
                        <div className="bg-white rounded-lg shadow p-3 max-w-[80%] ml-auto space-y-2">
                            {/* Header */}
                            {formData.components.find(c => c.type === 'HEADER') && (
                                <div className="font-bold">
                                    {(() => {
                                        const header = formData.components.find(c => c.type === 'HEADER');
                                        if (header.format === 'TEXT') {
                                            return header.text;
                                        } else if (header.format === 'IMAGE') {
                                            return (
                                                <div className="bg-gray-200 h-40 flex items-center justify-center rounded">
                                                    <span className="text-gray-600">Image Header</span>
                                                </div>
                                            );
                                        } else if (header.format === 'VIDEO') {
                                            return (
                                                <div className="bg-gray-200 h-40 flex items-center justify-center rounded">
                                                    <span className="text-gray-600">Video Header</span>
                                                </div>
                                            );
                                        } else if (header.format === 'DOCUMENT') {
                                            return (
                                                <div className="bg-gray-200 p-4 flex items-center justify-center rounded">
                                                    <span className="text-gray-600">Document Header</span>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })()}
                                </div>
                            )}
                            
                            {/* Body */}
                            {formData.components.find(c => c.type === 'BODY') && (
                                <div>
                                    {(() => {
                                        const body = formData.components.find(c => c.type === 'BODY');
                                        let bodyText = body.text || '';
                                        
                                        // Replace variables with examples if available
                                        const examples = body.example?.body_text?.[0] || [];
                                        bodyText = bodyText.replace(/\{\{(\d+)\}\}/g, (match, index) => {
                                            const exampleIndex = parseInt(index) - 1;
                                            return examples[exampleIndex] || match;
                                        });
                                        
                                        return bodyText.split('\n').map((line, i) => (
                                            <p key={i} className="mb-1">{line}</p>
                                        ));
                                    })()}
                                </div>
                            )}
                            
                            {/* Footer */}
                            {formData.components.find(c => c.type === 'FOOTER') && (
                                <div className="text-xs text-gray-500 border-t border-gray-100 pt-2">
                                    {formData.components.find(c => c.type === 'FOOTER').text}
                                </div>
                            )}
                        </div>
                        
                        {/* Buttons */}
                        {formData.components.find(c => c.type === 'BUTTONS') && (
                            <div className="bg-white rounded-lg shadow p-2 max-w-[80%] ml-auto">
                                {formData.components.find(c => c.type === 'BUTTONS').buttons.map((button, index) => (
                                    <div 
                                        key={index} 
                                        className={`py-2 px-3 ${index > 0 ? 'border-t border-gray-100' : ''}`}
                                    >
                                        <div className="flex items-center">
                                            {button.type === 'URL' && (
                                                <Link className="h-4 w-4 text-blue-500 mr-2" />
                                            )}
                                            {button.type === 'PHONE_NUMBER' && (
                                                <Phone className="h-4 w-4 text-blue-500 mr-2" />
                                            )}
                                            {button.type === 'QUICK_REPLY' && (
                                                <MessageSquare className="h-4 w-4 text-blue-500 mr-2" />
                                            )}
                                            <span className="text-blue-500 text-sm font-medium">
                                                {button.text}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                
                <div className="mt-4">
                    <button
                        type="button"
                        onClick={togglePreview}
                        className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Back to Edit
                    </button>
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="h-screen flex flex-col overflow-hidden">
            {/* Scrollable content area */}
            <div className="flex-1 overflow-y-auto pb-24">
                {previewMode ? (
                    renderPreview()
                ) : (
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
                        <form className="space-y-8">
                            {/* Form error messages */}
                            {errors.form && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                    <span className="block sm:inline">{errors.form}</span>
                                </div>
                            )}
                            
                            {/* Basic Information */}
                            <div className="space-y-6">
                                <h2 className="text-xl font-semibold text-gray-900">Template Information</h2>

                                {id && (
                                    <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <Info className="h-5 w-5 text-blue-500" />
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-sm text-blue-700">
                                                    <strong>WhatsApp Template Editing Guidelines:</strong>
                                                </p>
                                                <ul className="list-disc pl-5 text-xs text-blue-700 mt-1">
                                                    <li>Only templates with "APPROVED", "REJECTED", or "PAUSED" status can be edited</li>
                                                    <li>You cannot edit the category of an approved template</li>
                                                    <li>Approved templates can be edited up to 10 times in a 30-day window or once in a 24-hour window</li>
                                                    <li>Rejected or paused templates can be edited without limits</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                                    {/* Template Name */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Template Name
                                        </label>
                                        <input
                                            type="text"
                                            value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            className={`mt-1 block w-full rounded-md shadow-sm 
                                                ${errors.name ? 'border-red-300 focus:border-red-500 focus:ring-red-500' :
                                                    'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}`}
                                            placeholder="my_template_name"
                                        />
                                        {errors.name && (
                                            <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                                        )}
                                        <p className="mt-1 text-xs text-gray-500">
                                            Use lowercase letters, numbers, and underscores only
                                        </p>
                                    </div>

                                    {/* Category Selection */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Category
                                        </label>
                                        <select
                                            value={formData.category}
                                            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                                            className={`mt-1 block w-full rounded-md shadow-sm 
                                                ${errors.category ? 'border-red-300 focus:border-red-500 focus:ring-red-500' :
                                                    'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}`}
                                        >
                                            <option value="MARKETING">Marketing</option>
                                            <option value="UTILITY">Utility</option>
                                            <option value="AUTHENTICATION">Authentication</option>
                                        </select>
                                        {errors.category && (
                                            <p className="mt-1 text-sm text-red-600">{errors.category}</p>
                                        )}
                                    </div>

                                    {/* Parameter Format */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Parameter Format
                                        </label>
                                        <select
                                            value={formData.parameter_format}
                                            onChange={(e) => setFormData({ ...formData, parameter_format: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        >
                                            <option value="POSITIONAL">Positional</option>
                                            <option value="NAMED">Named</option>
                                        </select>
                                        <p className="mt-1 text-xs text-gray-500">
                                            Positional: &#123;&#123;1&#125;&#125;, &#123;&#123;2&#125;&#125;... Named: &#123;&#123;name&#125;&#125;, &#123;&#123;date&#125;&#125;...
                                        </p>
                                    </div>

                                    {/* Allow Category Change */}
                                    {!id && (
                                        <div className="flex items-center pt-5">
                                            <input
                                                id="allow_category_change"
                                                type="checkbox"
                                                checked={formData.allow_category_change}
                                                onChange={(e) => setFormData({ ...formData, allow_category_change: e.target.checked })}
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="allow_category_change" className="ml-2 block text-sm text-gray-700">
                                                Allow automatic category assignment
                                            </label>
                                            <p className="mt-1 text-xs text-gray-500 ml-6">
                                                If enabled, WhatsApp may change the category if needed
                                            </p>
                                        </div>
                                    )}

                                    {/* Language Selection */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Language
                                        </label>
                                        <select
                                            value={formData.language}
                                            onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        >
                                            <option value="en">English</option>
                                            <option value="hi">Hindi</option>
                                            <option value="ml">Malayalam</option>
                                            <option value="ta">Tamil</option>
                                            <option value="te">Telugu</option>
                                            <option value="bn">Bengali</option>
                                            <option value="gu">Gujarati</option>
                                            <option value="kn">Kannada</option>
                                            <option value="mr">Marathi</option>
                                            <option value="pa">Punjabi</option>
                                            <option value="ur">Urdu</option>
                                            <option value="as">Assamese</option>
                                            <option value="or">Odia</option>
                                            <option value="ne">Nepali</option>
                                            <option value="sd">Sindhi</option>
                                            <option value="ks">Kashmiri</option>
                                            <option value="doi">Dogri</option>
                                            <option value="ar">Arabic</option>
                                            <option value="de">German</option>
                                            <option value="fr">French</option>
                                            <option value="es">Spanish</option>
                                            <option value="pt">Portuguese</option>
                                            <option value="it">Italian</option>
                                            <option value="nl">Dutch</option>
                                            <option value="sv">Swedish</option>
                                            <option value="no">Norwegian</option>
                                            <option value="da">Danish</option>
                                            <option value="fi">Finnish</option>
                                            <option value="el">Greek</option>
                                            <option value="tr">Turkish</option>
                                            <option value="ru">Russian</option>
                                            <option value="he">Hebrew</option>
                                            <option value="ar">Arabic</option>
                                            <option value="th">Thai</option>
                                            <option value="id">Indonesian</option>
                                            <option value="ja">Japanese</option>
                                            <option value="ko">Korean</option>
                                            <option value="zh">Chinese</option>
                                            <option value="my">Burmese</option>
                                            <option value="vi">Vietnamese</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Components Section */}
                            <div className="space-y-6">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-xl font-semibold text-gray-900">Template Components</h2>
                                    <div className="flex gap-2">
                                        <button
                                            type="button"
                                            onClick={() => addComponent('HEADER')}
                                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Add Header
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => addComponent('BODY')}
                                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Add Body
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => addComponent('FOOTER')}
                                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Add Footer
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => addComponent('BUTTONS')}
                                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Add Buttons
                                        </button>
                                    </div>
                                </div>
                                
                                {/* Component List */}
                                <div className="space-y-4">
                                    {formData.components.map((component, index) => (
                                        <div key={index} className="bg-gray-50 border border-gray-200 rounded-lg p-4">
                                            {/* Component Header */}
                                            <div className="flex justify-between items-center mb-4">
                                                <div className="flex items-center gap-2">
                                                    <span className="text-sm font-semibold text-gray-700 bg-gray-200 px-2 py-1 rounded">
                                                        {component.type}
                                                    </span>
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => removeComponent(index)}
                                                    className="text-gray-400 hover:text-red-500 transition-colors"
                                                >
                                                    <X size={18} />
                                                </button>
                                            </div>

                                            {/* Component-specific content here - keeping the existing implementation */}
                                            {component.type === 'HEADER' && (
                                                <div className="space-y-4">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            Format
                                                        </label>
                                                        <select
                                                            value={component.format || 'TEXT'}
                                                            onChange={(e) => updateComponent(index, { format: e.target.value })}
                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                        >
                                                            <option value="TEXT">Text</option>
                                                            <option value="IMAGE">Image</option>
                                                            <option value="VIDEO">Video</option>
                                                            <option value="DOCUMENT">Document</option>
                                                            <option value="LOCATION">Location</option>
                                                        </select>
                                                    </div>

                                                    {component.format === 'TEXT' && (
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                Text
                                                            </label>
                                                            <input
                                                                type="text"
                                                                value={component.text || ''}
                                                                onChange={(e) => updateComponent(index, { text: e.target.value })}
                                                                maxLength={60}
                                                                className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                            />
                                                            <p className="mt-1 text-xs text-gray-500">
                                                                Maximum 60 characters
                                                            </p>
                                                        </div>
                                                    )}

                                                    {['IMAGE', 'VIDEO', 'DOCUMENT'].includes(component.format) && (
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                Example URL
                                                            </label>
                                                            <input
                                                                type="url"
                                                                value={component.example?.header_handle?.[0]?.url || ''}
                                                                onChange={(e) => updateComponent(index, {
                                                                    example: {
                                                                        header_handle: [{
                                                                            type: component.format.toLowerCase(),
                                                                            url: e.target.value
                                                                        }]
                                                                    }
                                                                })}
                                                                className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                placeholder="https://example.com/media"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {component.type === 'BODY' && (
                                                <div className="space-y-4">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            Message Text
                                                        </label>
                                                        <textarea
                                                            value={component.text || ''}
                                                            onChange={(e) => updateComponent(index, { text: e.target.value })}
                                                            rows={4}
                                                            maxLength={1024}
                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                            placeholder="Enter your message here. Use {{1}}, {{2}}, etc. for variables"
                                                        />
                                                        <p className="mt-1 text-xs text-gray-500">
                                                        Maximum 1024 characters. Use &#123;&#123; 1&#125;&#125;, &#123;&#123; 2&#125;&#125;, etc. for variables
                                                        </p>
                                                    </div>

                                                    {/* Variable Examples */}
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            Variable Examples
                                                        </label>
                                                        <div className="space-y-2">
                                                            {(component.example?.body_text?.[0] || []).map((example, varIndex) => (
                                                                <div key={varIndex} className="flex items-center gap-2">
                                                                    <input
                                                                        type="text"
                                                                        value={example || ''}
                                                                        onChange={(e) => {
                                                                            const newExamples = [...(component.example.body_text[0] || [])];
                                                                            newExamples[varIndex] = e.target.value;
                                                                            updateComponent(index, {
                                                                                example: {
                                                                                    body_text: [newExamples]
                                                                                }
                                                                            });
                                                                        }}
                                                                        className="flex-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                        placeholder={`Example for {{${varIndex + 1}}}`}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            const newExamples = [...(component.example.body_text[0] || [])];
                                                                            newExamples.splice(varIndex, 1);
                                                                            updateComponent(index, {
                                                                                example: {
                                                                                    body_text: [newExamples]
                                                                                }
                                                                            });
                                                                        }}
                                                                        className="p-2 text-red-600 hover:text-red-800 focus:outline-none"
                                                                    >
                                                                        <X size={16} />
                                                                    </button>
                                                                </div>
                                                            ))}
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    const newExamples = [...(component.example?.body_text?.[0] || []), ''];
                                                                    updateComponent(index, {
                                                                        example: {
                                                                            body_text: [newExamples]
                                                                        }
                                                                    });
                                                                }}
                                                                className="text-sm text-blue-600 hover:text-blue-700"
                                                            >
                                                                + Add Variable Example
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {component.type === 'FOOTER' && (
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                        Footer Text
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={component.text || ''}
                                                        onChange={(e) => updateComponent(index, { text: e.target.value })}
                                                        maxLength={60}
                                                        className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                    />
                                                    <p className="mt-1 text-xs text-gray-500">
                                                        Maximum 60 characters
                                                    </p>
                                                </div>
                                            )}

                                            {component.type === 'BUTTONS' && (
                                                <div className="space-y-4">
                                                    {component.buttons?.map((button, buttonIndex) => (
                                                        <div key={buttonIndex} className="bg-white p-3 rounded border border-gray-200">
                                                            <div className="flex justify-between items-center mb-3">
                                                                <span className="text-sm font-medium text-gray-700">
                                                                    Button {buttonIndex + 1}
                                                                </span>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => removeButton(index, buttonIndex)}
                                                                    className="text-gray-400 hover:text-red-500"
                                                                >
                                                                    <X size={16} />
                                                                </button>
                                                            </div>

                                                            <div className="space-y-3">
                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                        Type
                                                                    </label>
                                                                    <select
                                                                        value={button.type}
                                                                        onChange={(e) => updateButton(index, buttonIndex, { type: e.target.value })}
                                                                        className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                    >
                                                                        <option value="QUICK_REPLY">Quick Reply</option>
                                                                        <option value="URL">URL</option>
                                                                        <option value="PHONE_NUMBER">Phone Number</option>
                                                                    </select>
                                                                </div>

                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                        Button Text
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        value={button.text || ''}
                                                                        onChange={(e) => updateButton(index, buttonIndex, { text: e.target.value })}
                                                                        className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                    />
                                                                </div>

                                                                {button.type === 'URL' && (
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                            URL
                                                                        </label>
                                                                        <input
                                                                            type="url"
                                                                            value={button.url || ''}
                                                                            onChange={(e) => updateButton(index, buttonIndex, { url: e.target.value })}
                                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                            placeholder="https://example.com"
                                                                        />
                                                                    </div>
                                                                )}

                                                                {button.type === 'PHONE_NUMBER' && (
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                            Phone Number
                                                                        </label>
                                                                        <input
                                                                            type="tel"
                                                                            value={button.phoneNumber || ''}
                                                                            onChange={(e) => updateButton(index, buttonIndex, { phoneNumber: e.target.value })}
                                                                            className="w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                                            placeholder="+1234567890"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {(!component.buttons || component.buttons.length < 3) && (
                                                        <button
                                                            type="button"
                                                            onClick={() => addButton(index)}
                                                            className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                        >
                                                            Add Button
                                                        </button>
                                                    )}
                                                </div>
                                            )}

                                            {errors[`component_${index}`] && (
                                                <p className="mt-2 text-sm text-red-600">
                                                    {errors[`component_${index}`]}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            
            {/* Fixed action buttons at the bottom */}
            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 shadow-md z-10">
                <div className="max-w-4xl mx-auto flex justify-end space-x-3">
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Cancel
                    </button>
                    
                    {!previewMode && (
                        <button
                            type="button"
                            onClick={togglePreview}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Preview Template
                        </button>
                    )}
                    
                    {!previewMode && (
                        <button
                            type="button"
                            onClick={(e) => handleSubmit(e)}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Save Template
                        </button>
                    )}
                </div>
            </div>
        </div>
    );


};

export default TemplateForm;