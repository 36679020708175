//src/components/templates/TemplateManager.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/api';
import activityLogger from '../../utils/activityLogger';
import {
    Plus,
    RefreshCcw,
    Search,
    Filter,
    Edit,
    Trash2,
    CheckCircle,
    XCircle,
    Clock,
    Send,
    BarChart2
} from 'lucide-react';

const TemplateManager = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        limit: 10,
        offset: 0
    });
    const [filters, setFilters] = useState({
        category: '',
        status: '',
        language: '',
        search: ''
    });

    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        refreshTemplates();
    }, [user, filters]);

    const refreshTemplates = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await apiService.getTemplates({
                ...filters,
                limit: pagination.limit,
                offset: pagination.offset
            });

            // Correctly access the templates array from the nested response
            if (response.data?.status === 'success' && response.data?.data?.data) {
                setTemplates(response.data.data.data);
                setPagination({
                    ...pagination,
                    // Update pagination if needed based on response.data.data.paging
                });
                
                // Log template list viewed activity
                await activityLogger.logUserAction('templates_viewed', {
                    filters,
                    count: response.data.data.data.length
                }, user.id);
            } else {
                throw new Error('Invalid response structure');
            }
        } catch (err) {
            setError('Failed to load templates');
            console.error('Template loading error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteTemplate = async (templateId) => {
        if (!window.confirm('Are you sure you want to delete this template?')) {
            return;
        }

        try {
            // Get template info before deletion for logging
            const templateToDelete = templates.find(t => t.id === templateId);
            
            await apiService.deleteTemplate(templateId);
            
            // Log template deletion activity
            await activityLogger.logUserAction('template_deleted', {
                templateId,
                templateName: templateToDelete.name,
                category: templateToDelete.category
            }, user.id);
            
            refreshTemplates();
        } catch (err) {
            setError('Failed to delete template');
            console.error('Template deletion error:', err);
        }
    };

    const handleEditTemplate = (templateId, status) => {
        // According to WhatsApp API, only APPROVED, REJECTED, or PAUSED templates can be edited
        const editableStatuses = ['APPROVED', 'REJECTED', 'PAUSED'];
        
        if (!editableStatuses.includes(status)) {
            setError(`Cannot edit template with status "${status}". Only templates with APPROVED, REJECTED, or PAUSED status can be edited.`);
            return;
        }
        
        // Log template edit initiated activity
        const templateToEdit = templates.find(t => t.id === templateId);
        activityLogger.logUserAction('template_edit_initiated', {
            templateId,
            templateName: templateToEdit.name,
            currentStatus: templateToEdit.status
        }, user.id);
        
        navigate(`/templates/edit/${templateId}`);
    };

    const handleCreateTemplate = () => {
        // Log template creation initiated activity
        activityLogger.logUserAction('template_creation_initiated', {}, user.id);
        
        navigate('/templates/create');
    };

    const handleSearch = (searchTerm) => {
        setFilters(prev => ({
            ...prev,
            search: searchTerm
        }));
        setPagination(prev => ({
            ...prev,
            offset: 0 // Reset to first page on new search
        }));
    };

    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: value
        }));
        setPagination(prev => ({
            ...prev,
            offset: 0 // Reset to first page on filter change
        }));
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'bg-green-100 text-green-800 border-green-200';
            case 'PENDING':
                return 'bg-yellow-100 text-yellow-800 border-yellow-200';
            case 'REJECTED':
                return 'bg-red-100 text-red-800 border-red-200';
            default:
                return 'bg-gray-100 text-gray-800 border-gray-200';
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'APPROVED':
                return <CheckCircle className="w-4 h-4 text-green-600" />;
            case 'PENDING':
                return <Clock className="w-4 h-4 text-yellow-600" />;
            case 'REJECTED':
                return <XCircle className="w-4 h-4 text-red-600" />;
            default:
                return null;
        }
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                            Template Message Manager
                        </h1>

                        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                            <button
                                onClick={refreshTemplates}
                                disabled={loading}
                                className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
                            >
                                <RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />
                                Refresh
                            </button>
                            <button
                                onClick={handleCreateTemplate}
                                className="flex items-center justify-center gap-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                            >
                                <Plus size={16} />
                                New Template
                            </button>
                            <button
                                onClick={() => navigate('/templates/send')}
                                className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            >
                                <Send size={16} />
                                Send Template
                            </button>
                            <button
                                onClick={() => navigate('/dashboard/templates')}
                                className="flex items-center justify-center gap-2 bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600"
                            >
                                <BarChart2 size={16} />
                                Performance
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Filters */}
            <div className="bg-white border-b">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                                <input
                                    type="text"
                                    placeholder="Search templates..."
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    value={filters.search}
                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                />
                            </div>
                        </div>
                        <select
                            className="border rounded-lg px-4 py-2 bg-white"
                            value={filters.category}
                            onChange={(e) => setFilters({ ...filters, category: e.target.value })}
                        >
                            <option value="">All Categories</option>
                            <option value="MARKETING">Marketing</option>
                            <option value="UTILITY">Utility</option>
                            <option value="AUTHENTICATION">Authentication</option>
                        </select>
                        <select
                            className="border rounded-lg px-4 py-2 bg-white"
                            value={filters.status}
                            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                        >
                            <option value="">All Statuses</option>
                            <option value="APPROVED">Approved</option>
                            <option value="PENDING">Pending</option>
                            <option value="REJECTED">Rejected</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <main className="flex-1 overflow-auto py-6 px-4 sm:px-6">
                <div className="max-w-7xl mx-auto">
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                        </div>
                    ) : error ? (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                            {error}
                        </div>
                    ) : (
                        <div className="bg-white rounded-lg shadow">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Template Name
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Category
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Language
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {templates.map((template) => (
                                            <tr key={template.id} className="hover:bg-gray-50">
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {template.name}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">
                                                        {template.category}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">
                                                        {template.language}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeClass(template.status)}`}>
                                                        {getStatusIcon(template.status)}
                                                        <span className="ml-1">{template.status}</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center space-x-3">
                                                        <button
                                                            onClick={() => handleEditTemplate(template.id, template.status)}
                                                            className="text-blue-600 hover:text-blue-900">
                                                            <Edit size={16} />
                                                        </button>
                                                        <button 
                                                            onClick={() => handleDeleteTemplate(template.id)}
                                                            className="text-red-600 hover:text-red-900">
                                                            <Trash2 size={16} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default TemplateManager;