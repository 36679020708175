import { apiService } from '../services/api';

/**
 * Activity Logger utility for tracking user actions and system events
 */
const activityLogger = {
    /**
     * Log a user action
     * 
     * @param {string} action - The action being performed (e.g., 'user_created', 'user_updated')
     * @param {Object} details - Additional details about the action
     * @param {string} userId - The ID of the user performing the action
     * @returns {Promise} - The API response
     */
    logUserAction: async (action, details = {}, userId) => {
        try {
            const response = await apiService.fetchWithAuth('/api/activities', {
                method: 'POST',
                body: JSON.stringify({
                    type: action,  // Use the action as type (should be a valid enum value)
                    sourceType: 'user',  // Required field
                    data: details,  // Put all details in the data object
                    userId: userId,
                    ip: '',  // Can be filled by the server
                    timestamp: new Date().toISOString()
                })
            });
            return response;
        } catch (error) {
            console.error('Error logging user action:', error);
            return null;
        }
    },

    /**
     * Log a system event
     * 
     * @param {string} eventType - The system event (e.g., 'system_error', 'system_warning')
     * @param {Object} details - Additional details about the event
     * @returns {Promise} - The API response
     */
    logSystemEvent: async (eventType, details = {}) => {
        try {
            const response = await apiService.fetchWithAuth('/api/activities', {
                method: 'POST',
                body: JSON.stringify({
                    type: eventType,  // Should be a valid enum value
                    sourceType: 'system',
                    data: details,
                    timestamp: new Date().toISOString()
                })
            });
            return response;
        } catch (error) {
            console.error('Error logging system event:', error);
            return null;
        }
    },

    /**
     * Log a WhatsApp event
     * 
     * @param {string} eventType - The WhatsApp event (e.g., 'messages_status_updates')
     * @param {Object} details - Additional details about the event
     * @returns {Promise} - The API response
     */
    logWhatsAppEvent: async (eventType, details = {}) => {
        try {
            const response = await apiService.fetchWithAuth('/api/activities', {
                method: 'POST',
                body: JSON.stringify({
                    type: eventType,  // Should be a valid enum value
                    sourceType: 'whatsapp',
                    data: details,
                    timestamp: new Date().toISOString()
                })
            });
            return response;
        } catch (error) {
            console.error('Error logging WhatsApp event:', error);
            return null;
        }
    },

    /**
     * Log authentication activities (login, logout, etc.)
     * 
     * @param {string} eventType - The auth action (e.g., 'login_success', 'logout')
     * @param {string} userId - The user ID
     * @param {Object} details - Additional details
     * @returns {Promise} - The API response
     */
    logAuthActivity: async (eventType, userId, details = {}) => {
        try {
            const response = await apiService.fetchWithAuth('/api/activities', {
                method: 'POST',
                body: JSON.stringify({
                    type: eventType,  // Should be a valid enum value
                    sourceType: 'auth',
                    data: {
                        ...details,
                        userAgent: navigator.userAgent
                    },
                    userId: userId,
                    ip: '',  // Can be filled by the server
                    timestamp: new Date().toISOString()
                })
            });
            return response;
        } catch (error) {
            console.error('Error logging auth activity:', error);
            return null;
        }
    },

    /**
     * Request browser notification permission
     * 
     * @returns {Promise<string>} - Permission state
     */
    requestNotificationPermission: async () => {
        if (!('Notification' in window)) {
            console.log('This browser does not support desktop notifications');
            return 'not-supported';
        }
        
        if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
            const permission = await Notification.requestPermission();
            return permission;
        }
        
        return Notification.permission;
    }
};

export default activityLogger; 