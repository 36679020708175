import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { apiService } from '../../services/api';
import { Edit2, Save, X, User, Lock, Bell, Shield, CheckCircle } from 'lucide-react';

const UserProfile = () => {
    const { user, updateUserInfo } = useAuth();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });
    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('personal');
    
    // Personal info state
    const [profileData, setProfileData] = useState({
        username: '',
        email: '',
        name: '',
        role: '',
        phone: '',
        bio: ''
    });
    
    // Password change state
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    
    // Preferences state
    const [preferences, setPreferences] = useState({
        emailNotifications: true,
        browserNotifications: true,
        sound: true,
        language: 'en',
        theme: 'light'
    });
    
    useEffect(() => {
        // Initialize form with user data
        if (user) {
            setProfileData({
                username: user.username || '',
                email: user.email || '',
                name: user.name || '',
                role: user.role || '',
                phone: user.phone || '',
                bio: user.bio || ''
            });
            
            // If there are saved preferences, load them
            if (user.preferences) {
                setPreferences(user.preferences);
            }
        }
    }, [user]);
    
    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handlePreferencesChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPreferences(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    
    const saveProfile = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage({ type: '', text: '' });
        
        try {
            const response = await apiService.fetchWithAuth('/api/users/profile', {
                method: 'PUT',
                body: JSON.stringify(profileData)
            });
            
            if (response.status === 'success') {
                setMessage({ type: 'success', text: 'Profile updated successfully' });
                // Update auth context if needed
                if (updateUserInfo) {
                    updateUserInfo(profileData);
                }
                setEditMode(false);
            } else {
                setMessage({ type: 'error', text: response.message || 'Failed to update profile' });
            }
        } catch (error) {
            console.error('Profile update error:', error);
            setMessage({ type: 'error', text: 'An error occurred while updating your profile' });
        } finally {
            setLoading(false);
        }
    };
    
    const changePassword = async (e) => {
        e.preventDefault();
        
        // Validate passwords
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setMessage({ type: 'error', text: 'New passwords do not match' });
            return;
        }
        
        setLoading(true);
        setMessage({ type: '', text: '' });
        
        try {
            const response = await apiService.fetchWithAuth('/api/users/change-password', {
                method: 'POST',
                body: JSON.stringify({
                    currentPassword: passwordData.currentPassword,
                    newPassword: passwordData.newPassword
                })
            });
            
            if (response.status === 'success') {
                setMessage({ type: 'success', text: 'Password changed successfully' });
                // Clear form
                setPasswordData({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
            } else {
                setMessage({ type: 'error', text: response.message || 'Failed to change password' });
            }
        } catch (error) {
            console.error('Password change error:', error);
            setMessage({ type: 'error', text: 'An error occurred while changing your password' });
        } finally {
            setLoading(false);
        }
    };
    
    const savePreferences = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage({ type: '', text: '' });
        
        try {
            const response = await apiService.fetchWithAuth('/api/users/preferences', {
                method: 'PUT',
                body: JSON.stringify(preferences)
            });
            
            if (response.status === 'success') {
                setMessage({ type: 'success', text: 'Preferences saved successfully' });
            } else {
                setMessage({ type: 'error', text: response.message || 'Failed to save preferences' });
            }
        } catch (error) {
            console.error('Preferences update error:', error);
            setMessage({ type: 'error', text: 'An error occurred while saving your preferences' });
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="max-w-4xl mx-auto p-6">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="p-6 border-b border-gray-200">
                    <h1 className="text-2xl font-bold text-gray-900">User Profile</h1>
                    <p className="text-gray-500">Manage your account information and preferences</p>
                </div>
                
                {/* Profile Header with Avatar */}
                <div className="flex flex-col md:flex-row items-center justify-between px-8 py-6 bg-gray-50">
                    <div className="flex items-center mb-4 md:mb-0">
                        <div className="h-24 w-24 rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center text-white text-3xl font-bold shadow-md">
                            {profileData.name?.[0]?.toUpperCase() || profileData.username?.[0]?.toUpperCase() || 'U'}
                        </div>
                        <div className="ml-4">
                            <h2 className="text-xl font-semibold">{profileData.name || profileData.username}</h2>
                            <p className="text-gray-600">{profileData.email}</p>
                            <span className="inline-block px-2 py-1 mt-1 text-xs font-medium rounded-full bg-blue-100 text-blue-800 capitalize">
                                {profileData.role}
                            </span>
                        </div>
                    </div>
                    
                    {activeTab === 'personal' && (
                        <div>
                            <button 
                                onClick={() => setEditMode(!editMode)}
                                className={`
                                    px-4 py-2 rounded-md flex items-center gap-2 text-sm font-medium
                                    ${editMode 
                                        ? 'bg-red-50 text-red-700 hover:bg-red-100'
                                        : 'bg-blue-50 text-blue-700 hover:bg-blue-100'}
                                `}
                            >
                                {editMode ? (
                                    <>
                                        <X size={16} />
                                        Cancel
                                    </>
                                ) : (
                                    <>
                                        <Edit2 size={16} />
                                        Edit Profile
                                    </>
                                )}
                            </button>
                        </div>
                    )}
                </div>
                
                {/* Message display */}
                {message.text && (
                    <div className={`mx-8 mt-4 p-3 rounded-md ${
                        message.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                    }`}>
                        <div className="flex items-center">
                            {message.type === 'success' ? (
                                <CheckCircle size={18} className="mr-2" />
                            ) : (
                                <X size={18} className="mr-2" />
                            )}
                            {message.text}
                        </div>
                    </div>
                )}
                
                {/* Tabs */}
                <div className="flex border-b border-gray-200 px-8">
                    <button
                        className={`py-4 px-4 font-medium text-sm flex items-center gap-2 border-b-2 ${
                            activeTab === 'personal' 
                                ? 'border-blue-600 text-blue-600' 
                                : 'border-transparent text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('personal')}
                    >
                        <User size={16} />
                        Personal Information
                    </button>
                    <button
                        className={`py-4 px-4 font-medium text-sm flex items-center gap-2 border-b-2 ${
                            activeTab === 'security' 
                                ? 'border-blue-600 text-blue-600' 
                                : 'border-transparent text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('security')}
                    >
                        <Lock size={16} />
                        Password & Security
                    </button>
                    <button
                        className={`py-4 px-4 font-medium text-sm flex items-center gap-2 border-b-2 ${
                            activeTab === 'preferences' 
                                ? 'border-blue-600 text-blue-600' 
                                : 'border-transparent text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('preferences')}
                    >
                        <Bell size={16} />
                        Preferences
                    </button>
                </div>
                
                {/* Tab Content */}
                <div className="p-8">
                    {/* Personal Information Tab */}
                    {activeTab === 'personal' && (
                        <form onSubmit={saveProfile}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Username
                                    </label>
                                    <input
                                        type="text"
                                        name="username"
                                        value={profileData.username}
                                        onChange={handleProfileChange}
                                        disabled={!editMode}
                                        className={`
                                            w-full px-3 py-2 border rounded-md 
                                            ${editMode ? 'border-gray-300' : 'border-gray-200 bg-gray-50'} 
                                            focus:outline-none focus:ring-2 focus:ring-blue-500
                                        `}
                                    />
                                </div>
                                
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={profileData.email}
                                        onChange={handleProfileChange}
                                        disabled={!editMode}
                                        className={`
                                            w-full px-3 py-2 border rounded-md 
                                            ${editMode ? 'border-gray-300' : 'border-gray-200 bg-gray-50'} 
                                            focus:outline-none focus:ring-2 focus:ring-blue-500
                                        `}
                                    />
                                </div>
                                
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={profileData.name}
                                        onChange={handleProfileChange}
                                        disabled={!editMode}
                                        className={`
                                            w-full px-3 py-2 border rounded-md 
                                            ${editMode ? 'border-gray-300' : 'border-gray-200 bg-gray-50'} 
                                            focus:outline-none focus:ring-2 focus:ring-blue-500
                                        `}
                                    />
                                </div>
                                
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Phone
                                    </label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={profileData.phone}
                                        onChange={handleProfileChange}
                                        disabled={!editMode}
                                        className={`
                                            w-full px-3 py-2 border rounded-md 
                                            ${editMode ? 'border-gray-300' : 'border-gray-200 bg-gray-50'} 
                                            focus:outline-none focus:ring-2 focus:ring-blue-500
                                        `}
                                    />
                                </div>
                                
                                <div className="md:col-span-2">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Bio
                                    </label>
                                    <textarea
                                        name="bio"
                                        value={profileData.bio}
                                        onChange={handleProfileChange}
                                        disabled={!editMode}
                                        rows={4}
                                        className={`
                                            w-full px-3 py-2 border rounded-md 
                                            ${editMode ? 'border-gray-300' : 'border-gray-200 bg-gray-50'} 
                                            focus:outline-none focus:ring-2 focus:ring-blue-500
                                        `}
                                    />
                                </div>
                            </div>
                            
                            {editMode && (
                                <div className="mt-6 flex justify-end">
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center gap-2 disabled:opacity-50"
                                    >
                                        <Save size={16} />
                                        Save Changes
                                    </button>
                                </div>
                            )}
                        </form>
                    )}
                    
                    {/* Password & Security Tab */}
                    {activeTab === 'security' && (
                        <form onSubmit={changePassword}>
                            <div className="space-y-5">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Current Password
                                    </label>
                                    <input
                                        type="password"
                                        name="currentPassword"
                                        value={passwordData.currentPassword}
                                        onChange={handlePasswordChange}
                                        required
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        New Password
                                    </label>
                                    <input
                                        type="password"
                                        name="newPassword"
                                        value={passwordData.newPassword}
                                        onChange={handlePasswordChange}
                                        required
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    <p className="mt-1 text-xs text-gray-500">
                                        Password must be at least 8 characters long and include a mix of letters, numbers, and symbols.
                                    </p>
                                </div>
                                
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Confirm New Password
                                    </label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        value={passwordData.confirmPassword}
                                        onChange={handlePasswordChange}
                                        required
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                
                                <div className="mt-6">
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center gap-2 disabled:opacity-50"
                                    >
                                        <Shield size={16} />
                                        Change Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                    
                    {/* Preferences Tab */}
                    {activeTab === 'preferences' && (
                        <form onSubmit={savePreferences}>
                            <div className="space-y-5">
                                <div>
                                    <h3 className="text-lg font-medium text-gray-900 mb-3">Notifications</h3>
                                    <div className="space-y-3">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="emailNotifications"
                                                name="emailNotifications"
                                                checked={preferences.emailNotifications}
                                                onChange={handlePreferencesChange}
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="emailNotifications" className="ml-2 block text-sm text-gray-700">
                                                Email Notifications
                                            </label>
                                        </div>
                                        
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="browserNotifications"
                                                name="browserNotifications"
                                                checked={preferences.browserNotifications}
                                                onChange={handlePreferencesChange}
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="browserNotifications" className="ml-2 block text-sm text-gray-700">
                                                Browser Notifications
                                            </label>
                                        </div>
                                        
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="sound"
                                                name="sound"
                                                checked={preferences.sound}
                                                onChange={handlePreferencesChange}
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="sound" className="ml-2 block text-sm text-gray-700">
                                                Notification Sounds
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Language
                                        </label>
                                        <select
                                            name="language"
                                            value={preferences.language}
                                            onChange={handlePreferencesChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        >
                                            <option value="en">English</option>
                                            <option value="es">Spanish</option>
                                            <option value="fr">French</option>
                                            <option value="de">German</option>
                                            <option value="ar">Arabic</option>
                                        </select>
                                    </div>
                                    
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Theme
                                        </label>
                                        <select
                                            name="theme"
                                            value={preferences.theme}
                                            onChange={handlePreferencesChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        >
                                            <option value="light">Light</option>
                                            <option value="dark">Dark</option>
                                            <option value="system">System</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className="mt-6">
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center gap-2 disabled:opacity-50"
                                    >
                                        <Save size={16} />
                                        Save Preferences
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserProfile; 