import React, { useState, useEffect } from 'react';
import { Instagram, User, Image as ImageIcon, FileVideo, Link, AlertCircle } from 'lucide-react';
import { getInstagramContentType, getInstagramContentId } from '../../utils/linkUtils';

const InstagramPreview = ({ url, onClick }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [metadata, setMetadata] = useState(null);
    
    const contentType = getInstagramContentType(url);
    const contentId = getInstagramContentId(url);
    
    // Simulated metadata fetch - in a real app, you would fetch this from an API
    useEffect(() => {
        if (!url || !contentType || !contentId) {
            setError('Invalid Instagram URL');
            setLoading(false);
            return;
        }
        
        setLoading(true);
        setError(null);
        
        // Mock API delay
        const fetchTimeout = setTimeout(() => {
            try {
                // Create metadata based on the content type
                const metadataMap = {
                    profile: {
                        title: `@${contentId}`,
                        description: 'Instagram Profile',
                        image: null, // Would normally be profile picture
                        siteName: 'Instagram'
                    },
                    post: {
                        title: 'Instagram Post',
                        description: `Post by @${contentId.split('_')[0] || 'user'}`,
                        image: null, // Would normally be post thumbnail
                        siteName: 'Instagram'
                    },
                    reel: {
                        title: 'Instagram Reel',
                        description: 'Click to watch this video',
                        image: null, // Would normally be reel thumbnail
                        siteName: 'Instagram'
                    },
                    story: {
                        title: 'Instagram Story',
                        description: `@${contentId}'s story`,
                        image: null,
                        siteName: 'Instagram'
                    }
                };
                
                setMetadata(metadataMap[contentType]);
                setLoading(false);
            } catch (err) {
                console.error('Error processing Instagram URL:', err);
                setError('Could not load preview');
                setLoading(false);
            }
        }, 500); // Simulate network delay
        
        return () => clearTimeout(fetchTimeout);
    }, [url, contentType, contentId]);
    
    // Loading state
    if (loading) {
        return (
            <div className="border rounded-lg p-3 bg-gray-50 animate-pulse flex items-center space-x-2">
                <Instagram className="w-5 h-5 text-gray-400" />
                <span className="text-sm text-gray-500">Loading Instagram preview...</span>
            </div>
        );
    }
    
    // Error state
    if (error || !metadata) {
        return (
            <div className="border rounded-lg p-3 bg-gray-50 flex items-center space-x-2">
                <AlertCircle className="w-5 h-5 text-red-500" />
                <span className="text-sm text-gray-700">{error || 'Invalid Instagram link'}</span>
                <a 
                    href={url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-500 text-sm ml-auto hover:underline flex items-center"
                    onClick={(e) => {
                        e.preventDefault();
                        onClick(url);
                    }}
                >
                    <Instagram size={14} className="mr-1" />
                    Open
                </a>
            </div>
        );
    }
    
    // Get content-specific icon
    const getContentIcon = () => {
        switch (contentType) {
            case 'profile':
                return <User className="w-4 h-4 text-purple-600" />;
            case 'post':
                return <ImageIcon className="w-4 h-4 text-pink-600" />;
            case 'reel':
                return <FileVideo className="w-4 h-4 text-red-600" />;
            default:
                return <Instagram className="w-4 h-4 text-pink-600" />;
        }
    };
    
    // Instagram preview
    return (
        <div 
            className="border rounded-lg overflow-hidden bg-white shadow-sm hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => onClick(url)}
        >
            <div className="p-3">
                <div className="flex items-start">
                    <div className="p-2 bg-gradient-to-br from-purple-500 via-pink-500 to-yellow-500 rounded-md flex-shrink-0">
                        {getContentIcon()}
                    </div>
                    <div className="ml-3">
                        <h3 className="font-medium text-sm line-clamp-1">
                            {metadata.title}
                        </h3>
                        <p className="text-xs text-gray-500 mt-1 line-clamp-1">
                            {metadata.description}
                        </p>
                        <div className="text-xs text-gray-400 mt-2 flex items-center">
                            <Instagram size={12} className="mr-1" /> 
                            {metadata.siteName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstagramPreview; 