import React from 'react';

const Card = ({ 
    children, 
    className = '', 
    title, 
    subtitle,
    headerAction,
    footerContent,
    noPadding = false 
}) => {
    return (
        <div className={`bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden ${className}`}>
            {(title || subtitle) && (
                <div className="px-6 py-4 border-b border-gray-100 flex justify-between items-center">
                    <div>
                        {title && <h3 className="text-lg font-semibold text-gray-900">{title}</h3>}
                        {subtitle && <p className="text-sm text-gray-500 mt-0.5">{subtitle}</p>}
                    </div>
                    {headerAction && <div>{headerAction}</div>}
                </div>
            )}
            <div className={noPadding ? '' : 'p-6'}>
                {children}
            </div>
            {footerContent && (
                <div className="px-6 py-3 bg-gray-50 border-t border-gray-100">
                    {footerContent}
                </div>
            )}
        </div>
    );
};

export default Card; 