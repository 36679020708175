// src/components/dashboard/UserActivityTable.js

import React from 'react';
import { formatDistanceToNow } from 'date-fns';

const UserActivityTable = ({ users }) => {
    if (!users || !users.length) {
        return (
            <div className="text-center py-4 text-gray-500">
                No active users found in this time period
            </div>
        );
    }

    const getInitials = (name) => {
        if (!name || name === 'Unknown') return '??';

        if (/\p{Emoji}/u.test(name)) {
            return name.slice(0, 2);
        }

        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            User
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Messages Sent
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Messages Received
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Last Active
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user, index) => (
                        <tr key={user.phone || index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                                        <span className="text-indigo-800 font-medium">
                                            {getInitials(user.name || 'Unknown')}
                                        </span>
                                    </div>
                                    <div className="ml-4">
                                        <div className="text-sm font-medium text-gray-900">
                                            {user.name || 'Unknown User'}
                                        </div>
                                        <div className="text-sm text-gray-500">
                                            {user.phone ? user.phone.replace(/(\d{2})(\d{5})(\d{5})/, '+$1 $2 $3') : 'No Phone'}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {user.messagesSent || 0}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {user.messagesReceived || 0}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {user.lastActivity 
                                    ? formatDistanceToNow(new Date(user.lastActivity), { addSuffix: true })
                                    : 'Unknown'
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserActivityTable;