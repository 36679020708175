//src/components/chat/Message.js
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { FileText, Image, Film, Mic, ExternalLink, File, FileArchive, FilePlus, FileCode, Link, Instagram, MapPin, User, Phone, Plus, AlertCircle, Loader } from 'lucide-react';

import { apiService } from '../../services/api';
import { config } from '../../config';
import MediaViewer from './MediaViewer';
import LinkPreview from './LinkPreview';
import InstagramPreview from './InstagramPreview';
import YouTubeModal from './YouTubeModal';
import InstagramModal from './InstagramModal';
import { 
    extractUrls, 
    renderTextWithLinks, 
    isYouTubeUrl, 
    isInstagramUrl 
} from '../../utils/linkUtils';

const Message = ({ message }) => {
    const [viewerOpen, setViewerOpen] = useState(false);
    const [currentMedia, setCurrentMedia] = useState(null);
    const [youtubeUrl, setYoutubeUrl] = useState(null);
    const [youtubeModalOpen, setYoutubeModalOpen] = useState(false);
    const [instagramUrl, setInstagramUrl] = useState(null);
    const [instagramModalOpen, setInstagramModalOpen] = useState(false);
    const [templateData, setTemplateData] = useState(null);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const isOutgoing = message.direction === 'outgoing';
    //get the base url

    //console.log('baseUrl-->', apiService.getBaseUrl());
    const baseUrl = apiService.getBaseUrl();

    // Extract URLs from the message text
    const urls = message.content.text ? extractUrls(message.content.text) : [];
    const firstUrl = urls.length > 0 ? urls[0] : null;
    
    // Determine the type of the first URL for preview purposes
    const isYouTube = firstUrl && isYouTubeUrl(firstUrl);
    const isInstagram = firstUrl && isInstagramUrl(firstUrl);

    // Fetch template details if this is a template message
    useEffect(() => {
        if (message.type === 'template_message' && message.content.template) {
            fetchTemplateDetails(message.content.template.name, message.content.template.language);
        }
    }, [message]);

    const fetchTemplateDetails = async (templateName, language) => {
        if (!templateName) return;
        
        setLoadingTemplate(true);
        try {
            // Fetch templates from the API
            const response = await apiService.getTemplates({
                name: templateName,
                status: 'APPROVED'
            });
            
            if (response?.data?.status === 'success' && response.data?.data?.data) {
                const templates = response.data.data.data;
                // Find the exact template by name and language
                const template = templates.find(t => 
                    t.name === templateName && 
                    (!language || t.language === language)
                );
                
                if (template) {
                    setTemplateData(template);
                }
            }
        } catch (error) {
            console.error('Error fetching template details:', error);
        } finally {
            setLoadingTemplate(false);
        }
    };

    const openMediaViewer = (mediaType) => {
        const mediaUrl = `${baseUrl}${message.content.mediaUrl}`;
        
        console.log(`Opening ${mediaType} in MediaViewer:`, message.content);
        
        setCurrentMedia({
            type: mediaType,
            url: mediaUrl,
            caption: message.content.caption,
            fileName: message.content.fileName || 'File'
        });
        
        setViewerOpen(true);
    };

    const handleLinkClick = (url) => {
        if (isYouTubeUrl(url)) {
            setYoutubeUrl(url);
            setYoutubeModalOpen(true);
        } else if (isInstagramUrl(url)) {
            setInstagramUrl(url);
            setInstagramModalOpen(true);
        } else {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'sent':
                return '✓';
            case 'delivered':
                return '✓✓';
            case 'read':
                return <span className="text-blue-500">✓✓</span>;
            case 'failed':
                return '⚠️';
            default:
                return '⌛';
        }
    };

    const getFileIcon = (fileUrl) => {
        if (!fileUrl) return <FileText className="w-8 h-8 text-blue-500" />;
        
        const ext = fileUrl.split('.').pop().toLowerCase();
        
        // Images
        if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(ext)) {
            return <Image className="w-8 h-8 text-green-500" />;
        }
        
        // Videos
        if (['mp4', 'mov', 'avi', 'webm', 'mkv'].includes(ext)) {
            return <Film className="w-8 h-8 text-purple-500" />;
        }
        
        // Audio
        if (['mp3', 'wav', 'ogg', 'aac', 'm4a'].includes(ext)) {
            return <Mic className="w-8 h-8 text-red-500" />;
        }
        
        // Archives
        if (['zip', 'rar', '7z', 'tar', 'gz'].includes(ext)) {
            return <FileArchive className="w-8 h-8 text-amber-500" />;
        }
        
        // Code
        if (['js', 'jsx', 'ts', 'tsx', 'html', 'css', 'py', 'java', 'php', 'c', 'cpp', 'json'].includes(ext)) {
            return <FileCode className="w-8 h-8 text-cyan-500" />;
        }
        
        // Documents
        if (['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'].includes(ext)) {
            return <FileText className="w-8 h-8 text-blue-500" />;
        }
        
        return <File className="w-8 h-8 text-gray-500" />;
    };

    const renderMediaContent = () => {
        if (!message.content.mediaUrl) return null;

        const mediaUrl = `${baseUrl}${message.content.mediaUrl}`;

        switch (message.content.mediaType) {
            case 'image':
                return (
                    <div 
                        className="mb-2 rounded-lg overflow-hidden group relative cursor-pointer"
                        onClick={() => openMediaViewer('image')}
                    >
                        <img
                            src={mediaUrl}
                            alt={message.content.caption || "Image"}
                            className="max-w-60 rounded-lg transition-transform hover:scale-[0.98]"
                            loading="lazy"
                        />
                        {message.content.caption && (
                            <span className="block mt-1 text-sm opacity-80">{message.content.caption}</span>
                        )}
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            <span className="bg-black bg-opacity-60 text-white text-xs px-2 py-1 rounded-full flex items-center">
                                <ExternalLink size={12} className="mr-1" /> View
                            </span>
                        </div>
                    </div>
                );
            case 'video':
                return (
                    <div className="mb-2 group relative">
                        <div 
                            className="relative cursor-pointer"
                            onClick={() => openMediaViewer('video')}
                        >
                            <video
                                className="max-w-60 rounded-lg transition-transform hover:scale-[0.98]"
                                preload="metadata"
                            >
                                <source src={mediaUrl} type="video/mp4" />
                            </video>
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-black bg-opacity-50 rounded-full p-2">
                                    <Film className="w-6 h-6 text-white" />
                                </div>
                            </div>
                        </div>
                        {message.content.caption && (
                            <span className="block mt-1 text-sm opacity-80">{message.content.caption}</span>
                        )}
                    </div>
                );
            case 'audio':
                return (
                    <div className="mb-2 p-3 bg-opacity-10 bg-current rounded-lg">
                        <div className="flex items-center mb-2">
                            <Mic className="w-5 h-5 mr-2" />
                            <span className="text-sm font-medium">
                                {message.content.caption || "Audio message"}
                            </span>
                        </div>
                        <audio 
                            controls 
                            className="w-full max-w-60" 
                            preload="none"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <source src={mediaUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                        <div 
                            className="text-xs mt-1 underline cursor-pointer text-right"
                            onClick={() => openMediaViewer('audio')}
                        >
                            Open in viewer
                        </div>
                    </div>
                );
            case 'document':
                return (
                    <div 
                        className={`mb-2 p-3 rounded-lg ${isOutgoing ? 'bg-indigo-700' : 'bg-gray-100'}`}
                        onClick={() => openMediaViewer('document')}
                    >
                        <div className="flex items-start">
                            <div className="bg-white p-2 rounded mr-3 flex-shrink-0">
                                {getFileIcon(message.content.mediaUrl)}
                            </div>
                            <div className="flex-1 overflow-hidden">
                                <div className={`font-medium text-sm mb-1 ${isOutgoing ? 'text-white' : 'text-gray-800'}`}>
                                    {message.content.fileName || "Document"}
                                </div>
                                {message.content.caption && (
                                    <span className={`block text-xs ${isOutgoing ? 'text-indigo-200' : 'text-gray-600'}`}>
                                        {message.content.caption}
                                    </span>
                                )}
                                <div className="mt-2 flex">
                                    <a
                                        href={mediaUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`text-xs px-3 py-1 rounded-full ${isOutgoing ? 'bg-indigo-800 text-white' : 'bg-gray-200 text-gray-800'} hover:opacity-80 transition-opacity`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        Download
                                    </a>
                                    <button
                                        className={`text-xs px-3 py-1 rounded-full ${isOutgoing ? 'bg-indigo-800 text-white' : 'bg-gray-200 text-gray-800'} hover:opacity-80 transition-opacity ml-2`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openMediaViewer('document');
                                        }}
                                    >
                                        Preview
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const openInGoogleMaps = (latitude, longitude) => {
        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    // Render text content with URL detection
    const renderTextContent = () => {
        if (!message.content.text) return null;
        
        return (
            <div>
                <p className="whitespace-pre-wrap break-words">
                    {renderTextWithLinks(message.content.text, (url, key) => (
                        <a 
                            key={key}
                            href={url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                            onClick={(e) => {
                                e.preventDefault();
                                handleLinkClick(url);
                            }}
                        >
                            {url}
                        </a>
                    ))}
                </p>
                
                {/* Show appropriate preview for the first URL */}
                {firstUrl && (
                    <div className="mt-2">
                        {isYouTube ? (
                            <LinkPreview url={firstUrl} onClick={() => handleLinkClick(firstUrl)} />
                        ) : isInstagram ? (
                            <InstagramPreview url={firstUrl} onClick={() => handleLinkClick(firstUrl)} />
                        ) : (
                            <LinkPreview url={firstUrl} onClick={() => handleLinkClick(firstUrl)} />
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderLocationContent = () => {
        if (message.type !== 'location_message' || !message.content.location) return null;
        
        const { latitude, longitude, name, address } = message.content.location;
        // Use Mapbox with token from config
        const mapboxToken = config.MAPBOX_TOKEN;
        console.log('Using Mapbox token:', mapboxToken); // Debug
        const mapImageUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+f74e4e(${longitude},${latitude})/${longitude},${latitude},14,0/300x200?access_token=${mapboxToken}`;
        
        return (
            <div className={`mb-2 p-3 rounded-lg ${isOutgoing ? 'bg-indigo-700' : 'bg-gray-100'}`}>
                <div className="flex items-center mb-2">
                    <MapPin className={`w-5 h-5 mr-2 ${isOutgoing ? 'text-indigo-200' : 'text-indigo-600'}`} />
                    <span className={`text-sm font-medium ${isOutgoing ? 'text-white' : 'text-gray-800'}`}>
                        {name || "Shared location"}
                    </span>
                </div>
                {address && (
                    <p className={`text-sm mb-2 ${isOutgoing ? 'text-indigo-200' : 'text-gray-600'}`}>{address}</p>
                )}
                <div className="relative cursor-pointer rounded-lg overflow-hidden shadow-md" onClick={() => openInGoogleMaps(latitude, longitude)}>
                    <img 
                        src={mapImageUrl} 
                        alt="Location Map" 
                        className="w-full h-[150px] object-cover transition-transform hover:scale-[0.98]"
                    />
                    <div className="absolute bottom-0 left-0 bg-black bg-opacity-60 text-white text-xs px-2 py-1 m-2 rounded">
                        Location Map
                    </div>
                </div>
                <button 
                    className={`mt-2 text-xs px-3 py-1 rounded-full w-full ${
                        isOutgoing 
                        ? 'bg-indigo-500 text-white hover:bg-indigo-400' 
                        : 'bg-blue-600 text-white hover:bg-blue-500'
                    } transition-colors`}
                    onClick={() => openInGoogleMaps(latitude, longitude)}
                >
                    View on Google Maps
                </button>
            </div>
        );
    };

    const renderTemplateContent = () => {
        if (message.type !== 'template_message' || !message.content.template) return null;
        
        const { name, language, components = [] } = message.content.template;
        let templateComponents = [];
        
        // If we have fetched template data, use it
        if (templateData && templateData.components) {
            templateComponents = templateData.components.map(component => {
                // Make a copy to avoid modifying the original
                return { ...component };
            });
        }
        
        // Apply parameters to template components
        if (components.length > 0) {
            // Process parameters to be applied to template components
            components.forEach(msgComponent => {
                // Find matching component in the template data
                const templateComponent = templateComponents.find(c => 
                    c.type === msgComponent.type ||
                    c.type === msgComponent.type.toUpperCase()
                );
                
                if (templateComponent && msgComponent.parameters) {
                    // Handle different parameter types
                    if (templateComponent.type === 'HEADER' || templateComponent.type === 'header') {
                        // Handle header parameters which can be document, image, video, or text
                        if (msgComponent.parameters && msgComponent.parameters.length > 0) {
                            const param = msgComponent.parameters[0];
                            
                            // Store parameter for rendering
                            templateComponent.parameterValue = param;
                            
                            // For text headers, replace placeholder
                            if (param.type === 'text' && templateComponent.text) {
                                const placeholders = templateComponent.text.match(/\{\{[^}]+\}\}/g) || [];
                                if (placeholders.length > 0) {
                                    templateComponent.text = templateComponent.text.replace(
                                        placeholders[0], 
                                        param.text || ''
                                    );
                                }
                            }
                            
                            // For media headers, store the link
                            if (['document', 'image', 'video'].includes(param.type)) {
                                // Special handling for document type where link may be in text field
                                if (param.type === 'document' && (!param[param.type]?.link || param[param.type]?.link === '') && param.text) {
                                    templateComponent.mediaUrl = param.text;
                                } else {
                                    templateComponent.mediaUrl = param[param.type]?.link || param.text || '';
                                }
                                templateComponent.mediaType = param.type;
                            }
                        }
                    } else if (templateComponent.type === 'BODY' || templateComponent.type === 'body') {
                        // Handle text parameters in body
                        if (templateComponent.text) {
                            const placeholders = templateComponent.text.match(/\{\{[^}]+\}\}/g) || [];
                            
                            placeholders.forEach((placeholder, index) => {
                                if (msgComponent.parameters && 
                                    msgComponent.parameters[index] && 
                                    msgComponent.parameters[index].text) {
                                    templateComponent.text = templateComponent.text.replace(
                                        placeholder, 
                                        msgComponent.parameters[index].text
                                    );
                                }
                            });
                        }
                    }
                }
            });
        }
        
        // Define fallback templates
        const fallbackTemplates = {
            hello_world: {
                header: {
                    type: 'HEADER',
                    format: 'TEXT',
                    text: 'Hello World'
                },
                body: {
                    type: 'BODY',
                    text: 'Welcome and congratulations!! This message demonstrates your ability to send a message notification from WhatsApp Business Platform\'s Cloud API. Thank you for taking the time to test with us.'
                }
            },
            bitvoice_missed_call: {
                header: {
                    type: 'HEADER',
                    format: 'TEXT',
                    text: 'missed call'
                },
                body: {
                    type: 'BODY',
                    text: `We received a Call form {{1}}, Sorry we missed your call we will call you back with in 1 hours`
                }
            },
            lab_report: {
                header: {
                    type: 'HEADER',
                    format: 'DOCUMENT',
                    mediaType: 'document'
                },
                body: {
                    type: 'BODY',
                    text: `Hello {{1}}, your lab report is ready. Please find it attached above.`
                }
            }
        };
        
        // Apply message parameters to fallback template if needed
        if (templateComponents.length === 0 && fallbackTemplates[name]) {
            const fallback = fallbackTemplates[name];
            
            // Apply header
            if (fallback.header) {
                const headerComponent = {...fallback.header};
                
                // Apply header parameters if available
                const headerParams = components.find(c => 
                    c.type === 'header' || c.type === 'HEADER'
                )?.parameters;
                
                if (headerParams && headerParams.length > 0) {
                    const param = headerParams[0];
                    headerComponent.parameterValue = param;
                    
                    if (param.type === 'text' && headerComponent.text) {
                        const placeholders = headerComponent.text.match(/\{\{[^}]+\}\}/g) || [];
                        if (placeholders.length > 0) {
                            headerComponent.text = headerComponent.text.replace(
                                placeholders[0], 
                                param.text || ''
                            );
                        }
                    } else if (['document', 'image', 'video'].includes(param.type)) {
                        // Special handling for document type where link may be in text field
                        if (param.type === 'document' && (!param[param.type]?.link || param[param.type]?.link === '') && param.text) {
                            headerComponent.mediaUrl = param.text;
                        } else {
                            headerComponent.mediaUrl = param[param.type]?.link || param.text || '';
                        }
                        headerComponent.mediaType = param.type;
                    }
                }
                
                templateComponents.push(headerComponent);
            }
            
            // Apply body
            if (fallback.body) {
                const bodyComponent = {...fallback.body};
                
                // Apply body parameters if available
                const bodyParams = components.find(c => 
                    c.type === 'body' || c.type === 'BODY'
                )?.parameters;
                
                if (bodyParams && bodyParams.length > 0 && bodyComponent.text) {
                    const placeholders = bodyComponent.text.match(/\{\{[^}]+\}\}/g) || [];
                    
                    placeholders.forEach((placeholder, index) => {
                        if (bodyParams[index] && bodyParams[index].text) {
                            bodyComponent.text = bodyComponent.text.replace(
                                placeholder, 
                                bodyParams[index].text
                            );
                        }
                    });
                }
                
                templateComponents.push(bodyComponent);
            }
        }
        
        // Default WhatsApp colors for templates
        const bgColor = isOutgoing ? 'bg-indigo-600' : 'bg-white';
        const headerColor = isOutgoing ? 'text-white' : 'text-gray-800';
        const bodyColor = isOutgoing ? 'text-white' : 'text-gray-700';
        const metaColor = isOutgoing ? 'text-indigo-200' : 'text-gray-500';
        
        return (
            <div className={`mb-2 overflow-hidden rounded-lg ${bgColor}`}>
                <div className="bg-opacity-10 bg-gray-500 px-3 py-2 border-b border-opacity-10 border-gray-300">
                    <div className="flex items-center">
                        <AlertCircle className={`w-4 h-4 mr-2 ${isOutgoing ? 'text-indigo-200' : 'text-indigo-600'}`} />
                        <span className={`text-sm font-medium ${headerColor}`}>
                            {name}
                        </span>
                        <span className={`ml-auto text-xs ${metaColor}`}>
                            {language}
                        </span>
                    </div>
                </div>
                
                {/* Template body content */}
                <div className="p-3">
                    {loadingTemplate ? (
                        <div className="flex items-center justify-center py-4">
                            <Loader className={`w-5 h-5 animate-spin ${isOutgoing ? 'text-indigo-200' : 'text-indigo-600'}`} />
                            <span className={`ml-2 text-sm ${metaColor}`}>
                                Loading template...
                            </span>
                        </div>
                    ) : templateComponents.length > 0 ? (
                        templateComponents.map((component, index) => {
                            if (component.type === 'HEADER') {
                                if (component.format === 'TEXT') {
                                    return (
                                        <div key={`header-${index}`} className={`font-medium mb-3 ${headerColor}`}>
                                            {component.text}
                                        </div>
                                    );
                                } else if (component.format === 'DOCUMENT' || component.mediaType === 'document') {
                                    const documentUrl = component.mediaUrl || '';
                                    const fileName = documentUrl.split('/').pop() || 'Document';
                                    return (
                                        <div key={`header-${index}`} className={`mb-3 p-3 rounded ${isOutgoing ? 'bg-indigo-700' : 'bg-gray-100'}`}>
                                            <div className="flex items-start">
                                                <div className="bg-white p-2 rounded mr-3 flex-shrink-0">
                                                    <FileText className="w-8 h-8 text-blue-500" />
                                                </div>
                                                <div className="flex-1 overflow-hidden">
                                                    <div className={`font-medium text-sm mb-1 ${headerColor}`}>
                                                        {fileName}
                                                    </div>
                                                    <div className={`text-xs truncate mb-2 ${metaColor}`}>
                                                        {documentUrl}
                                                    </div>
                                                    <div className="mt-2 flex">
                                                        <a
                                                            href={documentUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className={`text-xs px-3 py-1 rounded-full ${isOutgoing ? 'bg-indigo-800 text-white' : 'bg-gray-200 text-gray-800'} hover:opacity-80 transition-opacity`}
                                                        >
                                                            View Document
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if (component.format === 'IMAGE' || component.mediaType === 'image') {
                                    const imageUrl = component.mediaUrl || '';
                                    return (
                                        <div key={`header-${index}`} className="mb-3">
                                            <div className="rounded-lg overflow-hidden mb-2">
                                                {imageUrl ? (
                                                    <img 
                                                        src={imageUrl}
                                                        alt="Template header" 
                                                        className="w-full object-cover max-h-60"
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'https://via.placeholder.com/300x200?text=Image+Error';
                                                        }}
                                                    />
                                                ) : (
                                                    <div className="h-40 bg-gray-100 rounded flex items-center justify-center">
                                                        <Image className={`w-8 h-8 ${isOutgoing ? 'text-indigo-200' : 'text-gray-400'}`} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                } else if (component.format === 'VIDEO' || component.mediaType === 'video') {
                                    const videoUrl = component.mediaUrl || '';
                                    return (
                                        <div key={`header-${index}`} className="mb-3">
                                            <div className="rounded-lg overflow-hidden relative mb-2">
                                                {videoUrl ? (
                                                    <div className="relative">
                                                        <video
                                                            className="w-full max-h-60 rounded"
                                                            controls
                                                            preload="none"
                                                        >
                                                            <source src={videoUrl} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                ) : (
                                                    <div className="h-40 bg-gray-100 rounded flex items-center justify-center">
                                                        <Film className={`w-8 h-8 ${isOutgoing ? 'text-indigo-200' : 'text-gray-400'}`} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }
                            } else if (component.type === 'BODY') {
                                return (
                                    <div key={`body-${index}`} className={`text-sm mb-3 whitespace-pre-line ${bodyColor}`}>
                                        {component.text}
                                    </div>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <div className={`text-sm italic ${metaColor}`}>
                            Template message: {name}
                        </div>
                    )}
                </div>
                
                {/* Template buttons */}
                {message.content.buttons && message.content.buttons.length > 0 && (
                    <div className="px-3 pb-3 space-y-2">
                        {message.content.buttons.map((button, index) => (
                            <button 
                                key={index}
                                className={`w-full py-2 px-3 text-sm font-medium rounded-md ${
                                    isOutgoing 
                                    ? 'bg-indigo-700 text-white hover:bg-indigo-600' 
                                    : 'bg-gray-100 text-indigo-600 hover:bg-gray-200'
                                }`}
                            >
                                {button.text || 'Button'}
                            </button>
                        ))}
                    </div>
                )}
                
                {/* Buttons from template */}
                {!message.content.buttons && templateData && templateData.components && (
                    <div className="px-3 pb-3 space-y-2">
                        {templateData.components
                            .filter(comp => comp.type === 'BUTTONS')
                            .flatMap(comp => comp.buttons || [])
                            .map((button, index) => (
                                <button 
                                    key={index}
                                    className={`w-full py-2 px-3 text-sm font-medium rounded-md ${
                                        isOutgoing 
                                        ? 'bg-indigo-700 text-white hover:bg-indigo-600' 
                                        : 'bg-gray-100 text-indigo-600 hover:bg-gray-200'
                                    }`}
                                >
                                    {button.text || 'Button'}
                                </button>
                            ))}
                    </div>
                )}
            </div>
        );
    };

    const renderContactContent = () => {
        if (message.type !== 'contact_message' || !message.content.contacts) return null;
        
        return (
            <div className={`mb-2 p-3 rounded-lg ${isOutgoing ? 'bg-indigo-700' : 'bg-gray-100'}`}>
                {message.content.contacts.map((contact, index) => (
                    <div key={index} className="mb-2 last:mb-0">
                        <div className="flex items-center mb-2">
                            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${isOutgoing ? 'bg-indigo-600' : 'bg-indigo-500'} mr-3`}>
                                <User className={`w-4 h-4 ${isOutgoing ? 'text-indigo-200' : 'text-white'}`} />
                            </div>
                            <span className={`text-sm font-medium ${isOutgoing ? 'text-white' : 'text-gray-800'}`}>
                                {contact.name?.formatted_name || contact.name?.first_name || 'Contact'}
                            </span>
                        </div>
                        
                        {contact.phones && contact.phones.map((phoneInfo, phoneIndex) => (
                            <div key={phoneIndex} className={`flex items-center py-1 px-2 rounded ${isOutgoing ? 'bg-indigo-600' : 'bg-white'} mb-2 last:mb-0`}>
                                <Phone className={`w-4 h-4 mr-2 ${isOutgoing ? 'text-indigo-200' : 'text-indigo-600'}`} />
                                <span className={`text-sm flex-1 ${isOutgoing ? 'text-white' : 'text-gray-700'}`}>
                                    {phoneInfo.phone}
                                </span>
                                <a 
                                    href={`tel:${phoneInfo.phone.replace(/\s+/g, '')}`}
                                    className={`text-xs px-2 py-1 rounded ${isOutgoing ? 'bg-indigo-500 text-white' : 'bg-indigo-100 text-indigo-700'} hover:opacity-80`}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    Call
                                </a>
                            </div>
                        ))}
                        
                        <button 
                            className={`mt-2 text-xs px-3 py-1 rounded-full w-full flex items-center justify-center ${
                                isOutgoing 
                                ? 'bg-indigo-500 text-white hover:bg-indigo-400' 
                                : 'bg-blue-600 text-white hover:bg-blue-500'
                            } transition-colors`}
                            onClick={() => {
                                // Create a vCard format string
                                const vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${contact.name?.formatted_name || contact.name?.first_name || 'Contact'}
TEL;TYPE=CELL:${contact.phones?.[0]?.phone || ''}
END:VCARD`;
                                
                                // Create a blob and download it
                                const blob = new Blob([vCardData], { type: 'text/vcard' });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = `${contact.name?.formatted_name || 'contact'}.vcf`;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                URL.revokeObjectURL(url);
                            }}
                        >
                            <Plus className="w-3 h-3 mr-1" /> Add to Contacts
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <div className={`flex ${isOutgoing ? 'justify-end' : 'justify-start'}`}>
                <div
                    className={`
                        max-w-[70%] rounded-lg px-4 py-2 shadow-sm
                        ${isOutgoing ? 'bg-indigo-600 text-white' : 'bg-white text-gray-900'}
                    `}
                >
                    {/* Media Content */}
                    {renderMediaContent()}

                    {/* Location Content */}
                    {renderLocationContent()}

                    {/* Contact Content */}
                    {renderContactContent()}

                    {/* Template Content */}
                    {renderTemplateContent()}

                    {/* Message Text */}
                    {renderTextContent()}

                    {/* Message Meta */}
                    <div className={`
                        flex items-center justify-end space-x-2 mt-1
                        ${isOutgoing ? 'text-indigo-200' : 'text-gray-500'}
                        text-xs
                    `}>
                        <span>
                            {format(new Date(message.timestamp), 'HH:mm')}
                        </span>
                        {isOutgoing && (
                            <span className="ml-1">
                                {getStatusIcon(message.status)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            
            {/* Media Viewer Modal */}
            <MediaViewer 
                media={currentMedia}
                isOpen={viewerOpen}
                onClose={() => setViewerOpen(false)}
            />
            
            {/* YouTube Modal */}
            <YouTubeModal
                url={youtubeUrl}
                isOpen={youtubeModalOpen}
                onClose={() => setYoutubeModalOpen(false)}
            />
            
            {/* Instagram Modal */}
            <InstagramModal
                url={instagramUrl}
                isOpen={instagramModalOpen}
                onClose={() => setInstagramModalOpen(false)}
            />
        </>
    );
};

export default Message;