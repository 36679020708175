import React from 'react';
import { ExternalLink } from 'lucide-react';
import Modal from '../ui/Modal';
import { getYouTubeEmbedUrl } from '../../utils/linkUtils';

const YouTubeModal = ({ url, isOpen, onClose }) => {
    if (!url) return null;
    
    const embedUrl = getYouTubeEmbedUrl(url);
    
    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
            size="lg"
            title="YouTube Video"
        >
            <div className="flex flex-col">
                <div className="aspect-video w-full bg-black">
                    <iframe
                        src={embedUrl}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="w-full h-full"
                    ></iframe>
                </div>
                
                <div className="mt-4 flex justify-between items-center">
                    <a 
                        href={url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 flex items-center"
                    >
                        <ExternalLink size={16} className="mr-1" />
                        Open on YouTube
                    </a>
                </div>
            </div>
        </Modal>
    );
};

export default YouTubeModal; 